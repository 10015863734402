<template>
  <div class="card no-padding-card invoice-card">
    <div class="card-header bg-primary text-white">
      <img src="/assets/img/bill.png" class="nav-icon rounded-circle" />
      Invoice Approval Pending
    </div>
    <ul class="list-group sktl--isloading" v-if="show">
      <li class="list-group-item">
        <div class="loading-image nav-icon rounded-circle"></div>
        <div class="loading-content">
          <div class="date loading-main-text"></div>
        </div>
        <div class="loading-content">
          <div class="date loading-main-text"></div>
        </div>
        <div class="loading-content">
          <div class="date loading-main-text"></div>
        </div>
      </li>
    </ul>
    <ul class="list-group sktl--isloading" v-if="show">
      <li class="list-group-item">
        <div class="loading-image nav-icon rounded-circle"></div>
        <div class="loading-content">
          <div class="date loading-main-text"></div>
        </div>
        <div class="loading-content">
          <div class="date loading-main-text"></div>
        </div>
        <div class="loading-content">
          <div class="date loading-main-text"></div>
        </div>
      </li>
    </ul>
    <ul class="list-group sktl--isloading" v-if="show">
      <li class="list-group-item">
        <div class="loading-image nav-icon rounded-circle"></div>
        <div class="loading-content">
          <div class="date loading-main-text"></div>
        </div>
        <div class="loading-content">
          <div class="date loading-main-text"></div>
        </div>
        <div class="loading-content">
          <div class="date loading-main-text"></div>
        </div>
      </li>
    </ul>
    <template v-if="!show">
      <div class="card-body card-after-sales">
        <ul class="list-group" v-if="Object.keys(items).length > 0">
          <li
            class="list-group-item"
            v-for="(b, index) in items"
            :key="index"
            @click="DetailInv(b.id, b.customer, index)"
          >
            <div class="img-left">
              <img src="/assets/img/invoice.jpg" class="nav-icon rounded-circle" />
            </div>
            <div class="col-text-right">
              <span class="date text-danger">
                <strong>{{ b.consultan }}</strong
                ><br />
              </span>
              <span class="date">Invoice No. : {{ b.invoice_no }}</span
              ><br />
              <span class="date">
                Sales Date: {{ moment(b.sales_date).format("DD MMMM YYYY") }}<br />
              </span>
              <span class="date"> Category Product: {{ b.product_category }} </span><br />
              <span class="date"> Branch: {{ b.branch_name }} </span><br />
              <span class="date"> Staff : {{ b.consultan }} </span><br />
              <span class="date"> Payment Status : {{ b.status }} </span>
            </div>
          </li>
        </ul>
        <ul v-else>
          <img src="/noTasks.svg" class="rounded mx-auto d-block" />
          <p class="text-center alert-warnings">*** No Data found ***</p>
        </ul>
      </div>
      <div class="footer-invoice card-footer" v-if="Object.keys(items).length > 0">
        <Paginator
          v-model:first="first"
          :rows="Number(per_pages)"
          :totalRecords="Number(meta.total)"
          @page="handlePagination($event)"
          template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        >
          <template #start>
            <Button type="button" icon="pi pi-refresh" @click="first = 0" />
          </template>
          <template #end>
            <Button type="button" icon="pi pi-book" />
          </template>
        </Paginator>
      </div>
    </template>
  </div>
</template>
<script>
import authHeader from "../../../services/auth-header";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import Paginator from "primevue/paginator";
import ProgressSpinner from "primevue/progressspinner";
import cryptos from "../../crypto.js";

export default {
  components: {
    Paginator,
    ProgressSpinner,
  },
  data() {
    return {
      moment: moment,

      items: [],
      meta: [],
      current_page: 1,
      per_pages: 3,
      searching: "",
      sortBy: "module_name",
      sortByDesc: false,
      isModalVisible: false,
      isBusy: false,
      buttonIsenabled: true,
      data_contact: false,
      headerBgVariant: "success",
      headerTextVariant: "light",
      bodyBgVariant: "light",
      bodyTextVariant: "dark",
      can_edit: false,
      can_view: false,
      can_create: false,
      isReadonly: true,
      isDisabled: true,
      active_error: false,
      show: false,
      salesstatus: null,
      opt_salesstatus: [
        { text: "Waiting Approval", value: 0 },
        { text: "Credit", value: 1 },
        { text: "Paid Off", value: 2 },
        { text: "Canceled", value: 4 },
      ],
      first: 0,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    module_name() {
      return JSON.parse(localStorage.getItem("permission"));
    },
    relatedroute() {
      return JSON.parse(localStorage.getItem("relatedroute"));
    },
    total: function () {
      return this.items.reduce((total, item) => {
        var getotal = 0;
        getotal = total + item.grand_total;
        return this.round(getotal, -2);
      }, 0);
    },
  },
  mounted() {
    this.DataInvLoad();
  },
  methods: {
    round(value, precision) {
      var multiplier = Math.pow(10, precision || 0);
      return Math.round(value * multiplier) / multiplier;
    },
    DataInvLoad() {
      this.show = true;
      let current_page = this.current_page;
      var access = "";
      if (
        this.currentUser.users.get_role.name == "Director" ||
        this.currentUser.users.email == "choe@abdi.co.id"
      ) {
        access = "allowed";
      }

      const data = {
        branchid: this.currentUser.users.branch_id,
        status: [1],
        branch: this.currentUser.users.branch_id,
        username: this.currentUser.users.name,
        role_id: this.currentUser.users.role_id,
        access: access,
        //years: moment().format("YYYY-MM"),
        module: "get detail dashboard",
      };
      axios
        .post(this.$api_url + "invoice-detail-customer", data, {
          headers: authHeader(),
          params: {
            page: current_page,
            per_page: this.per_pages,
            search: this.searching,
          },
        })
        .then((response) => {
          if (response.message == "Unauthenticated") {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
          }
          let getData = response.data;
          var datainv = getData.data;
          var invoicearr = [];
          datainv.forEach((doc) => {
            var status = "";
            if (doc.status == 0) {
              status = "Waitting Approval Credit";
            } else if (doc.status == 1) {
              status = "Deposite Receive";
            } else if (doc.status == 2) {
              status = "Paid Off";
            } else if (doc.status == 4) {
              status = "Canceled";
            }
            invoicearr.push({
              created_by: doc.created_by,
              created_on: moment(doc.created_on).format("DD MMMM YYYY, HH:mm:ss a"),
              full_payment: Number(doc.grand_total) - Number(doc.qty_refund),
              id: doc.salesid,
              invoice_no: doc.invoice_no,
              consultan: doc.consultan,
              total_maount: doc.grand_total,
              status: status,
              nme: doc.nme,
              users: doc.consultan == null ? "" : doc.consultan,
              customer: doc.customer_name == null ? "" : doc.customer_name,
              branch_name: doc.branch_name,
              last_visit_date: moment(doc.last_visit_date).format("DD MMM YYYY HH:ss"),
              grand_total: doc.grand_total,
              product_category: doc.product_category,
            });
          });
          this.items = invoicearr;
          this.meta = {
            total: Number(getData.total),
            current_page: Number(getData.current_page),
            per_pages: Number(getData.per_page),
            from: Number(getData.from),
            to: Number(getData.to),
          };
          this.show = false;
        })
        .catch((error) => {
          this.isBusy = false;
          this.show = false;
          console.log(error);
          if (error.response.status == 403 || error.response.status == 401) {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
          }
        });
    },
    handlePerPage(event) {
      this.per_pages = event.target.value;
      this.DataInvLoad(event.target.value);
    },
    handlePagination(val) {
      this.current_page = val.page + 1;
      this.DataInvLoad();
    },
    search: _.debounce(function (e) {
      this.searching = e.target.value;
      this.DataInvLoad();
    }, 500),
    handleSort(val) {
      this.sortBy = val.sortBy;
      this.sortByDesc = val.sortDesc;
      this.DataInvLoad();
    },
    handleDelete(val) {
      this.deletePostData(val.id);
    },
    DetailInv(val, customer, index) {
      let fcustomer = {
        fullname: this.items[index].customer,
      };
      const cryptoid = cryptos.aesEncrypt(val.toString());
      var customerid = cryptoid;
      localStorage.removeItem("relatedroute");
      const parsed = JSON.stringify({
        name: "customer can view",
        params: { id: customerid, customer: fcustomer },
      });
      localStorage.setItem("relatedroute", parsed);
      this.$router.push({
        name: "invoice can edit",
        params: {
          id: val,
          contactid: customerid,
          customer: fcustomer,
        },
      });
    },
    CreateInv() {
      this.$emit("createInvoice");
    },
    showload() {
      this.show = true;
      setTimeout(() => {
        this.checkrole();
      }, 1000);
    },
  },
};
</script>
