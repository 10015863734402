<template>
  <div>
    <div class="card-header bg-primary text-white">Follow Up After Sales 2 Weeks</div>
    <ul class="list-group sktl--isloading" v-if="show">
      <li class="list-group-item">
        <div class="loading-image nav-icon rounded-circle"></div>
        <div class="loading-content">
          <div class="date loading-main-text"></div>
        </div>
        <div class="loading-content">
          <div class="date loading-main-text"></div>
        </div>
        <div class="loading-content">
          <div class="date loading-main-text"></div>
        </div>
      </li>
    </ul>
    <ul class="list-group sktl--isloading" v-if="show">
      <li class="list-group-item">
        <div class="loading-image nav-icon rounded-circle"></div>
        <div class="loading-content">
          <div class="date loading-main-text"></div>
        </div>
        <div class="loading-content">
          <div class="date loading-main-text"></div>
        </div>
        <div class="loading-content">
          <div class="date loading-main-text"></div>
        </div>
      </li>
    </ul>
    <ul class="list-group sktl--isloading" v-if="show">
      <li class="list-group-item">
        <div class="loading-image nav-icon rounded-circle"></div>
        <div class="loading-content">
          <div class="date loading-main-text"></div>
        </div>
        <div class="loading-content">
          <div class="date loading-main-text"></div>
        </div>
        <div class="loading-content">
          <div class="date loading-main-text"></div>
        </div>
      </li>
    </ul>
    <template v-if="!show">
      <div
        class="card-primary card-after-sales"
        v-if="Object.keys(this.$attrs.aftetsevendays).length > 0"
      >
        <div class="">
          <ul class="list-group">
            <li
              class="list-group-item"
              v-for="(b, index) in paginated"
              :key="index"
              @click="goTo(b.id, index, 38)"
            >
              <img
                src="/assets/img/rating.png"
                class="nav-icon rounded-circle"
                v-if="b.gender == 1"
              />
              <img src="/assets/img/woman.png" class="nav-icon rounded-circle" v-else />
              <span class="date"> {{ $filters.capitalize(b.customer_name) }}<br /> </span>
              <span class="date">
                Sales Date: {{ moment(b.sales_date).format("DD MMMM YYYY") }}<br />
              </span>
              <span class="date"> Category Product: {{ b.category_product }} </span>
            </li>
          </ul>
        </div>
      </div>
      <ul v-else>
        <img src="/noTasks.svg" class="rounded mx-auto d-block" />
      </ul>
    </template>
  </div>
  <footer class="card-footer" v-if="Object.keys(this.$attrs.aftetsevendays).length > 0">
    <Paginator
      v-model:first="first"
      :rows="1"
      :totalRecords="Number(meta.total)"
      @page="handlePagination($event)"
      template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
    >
      <template #start>
        <Button type="button" icon="pi pi-refresh" @click="first = 0" />
      </template>
      <template #end>
        <Button type="button" icon="pi pi-book" />
      </template>
    </Paginator>
  </footer>
</template>
<script>
import moment from "moment";
import Paginator from "primevue/paginator";

export default {
  components: {
    Paginator,
  },
  data() {
    return {
      pageSize: 3,
      current: 1,
      meta: [],
      searchText: "",
      suggestiondata: [],
      show: true,
      moment: moment,
      first: 0,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    indexStart() {
      return (this.current - 1) * this.pageSize;
    },
    indexEnd() {
      return this.indexStart + this.pageSize;
    },
    paginated() {
      if (this.searchText && this.searchText.length > 2) {
        if (Object.keys(this.suggestiondata).length == 0) {
          return [];
        }
        var usercontact1 = this.suggestiondata;
        return usercontact1.slice(this.indexStart, this.indexEnd);
      } else {
        var usercontact = this.$attrs.aftetsevendays;
        return usercontact.slice(this.indexStart, this.indexEnd);
      }
    },
  },
  mounted() {
    if (this.$route.name == "inbox") {
      this.show = false;
    }
  },
  methods: {
    loadsales() {
      this.show = true;
      this.searchText = "";
      this.suggestiondata = [];
      var data = this.$attrs.aftetsevendays;
      if (Object.keys(data).length > 0) {
        this.meta = {
          total: Object.keys(data).length,
          current_page: this.current,
          per_page: 3,
          from: this.current,
          to: Object.keys(data).length,
        };
      }
      this.show = false;
    },
    handlePagination(val) {
      this.show = true;
      this.current = val.page + 1;
      var data = this.$attrs.aftetsevendays;
      if (Object.keys(data).length > 0) {
        var lengts = 0;
        var from = 1;
        if (this.current !== 1) {
          lengts = 0;
          from = this.meta.current_page * this.meta.per_page + 1;
        } else {
          from = 1;
          lengts = this.current * this.pageSize;
        }
        this.meta = {
          total: Object.keys(data).length,
          current_page: this.current,
          per_page: 3,
          from: from,
          to: lengts - 1,
        };
      }
      this.show = false;
    },
    goTo(val, index, type) {
      localStorage.removeItem("relatedroute");
      const parsed = JSON.stringify({
        name: "customer can view",
        params: { id: val, customer: this.paginated[index], type: type },
      });
      localStorage.setItem("relatedroute", parsed);
      this.$router.push({
        name: "activities can create",
      });
    },
  },
};
</script>
