<template>
  <div>
    <input
      class="form-control form-control-navbar"
      type="search"
      placeholder="Search"
      aria-label="Search"
      @keyup="loadSuggestions"
      v-model="searchText"
    />
    <br />
    <div class="p-autocomplete-panel p-component" v-if="suggestiondata.length">
      <ul id="pv_id_5_list" class="p-autocomplete-items" role="listbox">
        <li
          class="p-autocomplete-item"
          v-for="(item, index) in suggestiondata"
          @click="itemSelected(index)"
          :key="index"
          role="option"
          :data-index="index"
        >
          <span>
            {{ item.text }}
          </span>
          <span class="p-ink"></span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import authHeader from "../services/auth-header";
import axios from "axios";
import $ from "jquery";

export default {
  data: function () {
    return {
      searchText: "",
      suggestiondata: [],
      show: false,
    };
  },
  mounted() {
    if (this.searchText == "") {
      this.suggestiondata = [];
    }
  },
  methods: {
    hoverOut: function () {
      this.suggestiondata = [];
    },
    loadSuggestions: function () {
      var el = this;
      this.suggestiondata = [];
      if (this.searchText != "" && this.searchText.length > 3) {
        var data = {};
        this.show = true;
        axios
          .post(this.$api_url + "filter-nav", data, {
            headers: authHeader(),
            params: {
              search: this.searchText,
            },
          })
          .then(function (response) {
            var dataarr = [];
            var datacamp = [];
            var datalead = [];
            var dataticket = [];

            var activity = response.data.activity;
            var campaign = response.data.campaign;
            var lead_data = response.data.leads;
            var ticket_data = response.data.ticket;

            Object.keys(activity).forEach((key) => {
              var types = "";
              if (activity[key].activities_type !== null) {
                types = activity[key].activities_type.category_name;
              }
              dataarr.push({
                value: activity[key].id,
                text: activity[key].subject,
                type_name: types,
                description: activity[key].description,
                settype: activity[key].settype,
              });
            });

            Object.keys(campaign).forEach((key) => {
              datacamp.push({
                value: campaign[key].id,
                text: campaign[key].subject,
                settype: campaign[key].settype,
              });
            });

            Object.keys(lead_data).forEach((key) => {
              datalead.push({
                value: lead_data[key].id,
                text: lead_data[key].subject,
                settype: lead_data[key].settype,
                mobile_no: lead_data[key].mobile_no,
              });
            });

            Object.keys(ticket_data).forEach((key) => {
              dataticket.push({
                value: ticket_data[key].id,
                text: ticket_data[key].title,
                settype: ticket_data[key].settype,
                category: ticket_data[key].category,
                status: ticket_data[key].status,
              });
            });
            el.show = false;
            var newArray = $.merge($.merge([], dataarr), datacamp);
            var marger_last = $.merge($.merge([], newArray), datalead);
            var margre_act_camp_lead_ticket = $.merge(
              $.merge([], marger_last),
              dataticket
            );

            el.suggestiondata = margre_act_camp_lead_ticket;
          });
      }
    },
    itemSelected: function (index) {
      var id = this.suggestiondata[index].value;
      var name = this.suggestiondata[index].text;
      var settype = this.suggestiondata[index].settype;

      this.searchText = name;
      this.suggestiondata = [];

      this.$emit("input", id);
      this.selectitem(settype, id);
    },
    selectitem(settype, id) {
      if (settype == "Event") {
        this.$router.push({ path: `/activities/event/${id}` });
        //this.$router.push({ name: 'event can view', params: { id: id } }).catch(()=>{});
      } else if (settype == "Task") {
        this.$router.push({ name: "task can view", params: { id: id } });
      } else if (settype == "Leads") {
        this.$router.push({ name: "leads can view", params: { id: id } });
      } else if (settype == "Campaign") {
        this.$router.push({ name: "campaign can view", params: { id: id } });
      } else if (settype == "Ticket") {
        this.$router.push({ name: "ticket can view", params: { id: id } });
      }
    },
  },
};
</script>
