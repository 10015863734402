<template>
  <div id="appointment-calendar">
    <div class="card-header bg-primary text-white">
      <img src="/assets/img/schedule.png" class="nav-icon rounded-circle" /> Appointment
    </div>
    <div class="card-body">
      <!--
      <div class="row">
        <div id="appointment-schedule" class="col-sm-6">
          <div
            class="d-flex pb-3 border-bottom mb-3 align-items-end"
            v-for="(b, index) in eventarr"
            :key="index"
          >
            <div class="mr-auto" v-if="b.id">
              <p class="text-black font-w600 mb-2">
                <router-link
                  :to="{ name: 'appointment can view', params: { id: b.id } }"
                  v-if="b.id"
                >
                  {{ moment(b.date).format("dddd, DD MMM YYYY") }}
                </router-link>
              </p>
              <ul>
                <li>
                  <i class="pi pi-clock"></i> {{ moment(b.date).format("hh:ss") }} -
                  {{ moment(b.end).format("hh:ss") }}
                </li>
                <li><i class="pi pi-user"></i> {{ b.customer_name }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      -->
      <FullCalendar
        :options="calendarOptions"
        ref="fullCalendar"
        id="appointment-dashboard"
        @changeMonth="handleMonthChange"
      >
        <template v-slot:eventContent="arg">
          <div class="text-title">
            <div
              v-if="
                arg.event.extendedProps.settype == 'Appointment' &&
                arg.event.extendedProps.activitytype !== 'holidays' &&
                arg.event.extendedProps.activitytype !== 'weekend'
              "
            >
              <i class="far fa-clock"></i>
              {{ moment(arg.event.start).format("HH:mm") }} s/d
              {{ moment(arg.event.end).format("HH:mm") }}
            </div>
            <div v-else>
              <span
                class="text-white"
                v-if="arg.event.title !== null && arg.event.title !== undefined"
                >{{ arg.event.title }}</span
              >
            </div>

            <button
              class="btn btn-link btn-sm"
              @click="goToappointment(arg.event.id)"
              style="color: #fff"
              v-if="
                arg.event.extendedProps.activitytype !== 'holidays' &&
                arg.event.extendedProps.activitytype !== 'is_mondays'
              "
            >
              {{ arg.event.extendedProps.customer_name }} -
              {{ arg.event.extendedProps.mobile_no }}
            </button>
          </div>
        </template>
      </FullCalendar>
    </div>
    <div class="card-footer">
      <div v-for="(b, index) in arrholidays" :key="index">
        <span class="text-danger" v-if="b.activitytype == 'holidays'">
          <span class="text-danger">{{ b.days }}</span>
          {{ b.title }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import authHeader from "../../../services/auth-header";
import axios from "axios";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import moment from "moment";

import $ from "jquery";

export default {
  components: {
    FullCalendar,
  },
  props: ["moduleAccess"],
  data() {
    return {
      moment: moment,
      action: "Create",
      fields: [
        { key: "index", label: "No." },
        {
          key: "activities_type",
          label: "Type",
          sortable: true,
          searchable: true,
          formatter: (value, key, item) => {
            if (item.activity_type == null) {
              return item.settype;
            } else {
              if (item.activities_type !== null) {
                return item.activities_type.category_name;
              }
            }
          },
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          searchable: true,
          thStyle: "width: 15%",
        },
        {
          key: "priority",
          label: "Priority",
          sortable: true,
          searchable: true,
        },
        {
          key: "created_at",
          label: "Created At",
          sortable: true,
          searchable: true,
        },
        {
          key: "updated_at",
          label: "Updated At",
          sortable: true,
          searchable: true,
        },
        {
          key: "activities_owner.fullname",
          label: "Lead Owner",
          sortable: true,
          searchable: true,
        },
        { key: "actions", sortable: false },
      ],
      items: [],
      meta: [],
      current_page: 1,
      per_page: 10,
      searching: "",
      //sortBy: 'module_name',
      sortByDesc: false,
      isBusy: false,
      can_view: "",
      can_edit: "",
      can_create: "",
      can_fillter: "",
      log_users: [],
      msgerro: false,
      opt_status: [
        { value: "Client has arrived", text: "Client has arrived" },
        { value: "Cancel", text: "Cancel" },
      ],
      isEdit: false,

      calendarOptions: {
        plugins: [
          interactionPlugin,
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
          bootstrapPlugin,
        ],
        //googleCalendarApiKey: "AIzaSyA3XTC_LR-4xs0MH4iJaNTaNxvaFQRPV9Y",
        //themeSystem: "bootstrap",
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        },
        initialView: "dayGridMonth",
        weekends: true, // initial value
        dateClick: this.handleDateClick,
        //navLinks: true,
        events: [],
        eventChange: this.prevMonth,
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        eventClick: this.handleEventClick,
        //datesSet: this.handleMonthChange,
      },
      branch_id: null,
      opt_branch: [],
      room_id: null,
      opt_room: [],
      isMethod: false,
      loading: false,
      showAlart: false,
      month: "",
      idevent: "",
      isuser: "",
      vshow: "no",
      show: false,
      isVisible: true,
      calender: false,
      boxTwo: "",
      arrholidays: [],
      dateholidays: [],
      calendarStart: "",
      calendarEnd: "",
      displaymodal: false,
      eventarr: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    module_name() {
      return this.currentUser.permission;
    },
  },
  mounted() {
    $(".fc-today-button.fc-button.fc-button-primary")
      .prev()
      .addClass("fc-button-group-first");
    $(".fc-toolbar-chunk").each(function (index) {
      if (index == 0) {
        $(this).addClass("fc-toolbar-left");
      } else if (index == 1) {
        $(this).addClass("fc-toolbar-center");
      } else if (index == 2) {
        $(this).addClass("fc-toolbar-right");
      }
    });
    setTimeout(() => {
      $("fc-button-group>.fc-dayGridMonth-button").addClass("fc-button-group-last");
    }, 500);
    if (!this.currentUser) {
      this.$router.push({
        name: "login",
        params: { redirect: this.$route.path },
      });
    }
    this.getFullcalendar(moment().format("YYYY MMM"));
    var vm = this;
    $(document).on("click", ".fc-prev-button", function () {
      var value = $(".fc-toolbar-chunk h2").text();
      vm.handleEvents(value, $("#selecthandle").val());
    });
    $(document).on("click", ".fc-next-button", function () {
      var value = $(".fc-toolbar-chunk h2").text();
      vm.handleEvents(value, $("#selecthandle").val());
    });
  },
  methods: {
    handleMonthChange(payload) {
      var startisdate = "";
      var endisdate = "";
      if (payload !== undefined) {
        //startisdate = moment().format("YYYY-MM");
        startisdate = moment(payload.startStr).add(1, "months").format("YYYY-MM");
        endisdate = payload.endStr;
      }
      if (this.calendarStart != startisdate) {
        //this.isMethod = true;
        this.calendarStart = startisdate;
        this.calendarEnd = endisdate;
        this.show = true;
        this.loading = true;
        this.isBusy = true;
        this.getFullcalendar(startisdate);
      }
    },
    getFullcalendar(startisdate) {
      this.isMethod = true;
      let current_page = this.current_page;
      var ismonth = "";
      if (startisdate !== undefined) {
        ismonth = moment("01 " + startisdate).format("YYYY-MM");
      } else {
        ismonth = moment.format("YYYY-MM");
      }
      if (ismonth == "Invalid date") {
        return false;
      }
      const data = {
        //month: moment(startisdate).add(1, "months").format("YYYY-MM"),
        month: ismonth,
        branch_id:
          this.branch_id == null ? this.currentUser.users.branch_id : this.branch_id,
        room_id: this.room_id,
      };
      axios
        .post(this.$api_url + "activities-appointment-index", data, {
          headers: authHeader(),
        })
        .then((response) => {
          let getData = response.data;
          var holidays = [];
          var mondaydate = [];
          var deteholidaysarray = [];
          getData.calendar.forEach((b) => {
            if (b.national_holiday == 1) {
              //deteholidaysarray.push(b.db_date);
              holidays.push({
                title: b.event,
                date: b.db_date,
                eventid: b.id,
                activitytype: "holidays",
                color: "#d52c2", // an option!
                textColor: "#d52c2",
                backgroundColor: "#d52c2",
                display: "background",
                className: "holidays",
                days: b.day,
              });
            } else if (b.day_name == "Sunday" && b.national_holiday !== 1) {
              holidays.push({
                title: b.event,
                date: b.db_date,
                eventid: b.id,
                activitytype: "weekend",
                color: "#d52c2", // an option!
                textColor: "#d52c2",
                backgroundColor: "#d52c2",
                display: "background",
                className: "weekend",
                days: b.day,
              });
            }
          });

          getData.mondaydate.forEach((b) => {
            if (b.national_holiday == 1) {
              deteholidaysarray.push(b.db_date);
              mondaydate.push({
                title: b.event,
                date: b.db_date,
                eventid: b.id,
                activitytype: "holidays",
                color: "#d52c2", // an option!
                textColor: "#d52c2",
                backgroundColor: "#d52c2",
                display: "background",
                className: "holidays",
                days: b.day,
              });
            } else if (b.day_name == "Sunday" && b.national_holiday !== 1) {
              holidays.push({
                title: b.event,
                date: b.db_date,
                eventid: b.id,
                activitytype: "weekend",
                color: "#d52c2", // an option!
                textColor: "#d52c2",
                backgroundColor: "#d52c2",
                display: "background",
                className: "weekend",
                days: b.day,
              });
            }
          });
          this.dateholidays = deteholidaysarray;
          var data_event = response.data.data;
          let event = Object.keys(data_event).map((key) => {
            var custname = "";
            var mobileno = "";
            if (data_event[key].get_act_customer !== null) {
              custname = data_event[key].get_act_customer.customer_name;
              mobileno = data_event[key].get_act_customer.mobile_no;
            } else {
              custname =
                data_event[key].activities_leads == null
                  ? null
                  : data_event[key].activities_leads.leads_name;
              mobileno =
                data_event[key].activities_leads == null
                  ? null
                  : data_event[key].activities_leads.mobile_no;
            }
            return {
              title: data_event[key].activities_type.category_name,
              date: data_event[key].start_date + " " + data_event[key].start_time,
              end: data_event[key].end_date + " " + data_event[key].end_time,
              endtime: data_event[key].end_time,
              id: data_event[key].id,
              room: data_event[key].appointment_room.room_name,
              image: data_event[key].appointment_room.image,
              assigned_to: data_event[key].assigned_to,
              created_by: data_event[key].user_id,
              className: "gcal-event",
              customer_name: custname,
              mobile_no: mobileno,
            };
          });
          this.eventarr = event;
          this.arrholidays = holidays;
          var arrevents = $.merge($.merge([], event), holidays);
          var arrevent = $.merge($.merge([], arrevents), mondaydate);
          this.calendarOptions.events = arrevent;
          this.log_users = response.data.log_leads;
          this.isBusy = false;
          this.loading = false;
          this.isuser = this.currentUser.users.id;
        })
        .catch((error) => {
          this.loading = false;
          this.isBusy = false;
          console.log(error);
        });
    },
    toggleWeekends: function () {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // toggle the boolean!
    },
    handleMonthChange2(payload) {
      if (this.calendarStart != payload.startStr) {
        this.isMethod = true;
        this.calendarStart = payload.startStr;
        this.calendarEnd = payload.endStr;
        this.show = true;
        this.loading = true;
        this.isBusy = true;

        let current_page = this.current_page;
        const data = {
          month: moment(payload.startStr).add(1, "months").format("YYYY-MM"),
          branch_id: this.branch_id,
          room_id: this.room_id,
        };
        axios
          .post(this.$api_url + "activities-appointment-index", data, {
            headers: authHeader(),
            params: {
              page: current_page,
              per_page: this.per_page,
              search: this.searching,
            },
          })
          .then((response) => {
            let getData = response.data;
            var holidays = [];
            var mondaydate = [];
            getData.calendar.forEach((b) => {
              if (b.national_holiday == 1) {
                this.dateholidays.push(b.db_date);
                holidays.push({
                  title: b.event,
                  date: b.db_date,
                  eventid: b.id,
                  activitytype: "holidays",
                  color: "#d52c2", // an option!
                  textColor: "#d52c2",
                  backgroundColor: "#d52c2",
                  display: "background",
                  className: "holidays",
                  days: b.day,
                });
              }
            });
            getData.mondaydate.forEach((b) => {
              if (b.event !== null) {
                if (b.national_holiday == 1) {
                  mondaydate.push({
                    title: b.event,
                    date: b.db_date,
                    eventid: b.id,
                    activitytype: "holidays",
                    color: "#d52c2", // an option!
                    textColor: "#d52c2",
                    backgroundColor: "#d52c2",
                    display: "background",
                    className: "holidays",
                    days: b.day,
                  });
                }
              } else {
                if (b.national_holiday == 1) {
                  mondaydate.push({
                    title: "is_mondays",
                    date: b.db_date,
                    eventid: b.id,
                    activitytype: "is_mondays",
                    color: "#d52c2", // an option!
                    textColor: "#d52c2",
                    backgroundColor: "#d52c2",
                    display: "background",
                    className: "is_mondays",
                    days: b.day,
                  });
                }
              }
            });

            var data_event = response.data.data;
            let event = Object.keys(data_event).map((key) => {
              return {
                title: data_event[key].activities_type.category_name,
                date: data_event[key].start_date + " " + data_event[key].start_time,
                end: data_event[key].end_date + " " + data_event[key].end_time,
                endtime: data_event[key].end_time,
                id: data_event[key].id,
                room: data_event[key].appointment_room.room_name,
                image: data_event[key].appointment_room.image,
                assigned_to: data_event[key].assigned_to,
                created_by: data_event[key].user_id,
                activitytype: data_event[key].activities_type,
                settype: data_event[key].settype,
                className: "gcal-event",
                customer_name: data_event[key].get_act_customer.customer_name,
                mobile_no: data_event[key].get_act_customer.mobile_no,
              };
            });

            this.arrholidays = holidays;
            var arrevents = $.merge($.merge([], event), holidays);
            var arrevent = $.merge($.merge([], arrevents), mondaydate);
            this.calendarOptions.events = [...new Set(arrevent)];
            this.log_users = response.data.log_leads;
            this.isBusy = false;
            this.loading = false;
            this.isuser = this.currentUser.users.id;
          })
          .catch((error) => {
            this.loading = false;
            this.isBusy = false;
            console.log(error);
          });
        //await this.fetchAll();
      }
    },
    loadCalender() {
      this.isMethod = true;
    },
    handleDateClick: function () {
      //console.log(arg.url)
      //window.open(arg.url, '_blank', 'width=700,height=600');
    },
    /*
    handleEventClick(clickInfo) {
      if (
        confirm(
          `Are you sure you want to delete the event '${clickInfo.event.title}'`
        )
      ) {
        //clickInfo.event.remove()
      }
    },
    */
    handleEvents(val, selectData) {
      this.selected = selectData;
      if (val !== undefined) {
        this.getFullcalendar(val);
      }
    },
    selectedValue() {
      this.loadCalender(this.selected);
    },
    prevMonth() {
      //let calendarApi = this.$refs.fullCalendar.next()
      //let oke = this.$refs.fullCalendar;
    },
    checkrole() {
      Object.keys(this.moduleAccess).map((key) => {
        let checkroles = this.moduleAccess[key].permision_name;
        let user_id = this.moduleAccess[key].user_id;
        if (checkroles == "calender can view" && this.currentUser.users.id == user_id) {
          this.can_view = true;
        }

        if (checkroles == "calender can edit" && this.currentUser.users.id == user_id) {
          this.can_edit = true;
        }
        if (checkroles == "calender can create" && this.currentUser.users.id == user_id) {
          this.can_create = true;
        }
      });
    },
    goToappointment(id) {
      this.$router.push({
        name: "appointment can view",
        params: {
          id: id,
        },
      });
    },
  },
};
</script>
<style scoped></style>
