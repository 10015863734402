<template>
  <div v-if="currentUser !== null && currentUser !== undefined">
    <div class="row">
      <div class="col-12 col-lg-12 mb-4">
        <div class="card">
          <div class="row row-bordered g-0">
            <Topofsales
              @stopLoader="stopLoader"
              v-if="currentUser.users !== null && currentUser.users !== undefined"
            />
            <TopProduct />
          </div>
        </div>
      </div>
    </div>
    <div
      class="row homes"
      v-if="currentUser.users !== null && currentUser.users !== undefined"
    >
      <!-- follow up after sales -->
      <div class="col-sm-12">
        <div class="row after-sales-row padding-div">
          <div class="col-12">
            <h5 class="mb-3">
              Follow Up After Sales
              <a
                class="btn btn-primary btn-sm mb-3 1 float-right"
                href="#carouselExampleIndicators2"
                role="button"
                data-slide="next"
              >
                <i class="fa fa-arrow-right"></i>
              </a>
              <a
                class="btn btn-primary btn-sm mb-3 mr-1 float-right"
                href="#carouselExampleIndicators2"
                role="button"
                data-slide="prev"
              >
                <i class="fa fa-arrow-left"></i>
              </a>
            </h5>
          </div>
          <div class="col-sm-12">
            <div
              id="carouselExampleIndicators2"
              class="carousel slide"
              data-ride="carousel"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <Afterthredayss
                        v-model:afterthreedays="afterthreedays"
                        ref="aftertthree"
                      />
                    </div>
                    <div class="col-md-4 mb-3">
                      <AfterSevendayss
                        ref="afterseven"
                        v-model:aftetsevendays="aftetsevendays"
                      />
                    </div>
                    <div class="col-md-4 mb-3">
                      <AfteroneMonthss
                        ref="afteronemonthsales"
                        v-model:onemonth="onemonth"
                      />
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <AftertrheMonthss
                        ref="afterthreemonthsales"
                        v-model:threemonth="threemonth"
                      />
                    </div>
                    <div class="col-md-4 mb-3">
                      <AftersixsMonths
                        ref="aftersalessixmonth"
                        v-model:salesaftersixmontharr="salesaftersixmontharr"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end follow up after sales -->
          <!-- service  -->
          <div class="col-md-4" v-if="moduleAccess.includes('service can view')">
            <ServicePending
              class="card"
              ref="penddingservicedata"
              v-model:pendding="penddingservice"
              v-if="showedcomplated"
            />
          </div>
          <div class="col-md-4" v-if="moduleAccess.includes('service can view')">
            <ServiceOnprogress
              class="card"
              ref="onprogressservicedata"
              v-model:onprogress="onprogress"
              v-if="showedcomplated"
            />
          </div>
          <div class="col-sm-4" v-if="moduleAccess.includes('service can view')">
            <ServiceComplated
              class="card"
              ref="complatedservicedata"
              v-model:complated="complated"
              v-if="showedcomplated"
            />
          </div>
          <!-- end service  -->
          <!-- invoice pending -->
          <div
            :class="{ 'col-sm-6': colcenter == 2, 'col-sm-4': colcenter == 3 }"
            v-if="
              moduleAccess.includes('customer can view') &&
              (can_access.includes(currentUser.users.get_role.name) ||
                currentUser.users.division_id == 1)
            "
          >
            <InvoicePending />
          </div>
          <!-- end invoice pending -->
          <!-- birthdays -->
          <div
            :class="{ 'col-sm-6': colcenter == 2, 'col-sm-4': colcenter == 3 }"
            v-if="
              colcenter !== 1 ||
              currentUser.users.role_id == 15 ||
              currentUser.users.role_id == 41 ||
              currentUser.users.division_id == 1
            "
          >
            <div class="birthdays">
              <Birthdayscustomer />
            </div>
          </div>
          <!-- end birthdays -->
          <!-- recent activity -->
          <div
            :class="{ 'col-sm-6': colcenter == 2, 'col-sm-4': colcenter == 3 }"
            v-if="moduleAccess.includes('activities can view') && colcenter !== 1"
          >
            <Recentevent />
          </div>
          <!-- end recent activity -->
        </div>
      </div>
    </div>
    <div class="row homes" v-if="currentUser !== null || currentUser !== undefined">
      <!-- chart ticket -->
      <div class="col-sm-6" v-if="moduleAccess.includes('ticket can view')">
        <TicketChart
          class="card"
          v-model:yearsnow="yearsnow"
          v-model:monthnow="monthnow"
        />
      </div>
      <!-- end chart ticket -->
      <!-- calendar appointment -->
      <div class="col-sm-6" v-if="moduleAccess.includes('appointment can view')">
        <Calendarappointment class="card" />
      </div>
      <!-- end calendar appointment -->
      <!-- chart leads -->
      <div class="col-sm-6" v-if="moduleAccess.includes('leads can view')">
        <Chartleads
          v-model:start_date="start_date"
          v-model:end_date="end_date"
          ref="refresh_date"
          class="card card-leads-index"
          @stopload="stopload"
          v-if="currentUser !== null"
        />
      </div>
      <!-- end chart leads -->
      <!-- chart sale current month -->
      <div class="col-sm-6" v-if="moduleAccess.includes('customer can view')">
        <Saleschart class="card card-sales-index" v-bind:access="moduleAccess" />
      </div>
      <div
        class="col-sm-6"
        v-if="colcenter == 1 && moduleAccess.includes('activities can view')"
      >
        <Recentevent />
      </div>
      <!-- end chart sale current month -->
    </div>
  </div>
</template>
<script>
import authHeader from "../services/auth-header";
import axios from "axios";
import moment from "moment";
import $ from "jquery";
import Topofsales from "./includes/sales/Topsalesnew.vue";
import Afterthredayss from "./includes/sales/Aftertrheedays.vue";
import AfterSevendayss from "./includes/sales/Aftersalesseventdays.vue";
import AfteroneMonthss from "./includes/sales/AftersalesOnemonth.vue";
import AftertrheMonthss from "./includes/sales/AftersalseTrheemonth.vue";
import AftersixsMonths from "./includes/sales/AftersalesSixmonth.vue";
import ServiceComplated from "./includes/service/Complated.vue";
import ServicePending from "./includes/service/Pendding.vue";
import ServiceOnprogress from "./includes/service/Onprogress.vue";
import InvoicePending from "./includes/sales/Approvalpending.vue";
import Birthdayscustomer from "./includes/sales/Birthdays.vue";
import Recentevent from "./includes/event/Recentevent.vue";
import TicketChart from "./includes/ticket/Ticketbystatus.vue";
import Calendarappointment from "./includes/event/Appointmentevent.vue";
//import Calendarappointment from "./includes/event/Calendar.vue";
import Chartleads from "./includes/leads/Chart.vue";
import Saleschart from "./includes/customer/Chartsales.vue";
import TopProduct from "./includes/sales/Topproduct.vue";
/*
import Cardheader from "./includes/Cardheader.vue";
import Recentleads from "./includes/leads/Recentleads.vue";
import Message from "primevue/message";
import ProgressSpinner from "primevue/progressspinner";
*/
import xml2js from "xml2js";

export default {
  //props: ["usersdata", "notification", "moduleAccess"],
  props: {
    currentUser: {
      type: Object,
      default: () => {},
    },
    usersdata: {
      type: Array,
      default: () => [],
    },
    notification: {
      type: Number,
      default: () => 0,
    },
    moduleAccess: {
      type: Array,
      default: () => [],
    },
  },
  name: "Home",
  components: {
    Topofsales,
    //Cardheader,
    Afterthredayss,
    AfterSevendayss,
    AfteroneMonthss,
    AftertrheMonthss,
    AftersixsMonths,
    ServiceComplated,
    ServicePending,
    ServiceOnprogress,
    InvoicePending,
    Birthdayscustomer,
    Recentevent,
    TicketChart,
    Calendarappointment,
    Chartleads,
    Saleschart,
    TopProduct,
    //Recentleads,
    //Message,
    //ProgressSpinner,
  },
  data() {
    return {
      show: true,
      start_date: null,
      locale: "ID",
      end_date: null,
      loading: false,
      yearsnow: "",
      monthnow: "",
      infonotif: 0,
      iddoc: null,
      prevRoute: null,
      afterthreedays: [],
      aftetsevendays: [],
      onemonth: [],
      threemonth: [],
      salesaftersixmontharr: [],
      navtext: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      navservice: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      complated: [],
      showedcomplated: false,
      onprogress: [],
      penddingservice: [],
      moment: moment,
      previsrutename: null,
      can_access: ["Director", "Branch Manager"],
      showtopsales: true,
      istodays: moment().format("DD-MM-YYYY"),
      colcenter: 0,
      xmlItems: [],
    };
  },
  computed: {
    disabledbutton() {
      if (this.end_date !== null) {
        return false;
      } else {
        return true;
      }
    },
    checkwelcometext() {
      var path = this.prevRoute;
      if (path !== null) {
        if (path.fullPath.indexOf("/login") != -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    max() {
      var getStart = new Date(this.start_date);
      var lastDay = new Date(getStart.getFullYear(), getStart.getMonth() + 1, 0);
      if (this.start_date !== null) {
        return this.$options.filters.formatDate5(lastDay.toLocaleDateString());
      } else {
        return new Date();
      }
    },
    min() {
      var getStart = new Date(this.start_date);
      const minDate = new Date(getStart);
      var MinDates = new Date(minDate);
      MinDates.setDate(getStart.getDate());
      if (this.start_date !== null) {
        return this.$options.filters.formatDate5(MinDates.toLocaleDateString());
      } else {
        return new Date();
      }
    },
    accessdata() {
      var menu = [];
      this.moduleAccess.forEach((b) => {
        menu.push(b.module);
      });

      return menu;
    },
    prevRoutePath() {
      return this.prevRoute ? this.prevRoute.path : "/";
    },
    currentRouteName() {
      return this.$route && this.$route.name;
    },
  },
  mounted() {
    $("html, body .content-wrapper").animate({ scrollTop: 0 }, "slow");
    //this.GetBMKG();
    if (this.prevRoutePath !== this.currentRouteName) {
      setTimeout(() => {
        if (this.currentUser !== undefined && this.currentUser !== null) {
          if (this.notification !== 0) {
            $("title").text("Dashboard ( New Message" + this.notification + ") | DCMS");
          } else {
            $("title").text("Dashboard | DCMS");
          }
          this.loadService();
          this.loadDataaftersales();
        }
      }, 1000);
    }
  },
  methods: {
    GetBMKG() {
      axios
        .get(this.$api_url + "callback", {
          headers: authHeader(),
        })
        .then((response) => {
          let dataresponse = response.data;
          this.parseXML(dataresponse).then((data) => {
            this.xmlItems = data;
          });
        });
    },
    parseXML(data) {
      return new Promise((resolve) => {
        var k = "";
        var arr = [],
          parser = new xml2js.Parser({
            trim: true,
            explicitArray: true,
          });
        parser.parseString(data, function (err, result) {
          console.log(result);
          /*
          var obj = result.Employee;
          for (k in obj.emp) {
            var item = obj.emp[k];
            arr.push({
              id: item.id[0],
              name: item.name[0],
              email: item.email[0],
            });
          }
          resolve(arr);
          */
        });
      });
    },
    stopLoader() {
      this.showtopsales = false;
    },
    loadService() {
      setTimeout(() => {
        if (
          this.moduleAccess.includes("invoice can view") &&
          this.moduleAccess.includes("customer can view") &&
          this.moduleAccess.includes("activities can view")
        ) {
          this.colcenter = 3;
        } else {
          this.colcenter = 2;
        }
      }, 150);
      var user_id = "";
      if (this.currentUser !== undefined && this.currentUser.users.division_id == 52) {
        user_id = this.currentUser.users.id;
      }
      var firstDay = moment().startOf("month").format("YYYY-MM-DD");
      var lastDay = moment().endOf("month").format("YYYY-MM-DD");
      var data = {
        user_id: user_id,
        start_date: firstDay,
        end_date: lastDay,
      };
      axios
        .post(this.$api_url + "service/tracking", data, {
          headers: authHeader(),
        })
        .then((response) => {
          var getData = response.data;
          var itemsdata = getData.data;

          itemsdata.forEach((b) => {
            if (b.service_status == 4) {
              this.complated.push(b);
            }
            if (b.service_status == 5 || b.service_status == 7) {
              this.onprogress.push(b);
            }
            /*
            if (b.service_status == 1) {
              this.penddingservice.push(b);
            }
            */
          });
          this.showedcomplated = true;
        });
      var datapending = {
        user_id: user_id,
        service_status: 1,
      };
      axios
        .post(this.$api_url + "service/tracking", datapending, {
          headers: authHeader(),
        })
        .then((response) => {
          var getData = response.data;
          var itemsdata = getData.data;

          itemsdata.forEach((b) => {
            this.penddingservice.push(b);
          });
          this.showedcomplated = true;
          setTimeout(() => {
            if (this.moduleAccess.includes("Service")) {
              this.$refs.complatedservicedata.servicecomplated();
              this.$refs.penddingservicedata.servicecomplated();
              this.$refs.onprogressservicedata.servicecomplated();
            }
          }, 500);
        });
    },
    stopload(val) {
      this.show = val;
      this.loading = val;
    },
    loadDataaftersales() {
      this.previsrutename = this.prevRoutePath;
      var brandsarr = [];
      if (
        this.currentUser.users.get_role.name !== "Super Admin" &&
        this.currentUser.users.get_role.name !== "Director"
      ) {
        brandsarr = [this.currentUser.users.branch_id];
      }
      var access = "";
      if (
        this.currentUser.users.email == "web.developer.trasmediacom@gmail.com" ||
        this.currentUser.users.email == "choe@abdi.co.id"
      ) {
        access = "allowed";
      }
      var data = {
        branch: this.currentUser.users.branch_id,
        username: this.currentUser.users.name,
        role_id: this.currentUser.users.role_id,
        access: access,
        user_id: this.currentUser.users.id,
        module: "after sales follow up",
        followup: "no",
      };
      axios
        .post(this.$api_url + "after-sales-follow-up", data, {
          headers: authHeader(),
        })
        .then((response) => {
          var getData = response.data;
          getData.forEach((b) => {
            var issla = b.sla < 0 ? b.sla * -1 : b.sla;
            if (issla >= 3 && issla <= 7) {
              this.afterthreedays.push(b);
            } else if (issla >= 14 && issla <= 30) {
              this.aftetsevendays.push(b);
            } else if (issla >= 30 && issla <= 60) {
              this.onemonth.push(b);
            } else if (issla >= 90 && issla <= 120) {
              this.threemonth.push(b);
            } else if (issla >= 180 && issla <= 210) {
              this.salesaftersixmontharr.push(b);
            } else {
              return false;
            }
          });

          setTimeout(() => {
            this.$refs.aftertthree.loadsales();
            this.$refs.afterseven.loadsales();
            this.$refs.afteronemonthsales.loadsales();
            this.$refs.afterthreemonthsales.loadsales();
            this.$refs.aftersalessixmonth.loadsales();
          }, 2000);
        })
        .catch((error) => {
          this.show = false;
          console.log(error);
          if (error.response.status == 403 || error.response.status == 401) {
            this.$store.dispatch("auth/logout");
            this.$router.push({
              name: "login",
              params: { redirect: this.$route.path },
            });
          }
        });
    },
    onContext() {
      this.show = true;
      this.loading = true;
      if (this.end_date !== null) {
        if (new Date(this.end_date) >= new Date(this.start_date)) {
          this.$refs.refresh_date.chartjs();
        } else {
          alert("the end date is greater than the start date");
        }
      }
    },
    dateInput() {
      this.end_date = null;
    },
    pageTo() {
      var dataref = this.ref.doc(this.iddoc);
      dataref
        .update({
          isread: 1,
          user_id: this.currentUser.users.id,
          module_name: "activity user",
        })
        .then(() => {
          this.$router.push("/user-activity");
        });
    },
    gotTo(id, router, type, iddoc) {},
    logOut() {
      this.$emit("logout");
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
};
</script>
