import { createApp } from 'vue'
import App from './App.vue'
import { $, jQuery } from "jquery";
window.$ = window.jQuery = require("jquery");

import router from "./router";
import { store } from "./store";
import axios from "axios";
import { func } from "./services/func.js";
import moment from "moment";
import PrimeVue from "primevue/config";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import ToastService from "primevue/toastservice";
import ConfirmationService from 'primevue/confirmationservice';
import VueCryptojs from 'vue-cryptojs'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import Vue3MobileDetection from "vue3-mobile-detection";
import Highcharts from "highcharts";
import Stock from "highcharts/modules/stock";
import HighchartsVue from "highcharts-vue";
import Gantt from "highcharts/modules/gantt";
import { GcSpreadSheets, GcWorksheet, GcColumn } from '@grapecity/spread-sheets-vue';

//import adminlte styles
import '../node_modules/admin-lte/dist/css/adminlte.min.css'
import "../node_modules/admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css"
//import "../node_modules/admin-lte/plugins/summernote/summernote-bs4.min.css"
import "../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.css"
import "../node_modules/admin-lte/plugins/jqvmap/jqvmap.min.css"
import "../node_modules/admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css"
import "../node_modules/admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css"
import "../node_modules/admin-lte/plugins/bootstrap4-duallistbox/bootstrap-duallistbox.min.css"
import "../node_modules/admin-lte/plugins/bs-stepper/css/bs-stepper.min.css"
import "../node_modules/admin-lte/plugins/dropzone/min/dropzone.min.css"
import "../node_modules/admin-lte/plugins/fullcalendar/main.css"

import '../node_modules/admin-lte/plugins/jquery/jquery.min.js';
import '../node_modules/admin-lte/plugins/jquery-ui/jquery-ui.min.js';
import '../node_modules/admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js';
import '../node_modules/admin-lte/plugins/sparklines/sparkline.js';
import '../node_modules/admin-lte/plugins/jquery-knob/jquery.knob.min.js';
import '../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.js';
import "../node_modules/admin-lte/plugins/bootstrap-switch/js/bootstrap-switch.min.js"
import '../node_modules/admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js';
import '../node_modules/admin-lte/dist/js/adminlte.js';

//
// import "../node_modules/admin-lte/dist/js/adminlte.min.js"
// import "../node_modules/admin-lte/plugins/select2/js/select2.full.min.js"
// import "../node_modules/admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js"
// import "../node_modules/admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js"
// import "../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.js"
// import "../node_modules/admin-lte/plugins/jquery-knob/jquery.knob.min.js"
// import "../node_modules/admin-lte/plugins/sparklines/sparkline.js"
// import "../node_modules/admin-lte/plugins/jquery/jquery.min.js"
// import "../node_modules/admin-lte/plugins/jquery-ui/jquery-ui.min.js"
// import "../node_modules/admin-lte/plugins/inputmask/jquery.inputmask.min.js"
// import "../node_modules/admin-lte/plugins/bootstrap-switch/js/bootstrap-switch.min.js"
// import "../node_modules/admin-lte/plugins/bs-stepper/js/bs-stepper.min.js"
// import "../node_modules/admin-lte/plugins/dropzone/min/dropzone.min.js"




import "primeicons/primeicons.css";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "./assets/style.css";
import "./assets/mobile.css";
import "./assets/loader.css";

//library.add(fas, fab, far, faLock, faEnvelope, faFacebook, faGooglePlus);
//dom.watch()
const app = createApp(App);
app.component('gc-spread-sheets', GcSpreadSheets);
app.component('gc-worksheet', GcWorksheet);
app.component('gc-column', GcColumn);
//createApp(App).component("font-awesome-icon", FontAwesomeIcon).mount('#app')
app.config.globalProperties.$store = store;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$func = func;
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$api_url = "http://dcms-backend.abdi.co.id/api/";
app.config.globalProperties.$base_path_api = "http://dcms-backend.abdi.co.id/";
// app.config.globalProperties.$api_url = "http://127.0.0.1:8000/api/";
// app.config.globalProperties.$base_path_api = "http://127.0.0.1:8000/";
//app.config.globalProperties.$api_url = "http://dev-dcmsbackend.com/api/";
//app.config.globalProperties.$base_path_api = "http://dev-dcmsbackend.com/api/";

//app.config.globalProperties.$api_url = "http://backend-dev-test.abdi.co.id/api/";
//app.config.globalProperties.$base_path_api = "http://backend-dev-test.abdi.co.id/";

//app.config.globalProperties.$server_notif = "http://procurement-backend.com:8080/";
//app.config.globalProperties.$api_url_notif = "http://procurement-backend.com/api/";
app.config.globalProperties.$server_notif = "http://back-end.e-procurement.abdi.co.id:8082/";
app.config.globalProperties.$api_url_notif = "http://back-end.e-procurement.abdi.co.id/api/";

app.config.productionTip = false;
app.config.globalProperties.$filters = {
  sentenceCase(sentence) {
    var issplit = String(sentence).split("");
    if (issplit !== null) {
      return sentence
        .split(" ")
        .map((s) => this.capitalize(s))
        .join(" ");
    }
  },
  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
  Uppers(string) {
    return string.replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });
  },
  toUSD(value) {
    return `$${value}`;
  },
  formatPrice(value) {
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  },
  Uppersfirst(string){
    const datasplit = string.replace(/\s{2,}/g, " ").split(" ");
      if (string == "DKI JAKARTA" || string == "DI YOGYAKARTA") {
        const mySentence = datasplit[1];
        const firstwords = mySentence.toLowerCase();
        const finalSentence = firstwords.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
          letter.toUpperCase()
        );
        return datasplit[0] + " " + finalSentence;
      } else {
        const mySentence = string;
        const firstwords = mySentence.toLowerCase();
        const finalSentence = firstwords.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
          letter.toUpperCase()
        );
        return finalSentence;
      }
  },
  substrings(value, size) {
    if (!value) return "";
    value = value.toString();

    if (value.length <= size) {
      return value;
    }
    return value.substr(0, size) + " ...";
  },

};
Stock(Highcharts);
Gantt(Highcharts);
app.use(router);
app.use(PrimeVue);
app.use(ToastService);
app.use(ConfirmationService);
app.use(VueCryptojs);
app.use(HighchartsVue);
//app.use(metaManager).use(metaPlugin)
app.component("InputText", InputText);
app.component("Button", Button);
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA3XTC_LR-4xs0MH4iJaNTaNxvaFQRPV9Y',
    libraries: "places"
  },
});
app.use(Vue3MobileDetection);
//app.component("font-awesome-icon", FontAwesomeIcon);
//app.mount("#app");
router.isReady().then(() => app.mount("#app"));