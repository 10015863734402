<template>
  <div class="wrapper">
    <Preloader></Preloader>
    <Navbar
      @notifMessages="notifMessages"
      @logout="logout"
      v-model:accessSidebar="accessSidebar"
      v-model:usersdata="usersdata"
      @callusers="callusers"
      v-model:usernamestorage="usernamestorage"
      ref="getnotification"
      @setClass="setSidebar"
      v-model:notificationsnew="arrnotif"
      v-if="this.$route.name !== 'login'"
    ></Navbar>
    <Sidebar
      v-model:hover="hover"
      v-model:accessSidebar="accessSidebar"
      ref="callmodulaccess"
      v-model:usersdata="usersdata"
      v-model:moduleAccess="moduleAccess"
      @getAccessuser="getAccessuser"
      v-model:usernamestorage="usernamestorage"
      v-if="this.$route.name !== 'login' && this.$store.state.auth.user !== null"
    ></Sidebar>

    <div class="content-header" v-if="this.$route.name !== 'login'"></div>
    <div class="content-wrapper">
      <section class="content">
        <div class="container-fluid">
          <router-view
            v-model:hover="hover"
            v-model:msgnotif="msgnotif"
            v-model:notification="arrnotif.length"
            v-model:usersdata="usersdata"
            @callusers="callusers"
            @Showbox="Showbox"
            @getAccessuser="getmodulesforuser"
            @replaceModuleaccess="replaceModuleaccess"
            v-bind:moduleAccess="accessname"
            @checkAccess="checkAccess"
            v-model:can_view="can_view"
            v-model:can_filter="can_filter"
            v-model:can_edit="can_edit"
            v-model:can_create="can_create"
            v-model:can_print="can_print"
            v-model:can_import="can_import"
            v-model:can_delete="can_delete"
            @refreshrole="getAccessuser"
            @getusernew="getusernew"
            :currentUser="currentUser"
            v-model:usernamestorage="usernamestorage"
            v-model:can_export="can_export"
            @preloaderclass="preloaderclass"
          />
        </div>
      </section>
    </div>
  </div>
  <!-- <template v-else>
    <router-view
      v-model:hover="hover"
      v-model:msgnotif="msgnotif"
      v-model:notification="notification"
      v-model:usersdata="usersdata"
      @callusers="callusers"
      @Showbox="Showbox"
      @getAccessuser="getmodulesforuser"
      @replaceModuleaccess="replaceModuleaccess"
      v-bind:moduleAccess="accessname"
      @checkAccess="checkAccess"
      v-model:can_view="can_view"
      v-model:can_filter="can_filter"
      v-model:can_edit="can_edit"
      v-model:can_create="can_create"
      v-model:can_print="can_print"
      v-model:can_import="can_import"
      v-model:can_delete="can_delete"
      @refreshrole="getAccessuser"
      @getusernew="getusernew"
      v-model:usernamestorage="usernamestorage"
      v-model:can_export="can_export"
      @preloaderclass="preloaderclass"
    />
  </template> -->
  <a
    href="javascript:void(0)"
    class="float"
    @click="showchart($event)"
    v-if="
      this.currentUser !== null &&
      this.$route.name !== 'messanger can view' &&
      currentUser !== 401 &&
      currentUser !==
        'There is no user record corresponding to this identifier. The user may have been deleted.' &&
      currentUser !== 500 &&
      !$isMobile() &&
      this.$route.name !== 'messangers'
    "
  >
    <!--<i class="fa fa-plus my-float"></i>-->
    <i class="fab fa-facebook-messenger my-float"></i>
  </a>
  <OverlayPanel
    ref="op"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    class="overlay-chat"
    v-model:visible="charttemlate"
    v-bind:dismissable="false"
    v-if="!$isMobile() && this.$route.name !== 'login'"
  >
    <Listonlineusers
      ref="Showbox"
      @closedoverlay="closedoverlay"
      @showChatbox="showChatbox"
      v-model:showbox="charttemlate"
      v-if="this.$route.name !== 'login'"
    />
  </OverlayPanel>
  <OverlayPanel
    ref="chatbox"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    class="overlay-chatbox"
    v-model:visible="isoverlaymessage"
    v-bind:dismissable="false"
    v-if="!$isMobile() && this.$route.name !== 'login'"
  >
    <Chatboxpanel
      ref="boxrefchat"
      v-model:messages="sendermessage"
      @closeboxchat="closeboxchat"
      v-if="this.$route.name !== 'login'"
    />
  </OverlayPanel>
</template>

<script>
import $ from "jquery";

import Preloader from "./dashboard/Preloader.vue";
import Navbar from "./dashboard/Navbar.vue";
import Sidebar from "./dashboard/Sidebar.vue";
import Footer from "./dashboard/Footer.vue";
import ControlSidebar from "./dashboard/ControlSidebar.vue";

import Listonlineusers from "./views/messanger/Listuser.vue";
import Chatboxpanel from "./views/messanger/Chatbox.vue";
import OverlayPanel from "primevue/overlaypanel";
// import io from "socket.io-client";
const sitebody = document.body;

export default {
  name: "App",
  components: {
    Preloader,
    Navbar,
    Sidebar,
    Footer,
    ControlSidebar,
    Listonlineusers,
    OverlayPanel,
    Chatboxpanel,
  },
  data() {
    return {
      usersarray: [],
      notif: 0,
      moduleAccess: [],
      countmessage: 0,
      charttemlate: false,
      msgnotif: [],
      hover: false,
      access: false,
      msgerro: false,
      usernamestorage: [],
      isoverlaymessage: false,
      sendermessage: null,
      prevRoute: null,
      preloade: "preloade",
      // socket: io(this.$server_notif, {
      //   transports: ["websocket"],
      // }),
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    accessname() {
      if (this.$store.state.auth.user !== null) {
        if (this.$store.state.access.moudles == null) {
          return JSON.parse(localStorage.getItem("permission")).map((b) => {
            return b.permission_name;
          });
        } else {
          if (this.$store.state.access) {
            return this.$store.state.access.modules;
          }
        }
      } else {
        return null;
      }
    },
    field_access() {
      if (this.$store.state.auth.user !== null) {
        if (this.$store.state.access.moudles == null) {
          return JSON.parse(localStorage.getItem("permission")).map((b) => {
            return b.permission_name;
          });
        } else {
          return this.$store.state.access.modules;
        }
      } else {
        return null;
      }
    },
    arrnotif() {
      return this.$store.state.notifications.notification;
    },
    datausers() {
      if (
        this.currentUser !== null &&
        this.currentUser !==
          "There is no user record corresponding to this identifier. The user may have been deleted." &&
        this.currentUser !== 500
      ) {
        return this.currentUser.users;
      } else {
        return null;
      }
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_MODERATOR");
      }
      return false;
    },
    module_name() {
      if (!this.currentUser) {
        return this.currentUser.permission;
      } else {
        return false;
      }
    },
    accessSidebar() {
      if (
        this.currentUser !== null &&
        this.currentUser !==
          "There is no user record corresponding to this identifier. The user may have been deleted." &&
        this.currentUser !== 500
      ) {
        let accessrole = this.$func.AccessUser(this.module_name, this.$route.name);
        return accessrole;
      } else {
        return false;
      }
    },
    classname() {
      if (this.datausers != null) {
        return "main-panel";
      } else {
        return "login-page";
      }
    },
    usersdata: function () {
      return this.usersarray;
    },
    notification() {
      return this.notif == undefined ? null : this.notif;
    },
    can_view() {
      if (this.field_access !== null) {
        var accessview = this.$route.meta.can_view;
        if (accessview && this.field_access.includes(accessview)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    can_filter() {
      if (this.field_access !== null) {
        var accessview = this.$route.meta.can_filter;
        if (accessview && this.field_access.includes(accessview)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    can_edit() {
      if (this.field_access !== null) {
        var accessview = this.$route.meta.can_edit;
        if (accessview && this.field_access.includes(accessview)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    can_create() {
      if (this.field_access !== null) {
        var accessview = this.$route.meta.can_create;
        if (accessview && this.field_access.includes(accessview)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    can_delete() {
      if (this.field_access !== null) {
        var accessview = this.$route.meta.can_delete;
        if (accessview && this.field_access.includes(accessview)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    can_export() {
      if (this.field_access !== null) {
        var accessview = this.$route.meta.can_export;
        if (accessview && this.field_access.includes(accessview)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    can_print() {
      if (this.field_access !== null) {
        var accessview = this.$route.meta.can_print;
        if (accessview && this.field_access.includes(accessview)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    can_import() {
      if (this.field_access !== null) {
        var accessview = this.$route.meta.can_import;
        if (accessview && this.field_access.includes(accessview)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  mounted() {
    if (this.$route.name == "login" || this.currentUser == null) {
      sitebody.classList.remove("sidebar-mini");
      sitebody.classList.remove("layout-fixed");
      sitebody.classList.remove("layout-navbar-fixed");
      sitebody.classList.remove("sidebar-collapse");
      sitebody.classList.add("login-page");
      this.$router.push("/login");
    } else {
      sitebody.classList.remove("login-page");
      sitebody.classList.add("sidebar-mini");
      sitebody.classList.add("layout-fixed");
      sitebody.classList.add("layout-navbar-fixed");
      sitebody.classList.add("sidebar-collapse");
      setTimeout(() => {
        $(".preloader").addClass("display-none");
      }, 2000);
    }
  },
  methods: {
    async getDataMessage() {
      await this.$refs.chatbox.getDataMessage();
    },
    showChatbox(event, obj) {
      this.$refs.op.hide(event);
      this.charttemlate = false;
      this.sendermessage = obj;
      this.isoverlaymessage = true;
      this.$refs.chatbox.toggle(event);
      setTimeout(() => {
        this.$refs.boxrefchat.getDataMessage(event, obj);
      }, 250);
    },
    closeboxchat(event) {
      this.isoverlaymessage = false;
      this.$refs.chatbox.hide(event);
    },
    setSidebar() {
      this.$refs.callmodulaccess.toggleSidebar();
    },
    getnotifnafbar() {
      setTimeout(() => {
        this.$refs.getnotification.getnotifuser();
        this.$refs.callmodulaccess.getMenudocs();
      }, 3500);
    },
    async getusernew() {},
    checkAccess() {
      var accessview = this.$route.name + " can view";
      if (this.field_access.includes(accessview)) {
        return true;
      }
    },
    massagenotif(val) {
      this.msgnotif = val;
    },
    callusers() {},
    getmodulesforuser() {},
    async getAccessuser() {},
    replaceModuleaccess(val) {
      if (Object.keys(val).length > 0) {
        this.moduleAccess = [];
        Object.keys(val).map((key) => {
          return {
            permision_name: val[key].permission_name,
            module: val[key].module_name,
            user_id: val[key].user_id,
          };
        });

        this.getAccessuser();
      }
    },
    replaceModule() {},
    logOut() {
      sitebody.classList.remove("sidebar-mini");
      sitebody.classList.remove("layout-fixed");
      sitebody.classList.remove("layout-navbar-fixed");
      sitebody.classList.remove("sidebar-collapse");
      sitebody.classList.add("login-page");
      this.currentUser = null;
      this.$store.dispatch("auth/logout");
      //this.$router.push("/login");
      setTimeout(() => {
        this.$router.push({
          name: "login",
          query: { redirect: this.$route.path },
          redirectedFrom: { redirect: this.$route.path },
          params: { redirect: this.$route.path },
        });
      }, 500);
    },
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === "addClass") {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    Showbox(val, user_id, arrayuser) {
      this.$refs.Showbox.Showbox(val, user_id, arrayuser);
    },
    updatemessage() {
      //this.$refs.Showbox.getMessage(val)
      //this.$refs.refreshlist.listuser();
    },
    showchart(event) {
      this.$refs.op.toggle(event);
      this.charttemlate = !this.charttemlate;
    },
    closedoverlay(event) {
      this.$refs.op.hide(event);
    },
    notifMessages(val, d_message) {
      this.msgnotif = d_message;
      this.notif = val;
    },
    logout() {
      this.usersarray = [];
      setTimeout(() => {
        sitebody.classList.remove("sidebar-mini");
        sitebody.classList.remove("layout-fixed");
        sitebody.classList.remove("layout-navbar-fixed");
        sitebody.classList.remove("sidebar-collapse");
        sitebody.classList.add("login-page");
        this.$store.dispatch("auth/logout");

        this.$router.push({
          name: "login",
          params: {
            redirect: this.$route.path,
          },
          query: { redirect: this.$route.path },
          redirectedFrom: {
            redirect: this.$route.path,
          },
        });
      }, 500);
    },
    preloaderclass() {
      setTimeout(() => {
        $(".preloader").addClass("display-none");
      }, 2000);
    },
  },
};
</script>
<style>
.preloade {
  height: 60px;
}
.display-none {
  height: 0px !important;
}
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback");
</style>
