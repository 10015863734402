class uri {
  url() {
    return "http://dcms-backend.abdi.co.id/";
    //return "http://backend-dev-test.abdi.co.id/";
    //return 'http://dev-dcmsbackend.com/';
    // return 'http://127.0.0.1:8000/';
  }
  notificationurl(){
    //return 'http://procurement-backend.com/';
    return 'http://back-end.e-procurement.abdi.co.id/';
  }
}

export default new uri();
