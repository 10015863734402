<template>
  <footer class="main-footer">
    <strong
      >Copyright &copy; 2021 - 2022
      <a target="_blank" href="https://www.pusatalatbantudengar.com/"
        >Alat Bantu Dengar Indonesia</a
      >.</strong
    >
    All rights reserved.
    <div class="float-right d-none d-sm-inline-block"><b>Version</b> 3.1.0</div>
  </footer>
</template>

<script>
export default {};
</script>

<style></style>
