import { createStore } from "vuex";
import { auth } from "./auth.module";
import {access} from './access'
import {notifications} from './notifications'

export const store = createStore({
  modules: {
    auth,
    access,
    notifications
  },
});
