import axios from "axios";
import authHeader from "./auth-header";
import uri from "./func_global_url";
const API_URL = uri.url();

class AuthService {
  login(user) {
    return axios
          .post(API_URL + "api/auth/user-login", {
            email: user.email,
            password: user.password,
            log: user.datalog,
          },{ headers: { "Access-Control-Allow-Origin": "*", "Content-Type": "application/json", "secure": false}})
          .then((response) => {
            if (response == 401) {
              return response;
            } else {
              if (response.data.access_token) {
                localStorage.setItem("user", JSON.stringify(response.data));
                localStorage.setItem(
                  "permission",
                  JSON.stringify(response.data.permission)
                );
              }
              return response.data;
            }
          })
          .catch((error) => {
            if (error.response.status) {
              localStorage.setItem(
                "user",
                JSON.stringify(error.response.status)
              );
            }
            return error.response.status;
          });
  }
  getusers(id) {
    //return id

    const data = {
      id: id,
      module: 'new template'
    };
    return axios
      .post(API_URL + "api/edit-users", data, {
        headers: authHeader(),
      })
      .then((response) => {
        let userdata = JSON.parse(localStorage.getItem("user"));
        var user = response.data.data
        var permission = userdata.permission
        var datastorage = {
          access_token: userdata.access_token,
          expires_in: userdata.expires_in,
          permission,
          token_type: userdata.token_type,
          users: {
            branch_id : user.branch_id,
            created_at : user.created_at,
            division_id : user.division_id,
            email : user.email,
            first_name : user.first_name,
            gender : user.gender,
            id : user.id,
            image : user.image,
            is_enebled : user.is_enebled,
            last_name : user.last_name,
            middle_name : user.middle_name,
            name : user.name,
            role_id : user.role_id,
            updated_at: user.updated_at,
            branch_user: user.branch_user,
            devision: user.devision,
            get_role: user.get_role,
          }
        }
        setTimeout(() => {
        }, 500);
        localStorage.removeItem("user");
        const parsed = JSON.stringify(datastorage);
        localStorage.setItem('user', parsed);
        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  logout() {
    localStorage.removeItem("user");
  }

  register(user) {
    return axios.post(API_URL + "api/register", {
      username: user.username,
      email: user.email,
      password: user.password,
    });
  }
}
export default new AuthService();
