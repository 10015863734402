<template>
  <div class="login-box">
    <div class="login-logo">
      <img class="rounded mx-auto d-block" src="/abdi.png" />
    </div>
    <div class="card">
      <div class="card-body login-card-body">
        <p class="login-box-msg">
          <div class="alert alert-warning d-flex align-items-center" role="alert" v-if="dismissCountDown !== 0">
          <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:">
            <use xlink:href="#exclamation-triangle-fill" />
          </svg>
          <div>{{ message }}</div>
        </div>
        </p>
        <form @keyup.enter="handleLogin" v-if="!forgotpassword">
          <div class="input-group mb-3">
            <input
              type="email"
              class="form-control"
              placeholder="Email"
              v-model="user.email"
              :validate="'required'"
              required
              autofocus
            />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input
              type="password"
              class="form-control"
              placeholder="Password"
              v-model="user.password"
              required
            />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <!--
              <div class="icheck-primary">
                <input type="checkbox" id="remember" />
                <label for="remember"> Remember Me </label>
              </div>
            --></div>

            <div class="col-4">
              <button class="btn btn-sm btn-primary btn-block " @click="handleLogin"
          :class="{ 'btn-primary': !loading, 'btn-info': loading }" type="button" :disabled="loading">
          <div class="spinner-border text-white spinner-border-sm" role="status" v-if="loading">
            <span class="visually-hidden">Loading...</span>
           
          </div>
          <div class="loading-text" v-if="loading">Loading ..</div>
          <div v-else>Sign in</div>
        </button>
            </div>
          </div>
        </form>
        <form @keyup.enter="handleLogin" v-if="forgotpassword">
          <div class="input-group mb-3">
            <input
              type="email"
              class="form-control"
              placeholder="Email"
              v-model="user.email"
              :validate="'required'"
              required
              autofocus
            />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-8"></div>

            <div class="col-4">
              <button class="btn btn-sm btn-primary btn-block " @click="handleLogin"
          :class="{ 'btn-primary': !loading, 'btn-info': loading }" type="button" :disabled="loading">
          <div class="spinner-border text-white spinner-border-sm" role="status" v-if="loading">
            <span class="visually-hidden">Loading...</span>
           
          </div>
          <div class="loading-text" v-if="loading">Loading ..</div>
          <div v-else>Submit</div>
        </button>
            </div>
          </div>
        </form>
        <p class="mb-1">
          <a href="#" @click="passwordForgot(true)" v-if="!forgotpassword"
            >I forgot my password</a
          >
          <a href="#" @click="passwordForgot(false)" v-if="forgotpassword">Login</a>
        </p>
      </div>
    </div>
  </div>
  <Toast />
</template>
<script>
//import "../assets/simple-login.css";
import '../../node_modules/admin-lte/dist/js/adminlte.js';
import User from "../models/user";
import authHeader from "../services/auth-header";
import axios from "axios";
import Toast from "primevue/toast";
import $ from "jquery";
export default {
  props: {
    moduleAccess: {
      type: Object,
      default: () => ({}),
    },
    usersdata: {
      type: Object,
      default: () => ({}),
    },
    msgnotif: {
      type: Array,
      default: () => [],
    },
    usernamestorage: {
      type: Array,
      default: () => [],
    },
    can_view: {
      type: Boolean,
      default: () => false,
    },
    can_delete: {
      type: Boolean,
      default: () => false,
    },
    can_edit: {
      type: Boolean,
      default: () => false,
    },
    can_create: {
      type: Boolean,
      default: () => false,
    },
    can_export: {
      type: Boolean,
      default: () => false,
    },
    can_import: {
      type: Boolean,
      default: () => false,
    },
    can_filter: {
      type: Boolean,
      default: () => false,
    },
    can_print: {
      type: Boolean,
      default: () => false,
    },
    notification: {
      type: Number,
      default: () => 0,
    },
  },
  name: "Login",
  components: {
    //VueRecaptcha
    Toast,
  },
  data() {
    return {
      user: new User("", ""),
      loading: false,
      message: "",
      dismissSecs: 10,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      emailuser: "",
      forgotpassword: false,
      titleform: "User Login",
      titleh1: "Sign In",
      results: [],
      duserfir: "",
      handleMouseDown: "",
      onselectstart: "",
      checktoken: null,
      tmpuserid: null,
      robot: false,
      lastrute: this.$router.currentRoute.name,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    recaptcha() {
      if (
        (this.user.email !== "" && this.user.password !== "") ||
        this.emailuser !== ""
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  beforeMount() {
    this.user = {
      email: "",
      password: "",
    };
  },
  created() {
    $("title").text("Login");
  },
  mounted() {
    $('[data-widget="treeview"]').Treeview("init");
    const sitebody = document.body;
    sitebody.classList.remove("sidebar-mini");
      sitebody.classList.remove("layout-fixed");
      sitebody.classList.remove("layout-navbar-fixed");
      sitebody.classList.remove("sidebar-collapse");
      sitebody.classList.add("login-page");
  },
  methods: {
    handleLogin() {
      if (this.user.email == "") {
        this.message = "Please insert your email";
        this.showAlert();
        return false;
      }
      if (this.user.password == "") {
        this.message = "Please insert your password";
        this.showAlert();
        return false;
      }
      if (!this.robot) {
        var vm = this;
        this.loading = true;
        const url = "https://ipinfo.io/115.124.74.154?token=71a1fed504fef9";

        axios
          .get(url, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "content-type": "application/json",
              crossdomain: true,
            },
          })
          .then((response) => {
            this.user.datalog = response.data;

            if (this.user.email && this.user.password) {
              this.$store.dispatch("auth/login", this.user, this.results).then(
                (response) => {
                  if (response == 401) {
                    this.showAlert();
                    this.message = "email or password not match";
                    this.loading = false;
                    return;
                  } else if (
                    response ==
                    "There is no user record corresponding to this identifier. The user may have been deleted."
                  ) {
                    this.showAlert();
                    this.message = response;
                    this.loading = false;
                  } else {
                    if (response.account_info) {
                      this.loading = false;
                      this.message = response.account_info;
                      this.showAlert();
                      return false;
                    }
                    if (response.email) {
                      this.loading = false;
                      this.message = response.email;
                      this.showAlert();
                      return false;
                    }
                    let sitebody = document.body;
                    this.tmpuserid = response.users.id;
                    var datauser = response.users;
                    var data_online = {
                      branch_id: datauser.branch_id,
                      created_at: datauser.created_at,
                      created_by: datauser.created_by,
                      email: datauser.email,
                      first_name: datauser.first_name,
                      gender: datauser.gender,
                      id: datauser.id,
                      image: datauser.image,
                      last_login_at: datauser.last_login_at,
                      last_login_ip: datauser.last_login_ip,
                      last_name: datauser.last_name,
                      middle_name: datauser.middle_name,
                      name: datauser.name,
                      role_id: datauser.role_id,
                      updated_at: datauser.updated_at,
                      isonline: new Date(),
                      branch_user: datauser.branch_user,
                      devision: datauser.devision,
                      division_id: datauser.division_id,
                      get_role: datauser.get_role,
                      group_id: datauser.group_id,
                      usercrmapp: datauser.usercrmapp,
                    };
                    this.checktoken = response.access_token;
                    this.loading = false;
                    this.$emit("refreshrole");
                    this.$emit("callusers");
                    var idusers = this.currentUser.users.id;
                    this.$emit("getusernew");
                    let arrpermissions = [];
                    response.permission.forEach((b) => {
                      arrpermissions.push(b.permission_name);
                    });

                    this.$store
                      .dispatch("access/incrementIfOdd", arrpermissions)
                      .then(() => {
                        this.$emit("preloaderclass");
                        sitebody.classList.remove("login-page");
                        sitebody.classList.add("sidebar-mini");
                        sitebody.classList.add("layout-fixed");
                        sitebody.classList.add("layout-navbar-fixed");
                        sitebody.classList.add("sidebar-collapse");
                        this.$router.push(this.$route.query.redirect || "/");
                      });
                  }
                },
                (error) => {
                  this.loading = false;
                  this.message =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                }
              );
            }
          });
      } else {
        this.message = "Please insert captcha";
        this.showAlert();
        return false;
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    passwordForgot(val) {
      this.forgotpassword = val;
      if (val == true) {
        this.titleform = "Sendmail";
        this.titleh1 = "Sendmail";
      } else {
        this.titleform = "User Login";
        this.titleh1 = "Sign In";
      }
    },
    sendmailpassword() {
      if (!this.robot) {
        this.loading = true;
        if (this.emailuser == "") {
          //this.$toastr.error("Please insert email", "Alert Info");
          this.loading = false;
          return false;
        }
        var data = {
          email: this.emailuser,
          domain: window.location.origin,
        };
        axios
          .post("http://dcms-backend.abdi.co.id/api/auth/req-password-reset", data, {
            headers: authHeader(),
          })
          .then(() => {
            this.message = "Success send link, please chek your email";
            this.showAlert();
            this.$toast.add({
              severity: "success",
              summary: "Info Message",
              detail: "Success send link, please chek your email",
              life: 3000,
            });
            this.loading = false;
            this.forgotpassword = false;
          })
          .catch((error) => {
            //this.$toastr.error("Error create", "Alert Info");
            this.$toast.add({
              severity: "error",
              summary: "Info Message",
              detail: error.response.data.error,
              life: 3000,
            });
            this.loading = false;
            console.log(error);
          });
      } else {
        this.loading = false;
        this.message = "Please insert captcha";
        this.showAlert();
        return false;
      }
    },
    onVerify: function (response) {
      console.log(response);
      if (response !== "") {
        this.robot = true;
      }
    },
  },
};
</script>
