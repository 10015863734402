<template>
  <div id="recent-act" class="card">
    <div class="card-header bg-primary text-white borderless">
      <img src="/assets/img/schedule.png" class="nav-icon rounded-circle" /> Recently
      Activity
    </div>
    <div class="card-body">
      <div class="info-box sktl--isloading" v-if="showspinner">
        <div class="info-box-icon loading-image">
          <div class="nav-icon rounded-circle" />
        </div>
        <div class="info-box-content loading-content">
          <div class="info-box-text loading-main-text"></div>
          <div class="info-box-text loading-main-text"></div>
        </div>
      </div>
      <div class="info-box sktl--isloading" v-if="showspinner">
        <div class="info-box-icon loading-image">
          <div class="nav-icon rounded-circle" />
        </div>
        <div class="info-box-content loading-content">
          <div class="info-box-text loading-main-text"></div>
          <div class="info-box-text loading-main-text"></div>
        </div>
      </div>
      <div class="info-box sktl--isloading" v-if="showspinner">
        <div class="info-box-icon loading-image">
          <div class="nav-icon rounded-circle" />
        </div>
        <div class="info-box-content loading-content">
          <div class="info-box-text loading-main-text"></div>
          <div class="info-box-text loading-main-text"></div>
        </div>
      </div>
      <div class="info-box sktl--isloading" v-if="showspinner">
        <div class="info-box-icon loading-image">
          <div class="nav-icon rounded-circle" />
        </div>
        <div class="info-box-content loading-content">
          <div class="info-box-text loading-main-text"></div>
          <div class="info-box-text loading-main-text"></div>
        </div>
      </div>
      <div class="info-box sktl--isloading" v-if="showspinner">
        <div class="info-box-icon loading-image">
          <div class="nav-icon rounded-circle" />
        </div>
        <div class="info-box-content loading-content">
          <div class="info-box-text loading-main-text"></div>
          <div class="info-box-text loading-main-text"></div>
        </div>
      </div>
      <template v-if="!showspinner">
        <div class="h-100" v-for="(b, inx) in items" :key="inx">
          <div class="d-flex btn-reveal-trigger">
            <div class="calendar">
              <span class="calendar-month">{{ moment(b.created_at).format("MMM") }}</span
              ><span class="calendar-day">{{ moment(b.created_at).format("DD") }}</span>
            </div>
            <div class="flex-1 position-relative ps-3">
              <h6 class="fs-0 mb-0">
                <router-link :to="{ name: 'activities can view', params: { id: b.id } }">
                  {{ b.activities_type.category_name }}
                  <span
                    class="badge badge-soft-success rounded-pill"
                    v-if="b.status == 'Completed'"
                    >Complated</span
                  >
                </router-link>
              </h6>
              <p class="mb-1">
                Organized by
                <a href="#!" class="text-700" v-if="activities_owner !== null">{{
                  b.activities_owner == null ? "Mobile Apps" : b.activities_owner.fullname
                }}</a>
              </p>
              <p class="text-1000 mb-0">
                Customer:
                <router-link
                  :to="{
                    name: 'customer can view',
                    params: { id: b.customer_id },
                  }"
                  v-if="b.get_act_customer !== null"
                  >{{ b.get_act_customer.customer_name }}</router-link
                >
              </p>
              <p class="text-1000 mb-0">Branch: {{ b.branch_event.nme }}</p>
              <div class="border-dashed-bottom my-3"></div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <img
      src="/noTasks.svg"
      class="rounded mx-auto d-block"
      v-if="Object.keys(items).length == 0"
    />
    <p class="text-center alert-warnings" v-if="items.length == 0">
      *** No Data found ***
    </p>
    <div
      class="footer-act text-center bg-primary text-white"
      @click="goToact()"
      v-if="Object.keys(items).length > 2"
    >
      View All Activity
    </div>
  </div>
</template>
<script>
import authHeader from "../../../services/auth-header";
import axios from "axios";

import moment from "moment";

import cryptos from "./../../crypto.js";

export default {
  data() {
    return {
      moment: moment,
      items: [],
      meta: [],
      current_page: 1,
      per_page: 5,
      showspinner: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (this.currentUser !== null) {
      this.loadActivities();
    }
  },
  methods: {
    async loadActivities() {
      this.showspinner = true;
      let current_page = this.current_page;
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      const data = {
        start_date: firstDay,
        end_date: lastDay,
      };
      axios
        .post(this.$api_url + "acivity-index", data, {
          headers: authHeader(),
          params: {
            page: current_page,
            per_page: this.per_page,
            search: this.searching,
          },
        })
        .then((response) => {
          if (response.message == "Unauthenticated") {
            this.$store.dispatch("auth/logout");
            this.$router.push({
              name: "login",
              params: { redirect: this.$route.path },
            });
          }
          let getData = response.data;
          const arrevent = getData.data.data;
          arrevent.forEach((b) => {
            var cryptoid;
            if (b.customer_id !== null) {
              cryptoid = cryptos.aesEncrypt(b.customer_id.toString());
            }
            this.items.push({
              id: b.id,
              subject: b.subject,
              start_date: b.start_date,
              start_time: b.start_time,
              status: b.status,
              activity_type: b.activity_type,
              priority: b.priority,
              end_date: b.end_date,
              end_time: b.end_time,
              description: b.description,
              settype: b.settype,
              due_date: b.due_date,
              user_id: b.user_id,
              modified_by: b.modified_by,
              customer_id: cryptoid,
              campaign_id: b.campaign_id,
              leads_id: b.leads_id,
              created_at: b.created_at,
              updated_at: b.updated_at,
              get_act_customer: b.get_act_customer,
              branch_event: b.branch_event,
              activities_owner: b.activities_owner,
              activities_type: b.activities_type,
            });
          });

          this.editUrl = getData.data.data;
          this.meta = {
            total: getData.data.total,
            current_page: getData.data.current_page,
            per_page: getData.data.per_page,
            from: getData.data.from,
            to: getData.data.to,
          };
          this.showspinner = false;
        })
        .catch((error) => {
          this.showspinner = false;
          console.log(error);
          if (error.response.status == 403 || error.response.status == 401) {
            this.$store.dispatch("auth/logout");
            this.$router.push({
              name: "login",
              params: { redirect: this.$route.path },
            });
          }
        });
    },
    handlePerPage(event) {
      this.per_page = event.target.value;
      this.loadActivities(event.target.value);
    },
    handlePagination(val) {
      this.current_page = val;
      this.loadActivities();
    },
    goToact() {
      this.$router.push("/activities");
    },
  },
};
</script>
