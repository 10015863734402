<template>
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a
          class="nav-link"
          data-widget="pushmenu"
          href="#"
          role="button"
          @click="setClass()"
        >
          <i class="fas fa-bars"></i>
        </a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <router-link to="/" class="nav-link">Home</router-link>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a href="javascript:void(0)" @click="logOut()" class="nav-link"
          ><i class="fas fa-sign-out-alt"></i> Logout</a
        >
      </li>
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Navbar Search -->
      <li class="nav-item">
        <a class="nav-link" data-widget="navbar-search" href="#" role="button">
          <i class="fas fa-search"></i>
        </a>
        <div class="navbar-search-block">
          <form class="form-inline">
            <div class="input-group input-group-sm">
              <Autocomplate v-bind:users="userid1" />

              <div class="input-group-append">
                <button class="btn btn-navbar" type="submit">
                  <i class="fas fa-search"></i>
                </button>
                <button class="btn btn-navbar" type="button" data-widget="navbar-search">
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
          </form>
          <ul class="autocomplete-results"></ul>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="fas fa-envelope"></i>
          <span class="badge badge-danger navbar-badge" v-if="messagetotal !== 0">{{
            messagetotal
          }}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <span class="dropdown-item dropdown-header" v-if="messagetotal !== 0"
            >{{ messagetotal }} Message</span
          >
          <div class="dropdown-divider"></div>
          <a
            href="#"
            v-for="(c, index) in newItem"
            :key="index"
            @click="goTopage(c)"
            class="dropdown-item"
          >
            <!-- Message Start -->
            <div class="media">
              <img
                :src="c.image || '/no-image.png'"
                class="img-size-50 mr-3 img-circle"
              />
              <div class="media-body" v-if="c.type == 'Message'">
                <h3 class="dropdown-item-title">
                  {{ c.owner }}
                  <span
                    class="float-right text-sm text-primary"
                    v-if="c.type == 'Message'"
                    ><i class="far fa-envelope"></i
                  ></span>
                </h3>
                <p class="text-sm text-muted">
                  <i class="far fa-clock mr-1"></i> {{ c.overdue }}
                </p>
              </div>
            </div>
            <!-- Message End -->
          </a>
          <div class="dropdown-divider"></div>
          <router-link
            to="/chat-messanger"
            class="dropdown-item dropdown-footer"
            v-if="messagetotal > 0"
            >See All Message</router-link
          >
          <router-link to="/chat-messanger" class="dropdown-item dropdown-footer" v-else
            >Go to Message</router-link
          >
        </div>
      </li>
      <!-- Notifications Dropdown Menu -->
      <!--
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-bell"></i>
          <span class="badge badge-danger navbar-badge" v-if="countnotif !== 0">{{
            countnotif
          }}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <span class="dropdown-item dropdown-header"
            >{{ countnotif }} Notifications</span
          >
          <div class="dropdown-divider"></div>
          <a
            href="#"
            v-for="(c, index) in newItem"
            :key="index"
            @click="goTopage(c)"
            class="dropdown-item"
          >
            <div class="media" v-if="c.type !== 'Message'">
              <img
                :src="c.image || '/no-image.png'"
                class="img-size-50 mr-3 img-circle"
              />
              <div class="media-body">
                <h3 class="dropdown-item-title">
                  {{ c.owner }}
                  <span class="float-right text-sm text-primary" v-if="c.type == 'Event'"
                    ><i class="nav-icon fas fa-chart-line"></i
                  ></span>
                  <span
                    class="float-right text-sm text-primary"
                    v-else-if="c.type == 'Message'"
                    ><i class="far fa-envelope"></i
                  ></span>
                  <span
                    class="float-right text-sm text-danger"
                    v-else-if="c.type == 'Service'"
                    ><i class="fas fa-toolbox"></i
                  ></span>
                  <span
                    class="float-right text-sm text-danger"
                    v-else-if="c.type == 'Appointment'"
                    ><i class="far fa-calendar-check"></i
                  ></span>
                  <span
                    class="float-right text-sm text-danger"
                    v-else-if="c.type == 'Ticket'"
                    ><i class="fas fa-clipboard-list"></i
                  ></span>
                  <span
                    class="float-right text-sm text-danger"
                    v-else-if="c.type == 'Leads'"
                    ><i class="fas fa-book-reader"></i
                  ></span>
                  <span
                    class="float-right text-sm text-danger"
                    v-else-if="c.type == 'Campaign'"
                    ><i class="fas fa-bullhorn"></i
                  ></span>
                </h3>
                <p class="text-sm" v-if="c.type !== 'Message'">
                  {{ c.status + " (" + c.type + ")" }}
                </p>
                <p class="text-sm text-muted">
                  <i class="far fa-clock mr-1"></i> {{ c.overdue }}
                </p>
              </div>
            </div>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a>
        </div>
      </li>
      -->
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-bell"></i>
          <span
            class="badge badge-danger navbar-badge"
            v-if="notificationsnew.length > 0"
            >{{ notificationsnew.length }}</span
          >
        </a>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-right"
          v-if="notificationsnew.length > 0"
        >
          <span class="dropdown-item dropdown-header"
            >{{ notificationsnew.length }} Notifications</span
          >
          <div class="dropdown-divider"></div>
          <a
            href="#"
            v-for="(c, index) in notificationsnew"
            :key="index"
            @click="GoDetail(c, index)"
            class="dropdown-item"
          >
            <div class="media">
              <img
                :src="c.images || '/no-image.png'"
                class="img-size-50 mr-3 img-circle"
              />
              <div class="media-body">
                <h3 class="dropdown-item-title">
                  {{ c.owner }}
                  <span
                    class="float-right text-sm text-primary"
                    v-if="c.notif_type == 'Event'"
                  >
                    <i class="far fa-calendar"></i>
                  </span>
                  <span
                    class="float-right text-sm text-primary"
                    v-else-if="c.notif_type == 'Appointment'"
                  >
                    <i class="fas fa-calendar"></i>
                  </span>
                  <span
                    class="float-right text-sm text-primary"
                    v-else-if="c.notif_type == 'Ticket'"
                  >
                    <img src="/assets/img/ticket.png" />
                  </span>
                  <span
                    class="float-right text-sm text-primary"
                    v-else-if="c.notif_type == 'Comment'"
                  >
                    <i class="fas fa-envelope-open"></i>
                  </span>
                  <span
                    class="float-right text-sm text-primary"
                    v-else-if="c.notif_type == 'Leads'"
                  >
                    <img src="/assets/img/sales-funnel.png" style="width: 18px" />
                  </span>
                  <span
                    class="float-right text-sm text-primary"
                    v-else-if="c.notif_type == 'Campaign'"
                  >
                    <img src="/assets/img/campaign.png" style="width: 18px" />
                  </span>
                  <span class="float-right text-sm text-primary" v-else
                    ><i class="nav-icon fas fa-chart-line"></i
                  ></span>
                </h3>
                <p class="text-sm">
                  {{ c.status + " " + c.title + " (" + c.notif_type + ")" }}
                </p>
                <p class="text-sm text-muted">
                  <i class="far fa-clock mr-1"></i> {{ moment(c.created_at).fromNow() }}
                </p>
              </div>
            </div>
          </a>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-widget="fullscreen" href="#" role="button">
          <i class="fas fa-expand-arrows-alt"></i>
        </a>
      </li>
      <!--
      <li class="nav-item">
        <a
          class="nav-link"
          data-widget="control-sidebar"
          data-slide="true"
          href="#"
          role="button"
        >
          <i class="fas fa-th-large"></i>
        </a>
      </li>
      -->
    </ul>
  </nav>
  <!-- /.navbar -->
</template>

<script>
import authHeader from "../services/auth-header";
import axios from "axios";
import moment from "moment";
import Autocomplate from "./Autocomplate.vue";
//import Timer from "./Time.vue";
//import Clock from "./Clock.vue";
// import io from "socket.io-client";

export default {
  props: {
    moduleAccess: {
      type: Array,
      default: () => [],
    },
    usersdata: {
      type: Object,
      default: () => ({}),
    },
    msgnotif: {
      type: Array,
      default: () => [],
    },
    usernamestorage: {
      type: Object,
      default: () => [{}],
    },
    can_view: {
      type: Boolean,
      default: () => false,
    },
    can_delete: {
      type: Boolean,
      default: () => false,
    },
    can_edit: {
      type: Boolean,
      default: () => false,
    },
    can_create: {
      type: Boolean,
      default: () => false,
    },
    can_export: {
      type: Boolean,
      default: () => false,
    },
    can_import: {
      type: Boolean,
      default: () => false,
    },
    can_filter: {
      type: Boolean,
      default: () => false,
    },
    can_print: {
      type: Boolean,
      default: () => false,
    },
    notification: {
      type: Number,
      default: () => 0,
    },
    accesssidebar: {
      type: Boolean,
      default: () => false,
    },
    notificationsnew: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Autocomplate,
    //Timer,
    //Clock,
  },
  data() {
    return {
      currentDate: 0,
      datauser: [],
      active: true,
      devision_label: "",
      temp: "",
      newItem: [],
      countnotif: 0,
      itemModule: [],
      search: ["Bangalore", "Chennai", "Cochin", "Delhi", "Kolkata", "Mumbai"],
      value_search: null,
      notifmessage: [],
      countmsg: 0,
      moment: moment,
      searchText: "",
      userid1: "",
      messagetotal: 0,
      // socket: io(this.$server_notif, {
      //   transports: ["websocket"],
      // }),
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    imageUser() {
      if (this.currentUser !== 401 || this.currentUser !== null) {
        return this.currentUser.users.image;
      } else {
        return null;
      }
    },
    datausers() {
      return this.currentUser.users;
    },
    module_name() {
      return this.currentUser.permission;
    },
    routeName() {
      return this.$route.name;
    },
    timer() {
      var d = new Date();
      return d.toLocaleTimeString();
    },
  },
  async mounted() {
    if (this.currentUser !== undefined && this.currentUser !== null) {
    }
    $(".form-control-navbar").keyup(function () {
      console.log($(this.e));
    });
  },

  methods: {
    setSidebar() {
      this.$emit("setSidebar");
    },
    getnotifuser() {},
    updatereadmessage(id, iddoc) {},
    setActive() {
      this.active = !this.active;
      let sitebody = document.body;
      this.active
        ? sitebody.classList.add("sidebar-icon-only")
        : sitebody.classList.remove("sidebar-icon-only");
      if (sitebody.classList == "") {
        sitebody.classList.add("sidebar-show");
      } else {
        sitebody.classList.remove("sidebar-show");
      }
    },
    logOut() {
      this.$emit("logout");
    },
    notifMessages() {},
    goTopage(obj) {
      if (obj.type == "Event" || obj.type == "Appointment") {
        var data = {
          activity_id: obj.id,
          assigned_to: this.currentUser.users.id,
        };
        if (obj.type == "Event") {
          localStorage.removeItem("relatedroute");
          const parsed = JSON.stringify({
            name: "activities can view",
            params: {
              id: obj.id,
            },
          });
          localStorage.setItem("relatedroute", parsed);
          this.$router.push({
            name: "activities can view",
            params: {
              id: obj.id,
            },
          });
        }
        axios
          .post(this.$api_url + "update-activity-assigned", data, {
            headers: authHeader(),
          })
          .then(() => {
            this.$router.push({
              name: router,
              params: {
                id: obj.id,
              },
            });
          });
      }
      if (obj.type == "Ticket") {
        this.$router.push({
          name: "ticket can view",
          params: {
            id: obj.id,
          },
        });
      }
      if (obj.type == "Service" || obj.type == "comment") {
        this.$router.push({
          name: "service can view",
          params: {
            id: obj.id,
          },
        });
      }
      if (obj.type == "Campaign") {
        this.$router.push({
          name: "campaign can view",
          params: {
            id: obj.id,
          },
        });
      }
      if (obj.type == "Leads") {
        this.$router.push({
          name: "leads can view",
          params: {
            id: obj.id,
          },
        });
      }
      if (obj.type == "Message") {
        this.$router.push({
          name: "messanger",
        });
      }
    },
    async GoDetail(obj, index) {
      const data = {
        notif_id: obj.id,
      };
      await axios.post(this.$api_url_notif + "update-notification", data).then(() => {
        setTimeout(() => {
          if (obj.notif_type == "Event") {
            this.$router.push({
              name: "activities can view",
              params: { id: obj.related_id },
            });
          } else if (obj.notif_type == "Ticket" || obj.notif_type == "Comment Ticket") {
            this.$router.push({
              name: "ticket can view",
              params: { id: obj.related_id },
            });
          } else if (obj.notif_type == "Appointment") {
            this.$router.push({
              name: "appointment can view",
              params: { id: obj.related_id },
            });
          } else if (obj.notif_type == "Leads") {
            localStorage.removeItem("relatedroute");
            const parsed = JSON.stringify({
              name: "leads can view",
              params: { id: obj.related_id },
            });
            localStorage.setItem("relatedroute", parsed);
            this.$router.push({
              name: "leads can view",
              params: { id: obj.related_id },
            });
          } else if (obj.notif_type == "Campaign") {
            localStorage.removeItem("relatedroute");
            const parsed = JSON.stringify({
              name: "campaign can view",
              params: { id: obj.related_id },
            });
            localStorage.setItem("relatedroute", parsed);
            this.$router.push({
              name: "campaign can view",
              params: { id: obj.related_id },
            });
          } else if (obj.notif_type == "Service") {
            this.$router.push({
              name: "service can view",
              params: { id: obj.related_id },
            });
          }

          // this.socket.open();
        }, 350);
      });
    },
    setClass() {
      if (this.$isMobile()) {
        this.$emit("setClass");
      }
    },
  },
};
</script>

<style></style>
