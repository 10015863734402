<template>
  <div class="card-header bg-primary">
    <h3 class="card-title">Service Complated</h3>
  </div>
  <div class="card-body table-responsive p-0" style="height: 300px">
    <ProgressSpinner v-show="show" />
    <table class="table table-head-fixed text-nowrap">
      <thead>
        <tr>
          <th>Code</th>
          <th>Customer</th>
          <th>Help Topic</th>
          <th>Status</th>
          <th>Owner</th>
        </tr>
      </thead>
      <tbody v-if="Object.keys(paginatedcomplated).length > 0">
        <tr v-for="(b, idx) in paginatedcomplated" :key="idx">
          <td>
            <a href="javascript:void(0)" @click="ShowService(b.id)">
              {{ b.service_code }}
            </a>
          </td>
          <td>{{ b.customerservice.customer_name }}</td>
          <td>{{ b.helptopicservice.help_topic }}</td>
          <td>{{ b.statusservice.status_name }}</td>
          <td>{{ b.usersservice.fullname }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="6">
            <img src="/noTasks.svg" class="rounded mx-auto d-block" />
            <p class="text-center alert-warnings">*** No Data found ***</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="card-footer" v-if="Object.keys(paginatedcomplated).length > 0">
    <Paginator
      v-model="current"
      :rows="Number(metacomplated.per_page)"
      :totalRecords="Number(metacomplated.total)"
      @page="handlePagination($event)"
    >
    </Paginator>
  </div>
</template>
<script>
import Paginator from "primevue/paginator";
import ProgressSpinner from "primevue/progressspinner";

export default {
  components: {
    Paginator,
    ProgressSpinner,
  },
  data() {
    return {
      items: [],
      metacomplated: [],
      current: 1,
      per_page: 5,
      show: false,
    };
  },
  computed: {
    indexStartcomplated() {
      return (this.current - 1) * this.per_page;
    },
    indexEndcomplated() {
      return this.indexStartcomplated + this.per_page;
    },
    paginatedcomplated() {
      var servicecomplated = this.$attrs.complated;
      return servicecomplated.slice(this.indexStartcomplated, this.indexEndcomplated);
    },
  },
  methods: {
    servicecomplated() {
      this.show = true;
      var data = this.$attrs.complated;
      if (Object.keys(data).length > 0) {
        this.metacomplated = {
          total: Object.keys(data).length,
          current: this.current,
          per_page: 10,
          from: this.current,
          to: Object.keys(data).length,
        };
      }
      this.show = false;
    },
    handlePagination(val) {
      this.current = val.page + 1;
      var data = this.$attrs.complated;
      if (Object.keys(data).length > 0) {
        var lengts = 0;
        var from = 1;
        if (this.current !== 1) {
          lengts = 0;
          from = this.metacomplated.current * this.metacomplated.per_page + 1;
        } else {
          from = 1;
          lengts = this.current * this.per_page;
        }
        this.metacomplated = {
          total: Object.keys(data).length,
          current: this.current,
          per_page: 10,
          from: from,
          to: lengts - 1,
        };
      }
    },
    ShowService(id) {
      localStorage.removeItem("relatedroute");
      const parsed = JSON.stringify({
        name: "service can view",
        params: { id: id },
      });
      localStorage.setItem("relatedroute", parsed);
      this.$router.push({ name: "service can view", params: { id: id } });
    },
  },
};
</script>
