<template>
  <div class="col-md-6">
    <div class="card-header d-flex align-items-center justify-content-between mb-4">
      <h5 class="card-title m-0 me-2">
        Top Sale <span class="text-primary">{{ moment().format("MMMM YYYY") }}</span>
      </h5>
    </div>
    <div class="card-body" :class="{ 'scroll-350': items.length > 0 }">
      <ul class="p-0 m-0">
        <li class="d-flex mb-4 pb-1 sktl--isloading" v-if="show">
          <div class="avatar flex-shrink-0 me-3">
            <div class="loading-image"></div>
          </div>
          <div
            class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2 loading-content"
          >
            <div class="me-2 loading-text-container">
              <h6 class="mb-0 loading-main-text"></h6>
              <small class="text-muted d-block mb-1 loading-main-text"></small>
            </div>
            <div
              class="user-progress d-flex align-items-center gap-3 loading-text-container"
            >
              <span class="fw-semibold loading-main-text"></span>
              <span class="badge rounded-pill bg-success loading-main-text"></span>
            </div>
          </div>
        </li>
        <li class="d-flex mb-4 pb-1 sktl--isloading" v-if="show">
          <div class="avatar flex-shrink-0 me-3">
            <div class="loading-image"></div>
          </div>
          <div
            class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2 loading-content"
          >
            <div class="me-2 loading-text-container">
              <h6 class="mb-0 loading-main-text"></h6>
              <small class="text-muted d-block mb-1 loading-main-text"></small>
            </div>
            <div
              class="user-progress d-flex align-items-center gap-3 loading-text-container"
            >
              <span class="fw-semibold loading-main-text"></span>
              <span class="badge rounded-pill bg-success loading-main-text"></span>
            </div>
          </div>
        </li>
        <li class="d-flex mb-4 pb-1 sktl--isloading" v-if="show">
          <div class="avatar flex-shrink-0 me-3">
            <div class="loading-image"></div>
          </div>
          <div
            class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2 loading-content"
          >
            <div class="me-2 loading-text-container">
              <h6 class="mb-0 loading-main-text"></h6>
              <small class="text-muted d-block mb-1 loading-main-text"></small>
            </div>
            <div
              class="user-progress d-flex align-items-center gap-3 loading-text-container"
            >
              <span class="fw-semibold loading-main-text"></span>
              <span class="badge rounded-pill bg-success loading-main-text"></span>
            </div>
          </div>
        </li>
        <li class="d-flex mb-4 pb-1 sktl--isloading" v-if="show">
          <div class="avatar flex-shrink-0 me-3">
            <div class="loading-image"></div>
          </div>
          <div
            class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2 loading-content"
          >
            <div class="me-2 loading-text-container">
              <h6 class="mb-0 loading-main-text"></h6>
              <small class="text-muted d-block mb-1 loading-main-text"></small>
            </div>
            <div
              class="user-progress d-flex align-items-center gap-3 loading-text-container"
            >
              <span class="fw-semibold loading-main-text"></span>
              <span class="badge rounded-pill bg-success loading-main-text"></span>
            </div>
          </div>
        </li>
        <li
          class="d-flex mb-4 pb-1"
          v-for="(b, inx) in items"
          :key="inx"
          v-if="!show && items.length > 0"
        >
          <div class="avatar flex-shrink-0 me-3">
            <img
              :src="
                b.salesuserdcms == null
                  ? '/no-image.png'
                  : b.salesuserdcms.image || '/no-image.png'
              "
              alt="ENVY Laptop"
              class="rounded"
            />
          </div>
          <div
            class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2"
          >
            <div class="me-2">
              <h6 class="mb-0">{{ b.consultan }}</h6>
              <small class="text-muted d-block mb-1">{{ b.branch_name }}</small>
            </div>
            <div class="user-progress d-flex align-items-center gap-3">
              <span class="fw-semibold"
                >Rp. {{ $filters.formatPrice(b.grand_total) }}</span
              >
              <span class="badge rounded-pill bg-success">{{ inx + 1 }}</span>
            </div>
          </div>
        </li>
      </ul>
      <div class="text-center" v-if="!show && items.length == 0">
        <img src="/noTasks.svg" class="rounded mx-auto d-block" />
        *** No data found ***
      </div>
    </div>
  </div>
</template>
<script>
import authHeader from "../../../services/auth-header";
import axios from "axios";
import _ from "lodash";

import moment from "moment";

export default {
  emits: ["stopLoader"],
  data() {
    return {
      moment: moment,
      itemsarray: [],
      show: false,
    };
  },
  computed: {
    items() {
      return _.orderBy(this.itemsarray, ["grand_total"], ["desc"]);
    },
  },
  mounted() {
    this.loadTopsales();
  },
  methods: {
    loadTopsales() {
      this.show = true;
      const data = {
        access: "allowed",
        module: "top sale",
        start_date: moment().startOf("month").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      };
      axios
        .post(this.$api_url + "sales-top-sales-users", data, {
          headers: authHeader(),
        })
        .then((response) => {
          var dataArray = response.data;
          dataArray.forEach((b) => {
            this.itemsarray.push({
              total: b.grand_total,
              branch_name: b.branch_name,
              consultan: b.consultan,
              new_date: b.new_date,
              salesuserdcms: b.salesuserdcms,
              grand_total:
                b.total_refund == null ? b.grand_total : b.grand_total - b.total_refund,
            });
          });
          this.show = false;
          this.$emit("stopLoader");
        })
        .catch((error) => {
          this.show = false;
          console.log(error);
          if (error.response.status == 403 || error.response.status == 401) {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
          }
        });
    },
  },
};
</script>
