<template>
  <div class="card card-dashobar-leads">
    <div class="card-header bg-primary text-white">
      <img src="/assets/img/funnel.png" class="nav-icon rounded-circle" /> Leads Quarterly
      Performance
    </div>
    <div class="card-body" id="leadschart" />
  </div>
</template>
<script>
import authHeader from "../../../services/auth-header";
import axios from "axios";

import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import stockInit from "highcharts/modules/stock";

import moment from "moment";

stockInit(Highcharts);
exportingInit(Highcharts);

export default {
  component: {},
  props: ["start_date", "end_date"],
  data() {
    return {
      moment: moment,
    };
  },
  mounted() {
    this.chartjs();
  },
  methods: {
    chartjs() {
      var date = new Date();
      var firstDay = moment().format("YYYY-MM");
      //var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      var start, end;
      if (this.start_date == null) {
        start = firstDay;
        end = "";
      } else {
        start = this.start_date;
        end = this.end_date;
      }
      const data = {
        start_date: start,
        end_date: end,
      };
      axios
        .post(this.$api_url + "leads-report-total", data, {
          headers: authHeader(),
        })
        .then((response) => {
          var menu = [];
          //var data_chart = []
          //this.data_chart = response.data
          Object.keys(response.data.series).map((key) => {
            menu.push(response.data.series[key].label);
          });
          Highcharts.chart("leadschart", {
            chart: {
              type: "column",
            },
            title: {
              text:
                "Quarterly Performance <br /><br />" + moment(date).format("MMM YYYY"),
            },
            xAxis: {
              //max: response.data.max,
              categories: [...new Set(response.data.category)],
            },
            legend: {
              layout: "vertical",
              align: "right",
              verticalAlign: "middle",
            },

            plotOptions: {
              column: {
                pointPadding: 0.2,
                borderWidth: 0,
              },
            },
            credits: {
              enabled: false,
            },
            series: response.data.series,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
