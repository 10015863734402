<template>
  <div
    class="preloader flex-column justify-content-center align-items-center"
    v-if="this.$route.name !== 'login'"
  >
    <div class="animation__shake" alt="DCMS" :class="preloade" width="60">
      DCM<span style="color: #000000">S</span>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
