<template>
  <div class="col-md-6">
    <div class="card-header d-flex align-items-center justify-content-between mb-4">
      <h5 class="card-title m-0 me-2">
        Top Products <span class="text-primary">{{ moment().format("MMMM YYYY") }}</span>
      </h5>
    </div>
    <div class="card-body" :class="{ 'scroll-350': items.length > 0 }">
      <ul class="p-0 m-0">
        <li class="d-flex mb-4 pb-1 sktl--isloading" v-if="show">
          <div class="avatar flex-shrink-0 me-3">
            <div class="loading-image"></div>
          </div>
          <div
            class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2 loading-content"
          >
            <div class="me-2 loading-text-container">
              <h6 class="mb-0 loading-main-text"></h6>
              <small class="text-muted d-block mb-1 loading-main-text"></small>
            </div>
            <div
              class="user-progress d-flex align-items-center gap-3 loading-text-container"
            >
              <span class="fw-semibold loading-main-text"></span>
              <span class="badge rounded-pill bg-success loading-main-text"></span>
            </div>
          </div>
        </li>
        <li class="d-flex mb-4 pb-1 sktl--isloading" v-if="show">
          <div class="avatar flex-shrink-0 me-3">
            <div class="loading-image"></div>
          </div>
          <div
            class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2 loading-content"
          >
            <div class="me-2 loading-text-container">
              <h6 class="mb-0 loading-main-text"></h6>
              <small class="text-muted d-block mb-1 loading-main-text"></small>
            </div>
            <div
              class="user-progress d-flex align-items-center gap-3 loading-text-container"
            >
              <span class="fw-semibold loading-main-text"></span>
              <span class="badge rounded-pill bg-success loading-main-text"></span>
            </div>
          </div>
        </li>
        <li class="d-flex mb-4 pb-1 sktl--isloading" v-if="show">
          <div class="avatar flex-shrink-0 me-3">
            <div class="loading-image"></div>
          </div>
          <div
            class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2 loading-content"
          >
            <div class="me-2 loading-text-container">
              <h6 class="mb-0 loading-main-text"></h6>
              <small class="text-muted d-block mb-1 loading-main-text"></small>
            </div>
            <div
              class="user-progress d-flex align-items-center gap-3 loading-text-container"
            >
              <span class="fw-semibold loading-main-text"></span>
              <span class="badge rounded-pill bg-success loading-main-text"></span>
            </div>
          </div>
        </li>
        <li class="d-flex mb-4 pb-1 sktl--isloading" v-if="show">
          <div class="avatar flex-shrink-0 me-3">
            <div class="loading-image"></div>
          </div>
          <div
            class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2 loading-content"
          >
            <div class="me-2 loading-text-container">
              <h6 class="mb-0 loading-main-text"></h6>
              <small class="text-muted d-block mb-1 loading-main-text"></small>
            </div>
            <div
              class="user-progress d-flex align-items-center gap-3 loading-text-container"
            >
              <span class="fw-semibold loading-main-text"></span>
              <span class="badge rounded-pill bg-success loading-main-text"></span>
            </div>
          </div>
        </li>
        <template v-if="!show">
          <li class="d-flex mb-4 pb-1" v-for="(b, index) in items" :keys="index">
            <div class="avatar flex-shrink-0 me-3">
              <img
                src="/img/hearing-aid.png"
                alt="oneplus"
                v-if="b.nme == 'Hearing Instrument'"
              />
              <img
                src="/img/battery-hearing.png"
                alt="oneplus"
                class="hear-battery"
                v-if="b.nme == 'Battery'"
              />
              <img src="/img/hearing-repair.png" alt="oneplus" v-if="b.nme == 'Repair'" />
              <img
                src="/img/hearing-repair.png"
                alt="oneplus"
                v-if="b.nme == 'Services'"
              />
              <img src="/img/habilitasi.png" alt="oneplus" v-if="b.nme == 'Habilitasi'" />
              <img
                src="/img/material.png"
                alt="oneplus"
                v-if="b.nme == 'Marketing Material'"
              />
              <img
                src="/img/hearing-accessories.png"
                alt="oneplus"
                v-if="b.nme == 'Accessories'"
              />
              <img src="/img/campaign_120.png" v-else />
            </div>
            <div
              class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2"
            >
              <div class="me-2">
                <h6 class="mb-0">{{ b.model }}</h6>
                <small class="text-muted d-block mb-1"
                  >Category Product : {{ b.nme }} <br />Total Qty : {{ b.qty }}</small
                >
              </div>
              <div class="user-progress d-flex align-items-center gap-1">
                <span class="fw-bold">Rp. {{ $filters.formatPrice(b.total) }}</span>
              </div>
            </div>
          </li>
        </template>
      </ul>
      <div class="text-center" v-if="!show && items.length == 0">
        <img src="/noTasks.svg" class="rounded mx-auto d-block" />
        *** No data found ***
      </div>
    </div>
  </div>
</template>
<script>
import authHeader from "../../../services/auth-header";
import axios from "axios";
import _ from "lodash";
import ProgressSpinner from "primevue/progressspinner";

import moment from "moment";
export default {
  components: {
    ProgressSpinner,
  },
  data() {
    return {
      items: [],
      moment: moment,
      show: false,
    };
  },
  mounted() {
    this.getProductTop();
  },
  methods: {
    getProductTop() {
      this.show = true;
      const data = {
        access: "allowed",
        date: moment().format("YYYY-MM"),
      };
      axios
        .post(this.$api_url + "top-of-product", data, {
          headers: authHeader(),
        })
        .then((response) => {
          var dataArray = response.data;
          this.items = dataArray;
          this.show = false;
        })
        .catch((error) => {
          this.show = false;
          console.log(error);
          if (error.response.status == 403 || error.response.status == 401) {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
          }
        });
    },
  },
};
</script>
