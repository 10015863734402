/*
import AuthService from './auth.service';
*/
import axios from "axios";
import authHeader from "./auth-header";
import $ from "jquery";
import uri from "./func_global_url";

const API_URL = uri.url();

export const func = {
  functionName: (value) => {
    if (value !== "") {
      const data = {
        id: value,
      };
      return axios
        .post(API_URL + "api/edit-users", data, {
          headers: authHeader(),
        })
        .then(function (response) {
          //let dataumapping = JSON.stringify(response.data.data)
          return {
            success: true,
            data: response.data.data,
          };
        });
    } else {
      return "tidak";
    }
  },
  checkAccessroles(role_id, module_name) {
    if (role_id !== "") {
      let permissionname = [];
      const data = {
        role_id: role_id,
        module_name: module_name,
      };
      axios
        .post(API_URL + "api/view-permission", data, {
          headers: authHeader(),
        })
        .then(function (response) {
          let dataroles = response.data;

          Object.keys(dataroles).forEach((key) => {
            permissionname.push({ name: dataroles[key] });
          });
        });

      return permissionname;
    } else {
      return "tidak";
    }
  },
  AccessUser: (modulename, currentroute) => {
    let modulesname = Object.keys(modulename).map((key) => {
      return modulename[key].module_name;
    });
    let shwoname = $.each(modulesname, function (key, value) {
      var exists = false;
      $.each(modulesname, function (k, val2) {
        if (value == val2) {
          exists = true;
        }
      });
      if (exists == false && value != "") {
        modulesname.push(value);
      }
      return modulesname;
    });
    if ($.inArray(currentroute, shwoname) >= 0) {
      return true;
    } else {
      return false;
    }
  },
  permissionaction: (modulename, accesspermision) => {
    let modulesname = Object.keys(modulename).map((key) => {
      return modulename[key].permission_name;
    });
    let shwoname = $.each(modulesname, function (key, value) {
      var exists = false;
      $.each(modulesname, function (k, val2) {
        if (value == val2) {
          exists = true;
        }
      });
      if (exists == false && value != "") {
        modulesname.push(value);
      }
      return modulesname;
    });
    if ($.inArray(accesspermision, shwoname) >= 0) {
      return true;
    } else {
      return false;
    }
    /*
        console.log(currentpermission)
        let permission_identitas = [currentpermission+" can view",currentpermission+" can delete", currentpermission+" can edit", currentpermission+" can creat", currentpermission+" can print"];

        let array_permission = $.each(permission_identitas, function(index, value) {
            if ($.inArray(value, shwoname) >= 0) {
                return value
            }
          });
        */
    //return array_permission
  },
  // RolesFirebase: (firstname, lastname, access, userid, permissions) => {
  //   //let moduleview = this;
  //   //var datamodule = []
  //   if (fire.auth()) {
  //     const itemdata = fire
  //       .database()
  //       .ref("module-access")
  //       .child(firstname + "-" + lastname);
  //     let moduleAccess = Array();
  //     itemdata.on("value", (snapshot) => {
  //       let datamodule = snapshot.val();
  //       let can_view = [];
  //       Object.keys(datamodule).forEach((key) => {
  //         let user_id = datamodule[key].user_id;
  //         let module = datamodule[key].module_name;
  //         let permission = datamodule[key].permission_name;
  //         if (module == access && userid == user_id) {
  //           if (permission == permissions) {
  //             can_view.push(permission);
  //           }
  //         }
  //       });
  //       //console.log('ini ada tidak '+moduleAccess)
  //       moduleAccess.push(can_view);
  //     });

  //     if ($.inArray(moduleAccess, permissions) >= 0) {
  //       console.log(moduleAccess);
  //     } else {
  //       console.log(moduleAccess);
  //     }
  //   }
  // },
};
