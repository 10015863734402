export const access = {
    namespaced: true,
    state: { 
        modules: null
    },
    mutations: {
        increment (state, mdl) {
            state.modules = mdl
        }
    },
    actions: {
        incrementIfOdd({ commit }, arr) {
            commit('increment', arr);
        }
    }
}