<template>
  <div class="card card-sales">
    <div class="card-header bg-primary text-white">
      <img src="/assets/img/statistics.png" class="nav-icon rounded-circle" /> Sales
    </div>
    <div class="sales-progress">
      <h6
        :class="{
          'text-success': totalsalesthismonth > totalsaleslastmonth,
          'text-danger': totalsalesthismonth < totalsaleslastmonth,
        }"
        v-if="totalsaleslastmonth > 0"
      >
        <i
          class="fas fa-caret-up text-success"
          v-if="totalsalesthismonth > totalsaleslastmonth"
        ></i>
        <i class="fas fa-caret-down" v-if="totalsalesthismonth < totalsaleslastmonth"></i>
        Rp. {{ $filters.formatPrice(totalsalesthismonth - totalsaleslastmonth) }}
        <!--
        <br />
        <span v-if="totalsalesthismonth < totalsaleslastmonth">-</span
        ><span v-else>+</span> {{ persentagesales }}%
        -->
      </h6>
    </div>
    <div class="card-body" id="seleschart" />
  </div>
</template>
<script>
import authHeader from "../../../services/auth-header";
import axios from "axios";

import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import stockInit from "highcharts/modules/stock";

import moment from "moment";

stockInit(Highcharts);
exportingInit(Highcharts);

export default {
  data() {
    return {
      moment: moment,
      items: [],
      meta: [],
      current_page: 1,
      per_page: 10,
      isBusy: true,
      totalsalesthismonth: 0,
      totalsaleslastmonth: 0,
      persentagesales: 0,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (this.$attrs.access.includes("customer can view")) {
      this.loadData();
    }
  },
  methods: {
    loadData() {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      this.isBusy = true;
      var start_date = moment().startOf("month").format("YYYY-MM-DD");
      var end_date = moment(new Date()).format("YYYY-MM-DD");
      var access = "";
      const isaccess = ["Manager", "Director", "Manager CSO", "Branch Manager"];
      if (isaccess.includes(this.currentUser.users.get_role.name)) {
        access = "allowed";
      }
      const data = {
        branch: this.currentUser.users.branch_id,
        username: this.currentUser.users.name,
        role_id: this.currentUser.users.role_id,
        start_date: start_date,
        end_date: end_date,
        access: access,
      };
      axios
        .post(this.$api_url + "sales-by-branch", data, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.message == "Unauthenticated") {
            this.$store.dispatch("auth/logout");
            this.$router.push({
              name: "login",
              query: { redirect: this.$route.path },
            });
          }
          let getData = response.data;
          var series = [];
          var totalsales = 0;
          getData.forEach((doc) => {
            totalsales += Number(doc.grand_total - doc.tax_refund);
            series.push({
              name: doc.branch_name,
              y: Number(doc.grand_total - doc.tax_refund),
            });
          });
          this.totalsalesthismonth = totalsales;
          this.getLastmonthsales();
          this.isBusy = false;

          Highcharts.chart("seleschart", {
            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: "pie",
            },
            title: {
              text: "Sale " + moment(firstDay).format("MMM YYYY"),
              x: -20, //center
            },
            subtitle: {
              text:
                document.ontouchstart === undefined
                  ? "Click and drag in the plot area to zoom in"
                  : "Pinch the chart to zoom in",
            },
            credits: {
              enabled: false,
            },
            rangeSelector: {
              selected: 1,
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
              },
            },

            series: [
              {
                name: "Brands",
                colorByPoint: true,
                data: series,
                dataLabels: {
                  formatter: function () {
                    if (this.y > 1000000) {
                      return (
                        this.key + " " + Highcharts.numberFormat(this.y / 1000, 3) + " M"
                      );
                    } else if (this.y > 1000) {
                      return (
                        this.key + " " + Highcharts.numberFormat(this.y / 1000, 3) + " K"
                      );
                    } else {
                      return this.y;
                    }
                    //return this.key + ", " + this.y;
                  },
                  enabled: true,
                  //allowOverlap: true,
                  //align: 'right',
                  //x:-50,
                },
              },
            ],

            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {
                    legend: {
                      layout: "horizontal",
                      align: "center",
                      verticalAlign: "bottom",
                    },
                  },
                },
              ],
            },
          });
        })
        .catch((error) => {
          this.isBusy = false;
          console.log(error);
          if (error.response.status == 403 || error.response.status == 401) {
            this.$store.dispatch("auth/logout");
            this.$router.push({
              name: "login",
              query: { redirect: this.$route.path },
            });
          }
        });
    },
    getLastmonthsales() {
      var lastmonth = moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");
      var start_date = moment(lastmonth).startOf("month").format("YYYY-MM-DD");
      var end_date = lastmonth;
      var access = "";
      const isaccess = ["Manager", "Director", "Manager CSO", "Branch Manager"];
      if (isaccess.includes(this.currentUser.users.get_role.name)) {
        access = "allowed";
      }
      const data = {
        branch: this.currentUser.users.branch_id,
        username: this.currentUser.users.name,
        role_id: this.currentUser.users.role_id,
        years: moment(lastmonth).format("YYYY"),
        month: moment(lastmonth).format("MM"),
        access: access,
      };
      axios
        .post(this.$api_url + "sales-by-branch", data, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.message == "Unauthenticated") {
            this.$store.dispatch("auth/logout");
            this.$router.push({
              name: "login",
              query: { redirect: this.$route.path },
            });
          }
          let getData = response.data;
          var totalsaleslastmonths = 0;
          getData.forEach((b) => {
            totalsaleslastmonths += Number(b.grand_total - b.tax_refund);
          });

          this.totalsaleslastmonth = totalsaleslastmonths;
          var persentagesales =
            ((this.totalsalesthismonth / totalsaleslastmonths) * 100) / 10;
          this.persentagesales = Math.round(persentagesales);
        });
    },
  },
};
</script>
