<template>
  <div class="card">
    <div class="card-header bg-primary text-white">
      <div class="btn btn-link position-relative text-white">
        Users Online
        <span
          class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
        >
          {{ Object.keys(orderedUsers).length }}
          <span class="visually-hidden">unread messages</span>
        </span>
      </div>
      <a
        href="javascript:void(0)"
        @click="closedoverlay($event)"
        class="float-right text-white"
        ><i class="pi pi-times"></i
      ></a>
    </div>
    <div class="card-body">
      <ScrollPanel style="width: 100%; height: 199px">
        <div
          class="d-flex list-of-user"
          v-for="(cdx, index) in orderedUsers"
          :key="index"
          @click="showChatbox($event, index)"
        >
          <div class="flex-shrink-0">
            <img :src="cdx.image || '/no-image.png'" class="rounded-circle avatar-sm" />
          </div>
          <div class="flex-grow-1">
            <p>{{ cdx.fullname }}</p>
            <p class="text-muted">{{ moment(cdx.updated_at).fromNow() }}</p>
          </div>
        </div>
      </ScrollPanel>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import ScrollPanel from "primevue/scrollpanel";

import $ from "jquery";
import authHeader from "../../services/auth-header";
import axios from "axios";
import io from "socket.io-client";

export default {
  name: "chatbox",
  components: {
    ScrollPanel,
  },
  data() {
    return {
      moment: moment,
      useronline: [],
      socket: io(this.$server_notif, {
        transports: ["websocket"],
      }),
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    orderedUsers: function () {
      return _.orderBy(this.useronline, "updated_at", "desc");
    },
  },
  mounted() {
    this.getUsersonline();
    setInterval(() => {
      this.getUsersonline();
    }, 1000);
  },
  methods: {
    showChatbox(event, idx) {
      this.$emit("showChatbox", event, this.orderedUsers[idx]);
    },
    closedoverlay(event) {
      this.$emit("closedoverlay", event);
    },
    getUsersonline() {
      axios
        .get(this.$api_url + "get-users", {
          headers: authHeader(),
          params: {
            q: this.searching,
            role_id: this.role_id,
            branch_id: this.branch_id,
          },
        })
        .then((response) => {
          let getData = response.data;
          this.useronline = [];
          const arrusers = getData.data;
          arrusers.forEach((b) => {
            var now = moment(new Date()); //todays date
            var end = moment(b.updated_at); // another date
            if (b.id !== this.currentUser.users.id && now.diff(end, "hours") == 0) {
              this.useronline.push(b);
            }
          });
        })
        .catch((error) => {
          this.isBusy = false;
          if (error.response.status == 403 || error.response.status == 401) {
            this.$store.dispatch("auth/logout");
            this.$router.push({
              name: "login",
              query: { redirect: this.$route.path },
            });
          }
        });
    },
    removeduplicate(Dataarr) {
      var result = [];
      $.each(Dataarr, function (i, e) {
        var matchingItems = $.grep(result, function (item) {
          return item.fullname === e.fullname;
        });
        if (matchingItems.length === 0) {
          result.push(e);
        }
      });
      return result;
    },
  },
};
</script>
