<template>
  <div class="card">
    <div class="card-header bg-primary text-white">
      <div class="box-left">
        <img
          :src="messages == null ? '/no-image.png' : messages.image"
          alt="Avatar"
          class="rounded-circle"
        />
      </div>
      <span class="user-box-name">{{ messages == null ? "" : messages.fullname }} </span>
      <span class="typings" v-if="typing !== null && typing == currentUser.users.id"
        >is typing</span
      >
      <br />
      <span class="last-online">{{ latesonlines == null ? "" : latesonlines }} </span>
      <a href="javascript:void(0)" class="link-out" @click="goTomessage"
        ><i class="pi pi-external-link"></i
      ></a>
      <a
        href="javascript:void(0)"
        @click="closeboxchat($event)"
        class="float-right text-white"
        ><i class="pi pi-times"></i
      ></a>
    </div>
    <div id="frame">
      <div class="content">
        <div class="messages">
          <ul>
            <template v-for="msg in messagearr">
              <li class="sent" v-if="msg.user_id == currentUser.users.id">
                <img :src="msg.image" alt="" />
                <p v-html="msg.message"></p>
              </li>
              <li class="replies" v-else>
                <img :src="msg.image" alt="" />
                <p v-html="msg.message"></p>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
    <!--
    <div id="chat-messangers" class="card-body">
      <div
        class="box-message"
        v-for="(c, inx) in messagearr"
        :key="inx"
        :class="c.user_id == currentUser.users.id ? 'sent' : 'darker'"
      >
        <img
          :src="c.image"
          alt="Avatar"
          :class="{ right: c.user_id == currentUser.users.id }"
        />
        <p
          v-html="c.message"
          :class="{ 'is-read': c.status == 1, 'no-read': c.status == 0 }"
        ></p>
        <span :class="c.timeclass">{{ moment(c.created_at).fromNow() }}</span>
      </div>
      <div class="position-relative">
        <div class="position-absolute top-0 start-50 translate-middle">
          <span
            class="is-writting"
            style="font-size: 10px; color: #6d7682; font-style: italic"
            v-if="istyping"
            >is write message</span
          >
        </div>
      </div>
    </div>
    -->
    <div class="boox-chat-footer">
      <form class="rounded-pill" @submit.prevent="send">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Write Message.."
            aria-label="Write Message.."
            aria-describedby="button-addon2"
            @focus="updateMesssage()"
            v-model="newMessage"
          />
          <button class="btn btn-primary rounded-circle" type="submit" id="button-addon2">
            <i class="pi pi-send"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import authHeader from "../../services/auth-header";
import axios from "axios";
import io from "socket.io-client";
import _ from "lodash";
import $ from "jquery";
import moment from "moment";

export default {
  props: ["messages"],
  data() {
    return {
      newMessage: null,
      messagearr: [],
      typing: null,
      username: null,
      ready: false,
      info: [],
      connections: 0,
      socket: io(this.$server_notif, {
        transports: ["websocket"],
      }),
      user_id: null,
      user_to: 34,
      datauser: [],
      searching: null,
      to_message: null,
      tabname: "contact",
      dmessage: [],
      isBusy: false,
      moment: moment,
      latesonlines: null,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    oredermessage: function () {
      return _.orderBy(this.arrmessage, ["created_at"], ["asc"]);
    },
  },
  mounted() {
    window.onbeforeunload = () => {
      this.socket.emit("leave", this.user_to);
    };
    this.user_id = this.currentUser.users.id;
    this.socket.on("chat-message", (data) => {
      this.messageRefresh();
    });

    this.socket.on("typing", (data) => {
      this.typing = data;
    });

    this.socket.on("stopTyping", () => {
      this.typing = null;
    });

    this.socket.on("joined", (data) => {
      this.info.push({
        //username: data,
        type: "joined",
      });
      //this.to_message = data;
      this.user_to = data;
      setTimeout(() => {
        this.info = [];
      }, 5000);
    });
    this.socket.on("refreshchat", (data) => {
      this.getDataMessage();
    });
    this.socket.on("leave", (data) => {
      this.info.push({
        username: data,
        type: "left",
      });
      setTimeout(() => {
        this.info = [];
      }, 5000);
    });

    this.socket.on("connections", (data) => {
      this.connections = data;
    });
    this.latesonlines = setInterval(() => {
      if (this.to_message !== null) {
        this.latesonlines = moment(this.to_message.lastonline).fromNow();
      }
    }, 1000);
    this.getDataMessage();
  },
  watch: {
    newMessage(value) {
      value ? this.socket.emit("typing", this.user_to) : this.socket.emit("stopTyping");
    },
  },
  methods: {
    closeboxchat(event) {
      this.$emit("closeboxchat", event);
    },
    ontyping() {},
    stopedtyping() {},
    messageRefresh() {
      const data = {
        user_id: this.to_message.user_id,
        user_to: this.to_message.user_to,
      };
      axios.post(this.$api_url_notif + "get-message-by-users", data).then((response) => {
        const getdata = response.data;
        const arrdata = [];
        getdata.forEach((b) => {
          arrdata.push({
            message: b.message,
            type: b.type,
            user_id: b.user_id,
            user_to: b.user_to,
            status: b.status,
            image: b.image,
          });
        });
        this.messagearr = arrdata;
        //this.socket.emit("chat-message", arrdata);
        $(".messages").animate(
          {
            scrollTop: $(".messages").get(0).scrollHeight,
          },
          200
        );
      });
    },
    getDataMessage(event, obj) {
      if (obj !== undefined) {
        const data = {
          user_id: this.currentUser.users.id,
          user_to: obj.id,
        };
        this.to_message = {
          user_id: this.currentUser.users.id,
          user_to: obj.id,
          fullname: obj.fullname,
          first_name: obj.first_name,
          last_name: obj.last_name,
          image: obj.image,
          image_to: this.currentUser.users.image,
          lastonline: obj.updated_at,
          modify_by: obj.modify_by,
        };
        this.user_to = obj.id;
        //this.socket.emit("joined", data);
        axios
          .post(this.$api_url_notif + "get-message-by-users", data)
          .then((response) => {
            const getdata = response.data;
            const arrdata = [];
            getdata.forEach((b) => {
              arrdata.push({
                message: b.message,
                type: b.type,
                user_id: b.user_id,
                user_to: b.user_to,
                status: b.status,
                image: b.image,
              });
            });
            this.messagearr = arrdata;
            this.socket.emit("chat-message", arrdata);
          });
      }
    },
    scrollToEnd: function () {
      var container = this.$el.querySelector("#chat-messangers");
      container.scrollTop = container.scrollHeight;
    },
    merger_objects(obj1, obj2) {
      $.each(obj2, function (key, value) {
        obj1[key] = value;
      });
      return obj1;
    },
    send() {
      this.socket.open();

      let data;
      if (this.to_message.user_id == this.currentUser.users.id) {
        data = {
          message: this.newMessage,
          user_id: this.to_message.user_id,
          user_to: this.to_message.user_to,
          image: this.currentUser.users.image,
          status: 0,
          image_to: this.to_message.image_to,
          type: 0,
        };
      } else {
        data = {
          message: this.newMessage,
          user_id: this.currentUser.users.id,
          user_to: this.to_message.user_id,
          image: this.currentUser.users.image,
          status: 0,
          image_to: this.to_message.image_to,
          type: 1,
        };
      }
      this.newMessage = null;
      axios.post(this.$api_url_notif + "store-message", data).then(() => {});
    },
    updateMesssage() {
      if (this.to_message !== null && this.to_message.total !== 0) {
        const data = {
          user_to: this.to_message.user_id,
          user_id: this.to_message.user_to,
        };

        axios.post(this.$api_url_notif + "update-message-by-users", data).then(() => {
          this.getDataMessage();
        });
      }
    },
    goTomessage() {
      this.$emit("closeboxchat");
      this.$router.push("/chat-messanger");
    },
  },
};
</script>
<style>
@import "../../assets/chat.css";
</style>
