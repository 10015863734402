<template>
  <div>
    <div>
      <div class="card-header bg-primary text-white">Follow Up After Sales 6 Month</div>
      <ul class="list-group sktl--isloading" v-if="show">
        <li class="list-group-item">
          <div class="loading-image nav-icon rounded-circle"></div>
          <div class="loading-content">
            <div class="date loading-main-text"></div>
          </div>
          <div class="loading-content">
            <div class="date loading-main-text"></div>
          </div>
          <div class="loading-content">
            <div class="date loading-main-text"></div>
          </div>
        </li>
      </ul>
      <ul class="list-group sktl--isloading" v-if="show">
        <li class="list-group-item">
          <div class="loading-image nav-icon rounded-circle"></div>
          <div class="loading-content">
            <div class="date loading-main-text"></div>
          </div>
          <div class="loading-content">
            <div class="date loading-main-text"></div>
          </div>
          <div class="loading-content">
            <div class="date loading-main-text"></div>
          </div>
        </li>
      </ul>
      <ul class="list-group sktl--isloading" v-if="show">
        <li class="list-group-item">
          <div class="loading-image nav-icon rounded-circle"></div>
          <div class="loading-content">
            <div class="date loading-main-text"></div>
          </div>
          <div class="loading-content">
            <div class="date loading-main-text"></div>
          </div>
          <div class="loading-content">
            <div class="date loading-main-text"></div>
          </div>
        </li>
      </ul>
      <template v-if="!show">
        <div
          class="card-primary card-after-sales"
          v-if="Object.keys(this.$attrs.salesaftersixmontharr).length > 0"
        >
          <div class="">
            <ul class="list-group">
              <li
                class="list-group-item"
                v-for="(b, index) in paginatesalesaftersixmontharr"
                :key="index"
                @click="goTo(b.id, index, 41)"
              >
                <img
                  src="/assets/img/rating.png"
                  class="nav-icon rounded-circle"
                  v-if="b.gender == 1"
                />
                <img src="/assets/img/woman.png" class="nav-icon rounded-circle" v-else />
                <span class="date">
                  {{ $filters.capitalize(b.customer_name) }}<br />
                </span>
                <span class="date">
                  Sales Date: {{ moment(b.sales_date).format("DD MMMM YYYY") }}<br />
                </span>
                <span class="date"> Category Product: {{ b.category_product }} </span>
              </li>
            </ul>
          </div>
        </div>
        <ul v-else>
          <img src="/noTasks.svg" class="rounded mx-auto d-block" />
        </ul>
      </template>
    </div>
    <footer
      class="card-footer"
      v-if="Object.keys(this.$attrs.salesaftersixmontharr).length > 0"
    >
      <Paginator
        v-model:first="first"
        :rows="Number(pageSizeone)"
        :totalRecords="Number(metaone.total)"
        @page="handlePagination($event)"
        template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
      >
        <template #start>
          <Button type="button" icon="pi pi-refresh" @click="first = 0" />
        </template>
        <template #end>
          <Button type="button" icon="pi pi-book" />
        </template>
      </Paginator>
    </footer>
  </div>
</template>
<script>
import moment from "moment";
import Paginator from "primevue/paginator";

export default {
  components: {
    Paginator,
  },
  data() {
    return {
      moment: moment,
      pageSizeone: 3,
      currentone: 1,
      metaone: [],
      searchTextone: "",
      suggestiondataone: [],
      show: true,
      first: 0,
    };
  },
  computed: {
    currentoneUser() {
      return this.$store.state.auth.user;
    },
    indexStart() {
      return (this.currentone - 1) * this.pageSizeone;
    },
    indexEnd() {
      return this.indexStart + this.pageSizeone;
    },
    paginatesalesaftersixmontharr() {
      if (this.searchTextone && this.searchTextone.length > 2) {
        if (Object.keys(this.suggestiondataone).length == 0) {
          return [];
        }
        var usercontact1 = this.suggestiondataone;
        return usercontact1.slice(this.indexStart, this.indexEnd);
      } else {
        var usercontact = this.$attrs.salesaftersixmontharr;
        return usercontact.slice(this.indexStart, this.indexEnd);
      }
    },
  },
  methods: {
    loadsales() {
      this.show = true;
      this.searchTextone = "";
      this.suggestiondataone = [];
      var data = this.$attrs.salesaftersixmontharr;
      if (Object.keys(data).length > 0) {
        this.metaone = {
          total: Object.keys(data).length,
          currentone_page: this.currentone,
          per_page: 3,
          from: this.currentone,
          to: Object.keys(data).length,
        };
      }
      this.show = false;
    },
    handlePagination(val) {
      this.show = true;
      this.currentone = val.page + 1;
      var data = this.$attrs.salesaftersixmontharr;
      if (Object.keys(data).length > 0) {
        var lengts = 0;
        var from = 1;
        if (this.currentone !== 1) {
          lengts = 0;
          from = this.metaone.currentone_page * this.metaone.per_page + 1;
        } else {
          from = 1;
          lengts = this.currentone * this.pageSizeone;
        }
        this.metaone = {
          total: Object.keys(data).length,
          currentone_page: this.currentone,
          per_page: 3,
          from: from,
          to: lengts - 1,
        };
      }
      this.show = false;
    },
    goTo(val, index, type) {
      localStorage.removeItem("relatedroute");
      const parsed = JSON.stringify({
        name: "customer can view",
        params: {
          id: val,
          customer: this.paginatesalesaftersixmontharr[index],
          type: type,
        },
      });
      localStorage.setItem("relatedroute", parsed);
      this.$router.push({
        name: "activities can create",
      });
    },
  },
};
</script>
