import uri from "../services/func_global_url";
const API_URL = uri.notificationurl();

import axios from "axios";
import authHeader from "../services/auth-header";

export const notifications = {
  namespaced: true,
  state: {
    notification: []
  },
  getters: {
    getUsers: (state) => state.notification
  },
  actions: {
    async fetchData({
      commit
    }) {
      const user = JSON.parse(localStorage.getItem("user"));
      commit('DELETE_NOTIF')
      await axios
      .get(API_URL + "api/get-notification",{
        headers: authHeader(),
        params:{
          user_id : user.users.id
        }
      })
      .then((response) => {
        if(response.data.length > 0){
          commit('SEt_NOTIF', response.data)
        }
      })
      .catch((error) => {
        return error.response.status;
      });
    },
    async insertData({
        commit
      }) {
        const data = {
            commit
        }
        await axios
        .post(API_URL + "api/insert-notification", data,{
          headers: authHeader(),
        })
        .then((response) => {
          let arr = [];
          console.log(response.data)
          if(response.data.length > 0){
            response.data.forEach((b)=>{
              console.log(b)
              if(b.id !== 1 && b.id !== 2 && b.id !== 3){
                arr.push(b);
              }
            })
            setTimeout(() => {
              commit('SEt_NOTIF', arr)
              
            }, 500);
          }
        })
        .catch((error) => {
          return error.response.status;
        });
      }
  },
  mutations: {
    DELETE_NOTIF(state) {
      state.notification = []
   },
    SEt_NOTIF(state, data) {
      state.notification = data
    }
  }
};