<template>
  <div id="ticket-status">
    <div class="card-header bg-primary text-white borderless">
      <img src="/assets/img/ticket.png" class="nav-icon rounded-circle" /> Ticket
    </div>
    <div class="card-body">
      <ProgressSpinner v-show="show" />
      <div id="container" class="my-1"></div>
    </div>
  </div>
</template>
<script>
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import stockInit from "highcharts/modules/stock";

import authHeader from "../../../services/auth-header";
import axios from "axios";

import ProgressSpinner from "primevue/progressspinner";
//import jQuery from 'jquery'

stockInit(Highcharts);
exportingInit(Highcharts);

export default {
  pluck: ["monthnow", "yearsnow"],
  components: {
    ProgressSpinner,
  },
  data() {
    return {
      series: [],
      show: true,
      categories: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  mounted() {
    this.getLeads();
  },
  methods: {
    getLeads(val) {
      this.show = false;
      var data = {
        month: val,
      };

      var series_open = 0;
      var series_in_progress = 0;
      var series_need_approval = 0;
      var series_approved = 0;
      var series_wait_response = 0;
      var series_closed = 0;
      var series_delivered = 0;
      var series_receive_by_user = 0;

      axios
        .post(this.$api_url + "ticket-by-status", data, {
          headers: authHeader(),
        })
        .then((response) => {
          let data = response.data;
          data.forEach((elem) => {
            if (elem.status == "1") {
              series_open = parseInt(elem.total);
            } else if (elem.status == "2") {
              series_in_progress = parseInt(elem.total);
            } else if (elem.status == "3") {
              series_need_approval = parseInt(elem.total);
            } else if (elem.status == "4") {
              series_approved = parseInt(elem.total);
            } else if (elem.status == "5") {
              series_wait_response = parseInt(elem.total);
            } else if (elem.status == "6") {
              series_closed = parseInt(elem.total);
            } else if (elem.status == "7") {
              series_delivered = parseInt(elem.total);
            } else if (elem.status == "8") {
              series_receive_by_user = parseInt(elem.total);
            }
          });
          /*
                data.forEach(elem => {
                    if(elem.statusname == '1'){
                        series_new.data.push({
                            x: +new Date(elem.month),
                            y: elem.count
                        });
                    }
                });
                */
          Highcharts.chart("container", {
            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: "pie",
            },
            title: {
              text: "Ticket " + this.$attrs.monthnow + " " + this.$attrs.yearsnow,
              x: -20, //center
            },
            subtitle: {
              text:
                document.ontouchstart === undefined
                  ? "Click and drag in the plot area to zoom in"
                  : "Pinch the chart to zoom in",
            },
            credits: {
              enabled: false,
            },
            /*
                        xAxis: {
                            type: 'datetime',
                        },
                        */
            rangeSelector: {
              selected: 1,
            },

            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: true,
                  format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                },
              },
            },
            series: [
              {
                name: "Ticket",
                colorByPoint: true,
                data: [
                  {
                    name: "Open",
                    y: series_open,
                  },
                  {
                    name: "In Progress",
                    y: series_in_progress,
                  },
                  {
                    name: "Need Approval",
                    y: series_need_approval,
                  },
                  {
                    name: "Approved",
                    y: series_approved,
                  },
                  {
                    name: "Wait for Respone",
                    y: series_wait_response,
                  },
                  {
                    name: "Closed",
                    y: series_closed,
                  },
                  {
                    name: "Delivered",
                    y: series_delivered,
                  },
                  {
                    name: "Received by User",
                    y: series_receive_by_user,
                  },
                ],
              },
            ],
            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {
                    legend: {
                      layout: "horizontal",
                      align: "center",
                      verticalAlign: "bottom",
                    },
                  },
                },
              ],
            },
          });
        });
      this.show = false;
    },
  },
};
</script>
