<template>
  <div class="card no-padding-card card-birthdays">
    <div class="card-header bg-primary text-white">
      <img src="/assets/img/birthday-cake.png" class="nav-icon rounded-circle" /> Todays
      Birthday's
    </div>
    <div class="card-body card-birthdays row">
      <div class="info-box sktl--isloading" v-if="show">
        <div class="info-box-icon loading-image">
          <div class="nav-icon rounded-circle" />
        </div>
        <div class="info-box-content loading-content">
          <div class="info-box-text loading-main-text"></div>
          <div class="info-box-text loading-main-text"></div>
        </div>
      </div>
      <div class="info-box sktl--isloading" v-if="show">
        <div class="info-box-icon loading-image">
          <div class="nav-icon rounded-circle" />
        </div>
        <div class="info-box-content loading-content">
          <div class="info-box-text loading-main-text"></div>
          <div class="info-box-text loading-main-text"></div>
        </div>
      </div>
      <div class="info-box sktl--isloading" v-if="show">
        <div class="info-box-icon loading-image">
          <div class="nav-icon rounded-circle" />
        </div>
        <div class="info-box-content loading-content">
          <div class="info-box-text loading-main-text"></div>
          <div class="info-box-text loading-main-text"></div>
        </div>
      </div>
      <div class="info-box sktl--isloading" v-if="show">
        <div class="info-box-icon loading-image">
          <div class="nav-icon rounded-circle" />
        </div>
        <div class="info-box-content loading-content">
          <div class="info-box-text loading-main-text"></div>
          <div class="info-box-text loading-main-text"></div>
        </div>
      </div>
      <div class="info-box sktl--isloading" v-if="show">
        <div class="info-box-icon loading-image">
          <div class="nav-icon rounded-circle" />
        </div>
        <div class="info-box-content loading-content">
          <div class="info-box-text loading-main-text"></div>
          <div class="info-box-text loading-main-text"></div>
        </div>
      </div>

      <template v-if="!show">
        <div class="info-box" v-for="(b, idx) in birthdays" :key="idx">
          <span
            class="info-box-icon"
            :class="{
              'bg-info': b.genders == 'Female',
              'bg-success': b.genders == 'Male',
            }"
            ><img
              src="/assets/img/rating.png"
              class="nav-icon rounded-circle"
              v-if="b.genders == 'Male'" />
            <img src="/assets/img/woman.png" class="nav-icon rounded-circle" v-else
          /></span>
          <div class="info-box-content">
            <span class="info-box-text">
              <router-link
                :to="{ name: 'customer can view', params: { id: b.idcustomer } }"
                >{{ b.customer_name }}</router-link
              >
            </span>
            <span class="info-box-number"
              >{{ moment(b.birthdays).format("DD MMMM YYYY") }}, {{ b.age }} old</span
            >
            <a
              href="javascript:void(0)"
              @click="
                getLinkWhastapp(
                  b.mobile_no,
                  'Happy birthdays ' + b.customer_name + ' yang ke ' + b.age
                )
              "
              class="absolute-position"
              title="Send whatsapp"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              ><i class="fa fa-lg fa-whatsapp" style="color: #51f626"></i
            ></a>
          </div>
        </div>
      </template>
      <img
        src="/noTasks.svg"
        class="rounded mx-auto d-block"
        v-if="birthdays.length == 0"
      />
      <p class="text-center alert-warnings" v-if="birthdays.length == 0">
        *** No Data found ***
      </p>
    </div>
    <div class="card-footer" v-if="birthdays.length > 0">
      <Paginator
        v-model:first="first"
        :rows="Number(per_pages)"
        :totalRecords="Number(meta.total)"
        @page="handlePagination($event)"
        template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
      >
        <template #start>
          <Button type="button" icon="pi pi-refresh" @click="first = 0" />
        </template>
        <template #end>
          <Button type="button" icon="pi pi-book" />
        </template>
      </Paginator>
    </div>
  </div>
</template>
<script>
import authHeader from "../../../services/auth-header";
import axios from "axios";
import moment from "moment";

import Paginator from "primevue/paginator";
import ProgressSpinner from "primevue/progressspinner";

import cryptos from "./../../crypto.js";

export default {
  components: {
    Paginator,
    ProgressSpinner,
  },
  data() {
    return {
      moment: moment,
      show: true,
      birthdays: [],
      meta: [],
      current_page: 1,
      per_pages: 6,
      first: 0,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.show = true;
      let current_page = this.current_page;
      var data = {};
      axios
        .post(this.$api_url + "customer-birthdays", data, {
          headers: authHeader(),
          params: {
            page: current_page,
            per_page: this.per_pages,
            search: this.searching,
            branch: this.currentUser.users.branch_id,
            username: this.currentUser.users.name,
            role_id: this.currentUser.users.role_id,
          },
        })
        .then((response) => {
          var getData = response.data;
          var birthdays = [];
          getData.data.forEach((b) => {
            const cryptoid = cryptos.aesEncrypt(b.customer_id.toString());
            birthdays.push({
              genders: b.genders,
              customer_name: b.customer_name,
              age: b.age,
              mobile_no: b.mobile_no,
              customer_id: b.customer_id,
              idcustomer: cryptoid,
            });
          });
          this.birthdays = birthdays;
          this.meta = {
            total: getData.total,
            current_page: getData.current_page,
            per_pages: getData.per_page,
            from: getData.from,
            to: getData.to,
          };
          this.show = false;
        })
        .catch((error) => {
          this.show = false;
          console.log(error);
          if (error.response.status == 403 || error.response.status == 401) {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
          }
        });
    },
    handlePerPage(event) {
      this.per_pages = event.target.value;
      this.loadData(event.target.value);
    },
    handlePagination(val) {
      this.current_page = val.page + 1;
      this.loadData();
    },
    getLinkWhastapp(number, message) {
      //var message    = 'Happy birthdays';
      number = number.replace(/08/g, "+628");
      var url =
        "https://api.whatsapp.com/send?phone=" +
        number +
        "&text=" +
        encodeURIComponent(message);
      window.open(url, "_blank");
    },
  },
};
</script>
