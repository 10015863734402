<template>
  <aside class="control-sidebar control-sidebar-dark">
    <div
      class="p-3 control-sidebar-content os-host os-theme-light os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition os-host-overflow os-host-overflow-y"
      style="height: 375px"
    >
      <div class="os-resize-observer-host observed">
        <div class="os-resize-observer" style="left: 0px; right: auto"></div>
      </div>
      <div
        class="os-size-auto-observer observed"
        style="height: calc(100% + 1px); float: left"
      >
        <div class="os-resize-observer"></div>
      </div>
      <div
        class="os-content-glue"
        style="margin: -16px; width: 249px; height: 374px"
      ></div>
      <div class="os-padding">
        <div
          class="os-viewport os-viewport-native-scrollbars-invisible os-viewport-native-scrollbars-overlaid"
          style="overflow-y: scroll"
        >
          <div class="os-content" style="padding: 16px; height: 100%; width: 100%">
            <h5>Customize Template</h5>
            <hr class="mb-2" />
            <div class="mb-4">
              <input
                type="checkbox"
                @click="Modecolor($event)"
                value="1"
                class="mr-1"
              /><span>Dark Mode</span>
            </div>
            <!--
            <h6>Header Options</h6>
            <div class="mb-1">
              <input type="checkbox" value="1" class="mr-1" /><span>Fixed</span>
            </div>
            <div class="mb-1">
              <input type="checkbox" value="1" class="mr-1" /><span
                >Dropdown Legacy Offset</span
              >
            </div>
            <div class="mb-4">
              <input type="checkbox" value="1" class="mr-1" /><span>No border</span>
            </div>
            <h6>Sidebar Options</h6>
            <div class="mb-1">
              <input type="checkbox" value="1" class="mr-1" /><span>Collapsed</span>
            </div>
            <div class="mb-1">
              <input type="checkbox" value="1" checked="checked" class="mr-1" /><span
                >Fixed</span
              >
            </div>
            <div class="mb-1">
              <input type="checkbox" value="1" checked="checked" class="mr-1" /><span
                >Sidebar Mini</span
              >
            </div>
            <div class="mb-1">
              <input type="checkbox" value="1" class="mr-1" /><span>Sidebar Mini MD</span>
            </div>
            <div class="mb-1">
              <input type="checkbox" value="1" class="mr-1" /><span>Sidebar Mini XS</span>
            </div>
            <div class="mb-1">
              <input type="checkbox" value="1" class="mr-1" /><span>Nav Flat Style</span>
            </div>
            <div class="mb-1">
              <input type="checkbox" value="1" class="mr-1" /><span
                >Nav Legacy Style</span
              >
            </div>
            <div class="mb-1">
              <input type="checkbox" value="1" class="mr-1" /><span>Nav Compact</span>
            </div>
            <div class="mb-1">
              <input type="checkbox" value="1" class="mr-1" /><span
                >Nav Child Indent</span
              >
            </div>
            <div class="mb-1">
              <input type="checkbox" value="1" class="mr-1" /><span
                >Nav Child Hide on Collapse</span
              >
            </div>
            <div class="mb-4">
              <input type="checkbox" value="1" class="mr-1" /><span
                >Disable Hover/Focus Auto-Expand</span
              >
            </div>
            
            <h6>Footer Options</h6>
            <div class="mb-4">
              <input type="checkbox" value="1" class="mr-1" /><span>Fixed</span>
            </div>
            <h6>Small Text Options</h6>
            <div class="mb-1">
              <input type="checkbox" value="1" class="mr-1" /><span>Body</span>
            </div>
            <div class="mb-1">
              <input type="checkbox" value="1" class="mr-1" /><span>Navbar</span>
            </div>
            <div class="mb-1">
              <input type="checkbox" value="1" class="mr-1" /><span>Brand</span>
            </div>
            <div class="mb-1">
              <input type="checkbox" value="1" class="mr-1" /><span>Sidebar Nav</span>
            </div>
            <div class="mb-4">
              <input type="checkbox" value="1" class="mr-1" /><span>Footer</span>
            </div>
            <h6>Navbar Variants</h6>
            <div class="d-flex">
              <select class="custom-select mb-3 text-light border-0 bg-white">
                <option class="bg-primary">Primary</option>
                <option class="bg-secondary">Secondary</option>
                <option class="bg-info">Info</option>
                <option class="bg-success">Success</option>
                <option class="bg-danger">Danger</option>
                <option class="bg-indigo">Indigo</option>
                <option class="bg-purple">Purple</option>
                <option class="bg-pink">Pink</option>
                <option class="bg-navy">Navy</option>
                <option class="bg-lightblue">Lightblue</option>
                <option class="bg-teal">Teal</option>
                <option class="bg-cyan">Cyan</option>
                <option class="bg-dark">Dark</option>
                <option class="bg-gray-dark">Gray dark</option>
                <option class="bg-gray">Gray</option>
                <option class="bg-light">Light</option>
                <option class="bg-warning">Warning</option>
                <option class="bg-white">White</option>
                <option class="bg-orange">Orange</option>
              </select>
            </div>
            <h6>Accent Color Variants</h6>
            <div class="d-flex"></div>
            <select class="custom-select mb-3 border-0">
              <option>None Selected</option>
              <option class="bg-primary">Primary</option>
              <option class="bg-warning">Warning</option>
              <option class="bg-info">Info</option>
              <option class="bg-danger">Danger</option>
              <option class="bg-success">Success</option>
              <option class="bg-indigo">Indigo</option>
              <option class="bg-lightblue">Lightblue</option>
              <option class="bg-navy">Navy</option>
              <option class="bg-purple">Purple</option>
              <option class="bg-fuchsia">Fuchsia</option>
              <option class="bg-pink">Pink</option>
              <option class="bg-maroon">Maroon</option>
              <option class="bg-orange">Orange</option>
              <option class="bg-lime">Lime</option>
              <option class="bg-teal">Teal</option>
              <option class="bg-olive">Olive</option>
            </select>
            <h6>Dark Sidebar Variants</h6>
            <div class="d-flex"></div>
            <select class="custom-select mb-3 text-light border-0 bg-primary">
              <option>None Selected</option>
              <option class="bg-primary">Primary</option>
              <option class="bg-warning">Warning</option>
              <option class="bg-info">Info</option>
              <option class="bg-danger">Danger</option>
              <option class="bg-success">Success</option>
              <option class="bg-indigo">Indigo</option>
              <option class="bg-lightblue">Lightblue</option>
              <option class="bg-navy">Navy</option>
              <option class="bg-purple">Purple</option>
              <option class="bg-fuchsia">Fuchsia</option>
              <option class="bg-pink">Pink</option>
              <option class="bg-maroon">Maroon</option>
              <option class="bg-orange">Orange</option>
              <option class="bg-lime">Lime</option>
              <option class="bg-teal">Teal</option>
              <option class="bg-olive">Olive</option>
            </select>
            <h6>Light Sidebar Variants</h6>
            <div class="d-flex"></div>
            <select class="custom-select mb-3 border-0">
              <option>None Selected</option>
              <option class="bg-primary">Primary</option>
              <option class="bg-warning">Warning</option>
              <option class="bg-info">Info</option>
              <option class="bg-danger">Danger</option>
              <option class="bg-success">Success</option>
              <option class="bg-indigo">Indigo</option>
              <option class="bg-lightblue">Lightblue</option>
              <option class="bg-navy">Navy</option>
              <option class="bg-purple">Purple</option>
              <option class="bg-fuchsia">Fuchsia</option>
              <option class="bg-pink">Pink</option>
              <option class="bg-maroon">Maroon</option>
              <option class="bg-orange">Orange</option>
              <option class="bg-lime">Lime</option>
              <option class="bg-teal">Teal</option>
              <option class="bg-olive">Olive</option>
            </select>
            <h6>Brand Logo Variants</h6>
            <div class="d-flex"></div>
            <select class="custom-select mb-3 border-0">
              <option>None Selected</option>
              <option class="bg-primary">Primary</option>
              <option class="bg-secondary">Secondary</option>
              <option class="bg-info">Info</option>
              <option class="bg-success">Success</option>
              <option class="bg-danger">Danger</option>
              <option class="bg-indigo">Indigo</option>
              <option class="bg-purple">Purple</option>
              <option class="bg-pink">Pink</option>
              <option class="bg-navy">Navy</option>
              <option class="bg-lightblue">Lightblue</option>
              <option class="bg-teal">Teal</option>
              <option class="bg-cyan">Cyan</option>
              <option class="bg-dark">Dark</option>
              <option class="bg-gray-dark">Gray dark</option>
              <option class="bg-gray">Gray</option>
              <option class="bg-light">Light</option>
              <option class="bg-warning">Warning</option>
              <option class="bg-white">White</option>
              <option class="bg-orange">Orange</option>
              <a href="#">clear</a>
            </select>
            -->
          </div>
        </div>
      </div>
      <div
        class="os-scrollbar os-scrollbar-horizontal os-scrollbar-unusable os-scrollbar-auto-hidden"
      >
        <div class="os-scrollbar-track">
          <div
            class="os-scrollbar-handle"
            style="transform: translate(0px); width: 100%"
          ></div>
        </div>
      </div>
      <div class="os-scrollbar os-scrollbar-vertical os-scrollbar-auto-hidden">
        <div class="os-scrollbar-track">
          <div
            class="os-scrollbar-handle"
            style="transform: translate(0px); height: 29.0248%"
          ></div>
        </div>
      </div>
      <div class="os-scrollbar-corner"></div>
    </div>
  </aside>
</template>

<script>
import $ from "jquery";
export default {
  methods: {
    Modecolor(evt) {
      let sitebody = document.body;
      if (evt.target.checked) {
        sitebody.classList.add("dark-mode");
      } else {
        $("body").removeClass("dark-mode");
      }
    },
  },
};
</script>

<style></style>
