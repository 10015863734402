import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Vlogin from "../views/Login.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  
  {
    path: "/login",
    name: "login",
    component:Vlogin
  },
  {
    path: "/contact",
    name: "customer",
    meta: {
      can_view: "customer can view",
      can_filter: "customer can fillter",
      can_delete: "customer can delete",
      can_edit: "customer can edit",
    },
    component: () => import("../views/contact/Index.vue"),
  },
  {
    path: "/contact/create",
    name: "customer can create",
    meta: {
      can_view: "customer can view",
      can_filter: "customer can fillter",
      can_delete: "customer can delete",
      can_edit: "customer can edit",
    },
    component: () => import("../views/contact/Create.vue"),
  },
  {
    path: "/contact/view/:id",
    name: "customer can view",
    meta: {
      can_view: "customer can view",
      can_filter: "customer can fillter",
      can_delete: "customer can delete",
      can_edit: "customer can edit",
    },
    //component: () => import("../views/contact/View.vue"),
    component: () => import("../views/contact/mobile/Viewcustomer.vue"),
  },
  {
    path: "/audiogram/create",
    name: "audiogram can create",
    meta: {
      can_view: "audiogram can view",
      can_filter: "audiogram can fillter",
      can_delete: "audiogram can delete",
      can_edit: "audiogram can edit",
      can_create: "audiogram can create"
    },
    component: () => import("../views/audiogram/Create.vue"),
  },
  {
    path: "/audiogram/detail/:id",
    name: "audiogram detail",
    meta: {
      can_view: "audiogram can view",
      can_filter: "audiogram can fillter",
      can_delete: "audiogram can delete",
      can_edit: "audiogram can edit",
      can_create: "audiogram can create"
    },
    component: () => import("../views/audiogram/View.vue"),
  },
  {
    path: "/invoice/detail-:id",
    name: "invoice can edit",
    meta: {
      can_view: "invoice can view",
      can_filter: "invoice can fillter",
      can_delete: "invoice can delete",
      can_edit: "invoice can edit",
      can_create: "invoice can create",
      can_print: "invoice can print",
    },
    component: () => import("../views/invoice/Detail.vue"),
  },
  {
    path: "/invoice/create",
    name: "invoice can create",
    meta: {
      can_view: "invoice can view",
      can_filter: "invoice can fillter",
      can_delete: "invoice can delete",
      can_edit: "invoice can edit",
      can_create: "invoice can create",
      can_print: "invoice can print",
    },
    component: () => import("../views/invoice/Create.vue"),
  },
  {
    path: "/campaign",
    name: "campaign",
    meta: {
      can_view: "campaign can view",
      can_filter: "campaign can fillter",
      can_delete: "campaign can delete",
      can_edit: "campaign can edit",
      can_create: "campaign can create",
      can_print: "campaign can print",
    },
    component: () => import("../views/campaign/Checkagint.vue"),
  },
  {
    path: "/campaign/:id",
    name: "campaign can view",
    meta: {
      can_view: "campaign can view",
      can_filter: "campaign can fillter",
      can_delete: "campaign can delete",
      can_edit: "campaign can edit",
      can_create: "campaign can create",
      can_print: "campaign can print",
    },
    component: () => import("../views/campaign/Checkagaintview.vue"),
  },
  {
    path: "/campaign/create",
    name: "campaign can create",
    meta: {
      can_view: "campaign can view",
      can_filter: "campaign can fillter",
      can_delete: "campaign can delete",
      can_edit: "campaign can edit",
      can_create: "campaign can create",
      can_print: "campaign can print",
    },
    component: () => import("../views/campaign/Create.vue"),
  },
  {
    path: "/leads",
    name: "leads",
    meta: {
      can_view: "leads can view",
      can_filter: "leads can fillter",
      can_delete: "leads can delete",
      can_edit: "leads can edit",
      can_create: "leads can create",
      can_print: "leads can print",
      can_import: "leads can import",
    },
    component: () => import("../views/leads/Checkagaint.vue"),
  },
  {
    path: "/leads/create",
    name: "leads can create",
    meta: {
      can_view: "leads can view",
      can_filter: "leads can fillter",
      can_delete: "leads can delete",
      can_edit: "leads can edit",
      can_create: "leads can create",
      can_print: "leads can print",
      can_import: "leads can import",
    },
    component: () => import("../views/leads/Create.vue"),
  },
  {
    path: "/leads/view/:id",
    name: "leads can view",
    meta: {
      can_view: "leads can view",
      can_filter: "leads can fillter",
      can_delete: "leads can delete",
      can_edit: "leads can edit",
      can_create: "leads can create",
      can_print: "leads can print",
    },
    component: () => import("../views/leads/Checkagaintview.vue"),
  },
  {
    path: "/files-related/view/:id",
    name: "files detail",
    meta: {
      can_view: "files can view",
      can_filter: "files can fillter",
      can_delete: "files can delete",
      can_edit: "files can edit",
      can_create: "files can create",
      can_print: "files can print",
    },
    component: () => import("../views/files/FilesIndex.vue"),
  },
  {
    path: "/index-of-files",
    name: "files",
    meta: {
      can_view: "files can view",
      can_filter: "files can fillter",
      can_delete: "files can delete",
      can_edit: "files can edit",
      can_create: "files can create",
      can_print: "files can print",
    },
    component: () => import("../views/files/Indexall.vue"),
  },
  {
    path: "/notes-related/view/:id",
    name: "note group",
    meta: {
      can_view: "note can view",
      can_filter: "note can fillter",
      can_delete: "note can delete",
      can_edit: "note can edit",
      can_create: "note can create",
      can_print: "note can print",
    },
    component: () => import("../views/notes/NoteIndex.vue"),
  },
  {
    path: "/index-of-notes",
    name: "note",
    meta: {
      can_view: "note can view",
      can_filter: "note can fillter",
      can_delete: "note can delete",
      can_edit: "note can edit",
      can_create: "note can create",
      can_print: "note can print",
    },
    component: () => import("../views/notes/Index.vue"),
  },
  
  {
    path: "/roles",
    name: "roles",
    meta: {
      can_view: "roles can view",
      can_filter: "roles can fillter",
      can_delete: "roles can delete",
      can_edit: "roles can edit",
      can_create: "roles can create",
      can_print: "roles can print",
    },
    component: () => import("../views/roles/Index.vue"),
  },
  {
    path: "/roles/create",
    name: "roles can create",
    meta: {
      can_view: "roles can view",
      can_filter: "roles can fillter",
      can_delete: "roles can delete",
      can_edit: "roles can edit",
      can_create: "roles can create",
      can_print: "roles can print",
    },
    component: () => import("../views/roles/Create.vue"),
  },
  {
    path: "/roles/edit:id",
    name: "modul",
    meta: {
      can_view: "modul can view",
      can_filter: "modul can fillter",
      can_delete: "modul can delete",
      can_edit: "modul can edit",
      can_create: "modul can create",
      can_print: "modul can print",
    },
    component: () => import("../views/roles/Edit.vue"),
  },
  {
    path: "/roles/view/:id",
    name: "roles can view",
    meta: {
      can_view: "roles can view",
      can_filter: "roles can fillter",
      can_delete: "roles can delete",
      can_edit: "roles can edit",
      can_create: "roles can create",
      can_print: "roles can print",
    },
    component: () => import("../views/roles/View.vue"),
  },
  {
    path: "/activities",
    name: "activities",
    meta: {
      can_view: "activities can view",
      can_filter: "activities can fillter",
      can_delete: "activities can delete",
      can_edit: "activities can edit",
      can_create: "activities can create",
      can_print: "activities can print",
    },
    component: () => import("../views/activities/Checkagaintindex.vue"),
  },
  {
    path: "/activities/detail/:id",
    name: "activities can view",
    meta: {
      can_view: "activities can view",
      can_filter: "activities can fillter",
      can_delete: "activities can delete",
      can_edit: "activities can edit",
      can_create: "activities can create",
      can_print: "activities can print",
    },
    component: () => import("../views/activities/Checkagaintview.vue"),
  },
  {
    path: "/activities/create",
    name: "activities can create",
    meta: {
      can_view: "activities can view",
      can_filter: "activities can fillter",
      can_delete: "activities can delete",
      can_edit: "activities can edit",
      can_create: "activities can create",
      can_print: "activities can print",
    },
    component: () => import("../views/activities/Create.vue"),
  },
  {
    path: "/appointment",
    name: "appointment",
    meta: {
      can_view: "appointment can view",
      can_filter: "appointment can fillter",
      can_delete: "appointment can delete",
      can_edit: "appointment can edit",
      can_create: "appointment can create",
      can_print: "appointment can print",
    },
    component: () => import("../views/appointment/Index.vue"),
  },
  {
    path: "/appointment/approval",
    name: "appointment approval",
    meta: {
      can_view: "appointment approval can view",
      can_filter: "appointment approval can fillter",
      can_delete: "appointment approval can delete",
      can_edit: "appointment approval can edit",
      can_create: "appointment approval can create",
      can_print: "appointment approval can print",
    },
    component: () => import("../views/appointment/approval/Index.vue"),
  },
  {
    path: "/appointment/create",
    name: "appointment can create",
    meta: {
      can_view: "appointment can view",
      can_filter: "appointment can fillter",
      can_delete: "appointment can delete",
      can_edit: "appointment can edit",
      can_create: "appointment can create",
      can_print: "appointment can print",
    },
    component: () => import("../views/appointment/Create.vue"),
  },
  {
    path: "/appointment/event/:id",
    name: "appointment can view",
    meta: {
      can_view: "appointment can view",
      can_filter: "appointment can fillter",
      can_delete: "appointment can delete",
      can_edit: "appointment can edit",
      can_create: "appointment can create",
      can_print: "appointment can print",
    },
    component: () => import("../views/appointment/Checkagaintview.vue"),
  },
  {
    path: "/ticket",
    name: "ticket",
    meta: {
      can_view: "ticket can view",
      can_filter: "ticket can fillter",
      can_delete: "ticket can delete",
      can_edit: "ticket can edit",
      can_create: "ticket can create",
      can_print: "ticket can print",
    },
    component: () => import("../views/ticket/Checkagaint.vue"),
  },
  {
    path: "/ticket/create",
    name: "ticket can create",
    meta: {
      can_view: "ticket can view",
      can_filter: "ticket can fillter",
      can_delete: "ticket can delete",
      can_edit: "ticket can edit",
      can_create: "ticket can create",
      can_print: "ticket can print",
    },
    component: () => import("../views/ticket/Create.vue"),
  },
  {
    path: "/ticket/:id",
    name: "ticket can view",
    meta: {
      can_view: "ticket can view",
      can_filter: "ticket can fillter",
      can_delete: "ticket can delete",
      can_edit: "ticket can edit",
      can_create: "ticket can create",
      can_print: "ticket can print",
    },
    component: () => import("../views/ticket/Checkagaintview.vue"),
  },
  {
    path: "/service",
    name: "service",
    meta: {
      can_view: "service can view",
      can_filter: "service can fillter",
      can_delete: "service can delete",
      can_edit: "service can edit",
      can_create: "service can create",
      can_print: "service can print",
    },
    component: () => import("../views/service/Index.vue"),
  },
   {
    path: "/service/create",
    name: "service can create",
    meta: {
      can_view: "service can view",
      can_filter: "service can fillter",
      can_delete: "service can delete",
      can_edit: "service can edit",
      can_create: "service can create",
      can_print: "service can print",
    },
    component: () => import("../views/service/Create.vue"),
  },
  {
    path: "/service/detail/:id",
    name: "service can view",
    meta: {
      can_view: "service can view",
      can_filter: "service can fillter",
      can_delete: "service can delete",
      can_edit: "service can edit",
      can_create: "service can create",
      can_print: "service can print",
    },
    component: () => import("../views/service/View.vue"),
  },
  {
    path: "/event/calendar",
    name: "calendar",
    meta: {
      can_view: "calender can view",
      can_filter: "calender can fillter",
      can_delete: "calender can delete",
      can_edit: "calender can edit",
      can_create: "calender can create",
      can_print: "calender can print",
    },
    component: () => import("../views/calendar/Index.vue"),
  },
  {
    path: "/report/ticket",
    name: "report ticket",
    meta: {
      can_view: "report ticket can view",
      can_filter: "report ticket can fillter",
      can_delete: "report ticket can delete",
      can_edit: "report ticket can edit",
      can_create: "report ticket can create",
      can_print: "report ticket can print",
    },
    component: () => import("../views/report/ticket/Index.vue"),
  },
  {
    path: "/report/event",
    name: "event report can view",
    meta: {
      can_view: "event report can view",
      can_filter: "event report can fillter",
      can_delete: "event report can delete",
      can_edit: "event report can edit",
      can_create: "event report can create",
      can_print: "event report can print",
    },
    component: () => import("../views/report/event/Index.vue"),
  },
  {
    path: "/report/activity",
    name: "report activity can view",
    meta: {
      can_view: "report activity can view",
      can_filter: "report activity can fillter",
      can_delete: "report activity can delete",
      can_edit: "report activity can edit",
      can_create: "report activity can create",
      can_print: "report activity can print",
    },
    component: () => import("../views/report/activity/Index.vue"),
  },
  {
    path: "/report/sale",
    name: "report sales",
    meta: {
      can_view: "report sales can view",
      can_filter: "report sales can fillter",
      can_delete: "report sales can delete",
      can_edit: "report sales can edit",
      can_create: "report sales can create",
      can_print: "report sales can print",
    },
    component: () => import("../views/report/Sales.vue"),
  },
  {
    path: "/users",
    name: "user",
    meta: {
      can_view: "user can view",
      can_filter: "user can fillter",
      can_delete: "user can delete",
      can_edit: "user can edit",
      can_create: "user can create",
      can_print: "user can print",
      can_import: "user can import",
      can_export: 'user can export'
    },
    component: () => import("../views/users/Index.vue"),
  },
  {
    path: "/users/view/:id",
    name: "user can view",
    meta: {
      can_view: "user can view",
      can_filter: "user can fillter",
      can_delete: "user can delete",
      can_edit: "user can edit",
      can_create: "user can create",
      can_print: "user can print",
      can_import: "user can import",
    },
    component: () => import("../views/users/View.vue"),
  },
  {
    path: "/users/profile/:id",
    name: "user_profile_detail",
    component: () => import("../views/users/Profile2.vue"),
  },
  {
    path: "/users/profile",
    name: "profile",
    component: () => import("../views/users/Profile.vue"),
  },
  {
    path: "/users/create",
    name: "user can create",
    meta: {
      can_view: "user can view",
      can_filter: "user can fillter",
      can_delete: "user can delete",
      can_edit: "user can edit",
      can_create: "user can create",
      can_print: "user can print",
      can_import: "user can import",
    },
    component: () => import("../views/users/Create.vue"),
  },
  {
    path: "/users-log",
    name: "history",
    meta: {
      can_view: "history can view",
      can_filter: "history can fillter",
      can_delete: "history can delete",
      can_edit: "history can edit",
      can_create: "history can create",
      can_print: "history can print",
      can_import: "history can import",
    },
    component: () => import("../views/logs/History.vue"),
  },
  {
    path: "/branch",
    name: "branch",
    meta: {
      can_view: "branch can view",
      can_filter: "branch can fillter",
      can_delete: "branch can delete",
      can_edit: "branch can edit",
      can_create: "branch can create",
      can_print: "branch can print",
      can_import: "branch can import",
    },
    component: () => import("../views/branch/Index.vue"),
  },
  {
    path: "/branch/view/:id",
    name: "branch can view",
    meta: {
      can_view: "branch can view",
      can_filter: "branch can fillter",
      can_delete: "branch can delete",
      can_edit: "branch can edit",
      can_create: "branch can create",
      can_print: "branch can print",
      can_import: "branch can import",
    },
    component: () => import("../views/branch/View.vue"),
  },
  {
    path: "/demo-page",
    name: "demo",
    component: () => import("../views/Demo.vue"),
  },
  {
    path: "/product/catalog",
    name: "product",
    meta: {
      can_view: "product can view",
      can_filter: "product can fillter",
      can_delete: "product can delete",
      can_edit: "product can edit",
      can_create: "product can create",
      can_print: "product can print",
      can_import: "product can import",
    },
    component: () => import("../views/product/catalog/Index.vue"),
  },
  {
    path: "/product/catalog/view/:id",
    name: "product can view",
    meta: {
      can_view: "product can view",
      can_filter: "product can fillter",
      can_delete: "product can delete",
      can_edit: "product can edit",
      can_create: "product can create",
      can_print: "product can print",
      can_import: "product can import",
    },
    component: () => import("../views/product/catalog/View.vue"),
  },
  {
    path: "/product/color",
    name: "product color",
    meta: {
      can_view: "product color can view",
      can_filter: "product color can fillter",
      can_delete: "product color can delete",
      can_edit: "product color can edit",
      can_create: "product color can create",
      can_print: "product color can print",
      can_import: "product color can import",
    },
    component: () => import("../views/product/color/Index.vue"),
  },
  {
    path: "/product/canal-leght",
    name: "canal leght",
    meta: {
      can_view: "product canal leght can view",
      can_filter: "product canal leght can fillter",
      can_delete: "product canal leght can delete",
      can_edit: "product canal leght can edit",
      can_create: "product canal leght can create",
      can_print: "product canal leght can print",
      can_import: "product canal leght can import",
    },
    component: () => import("../views/product/canalleght/Index.vue"),
  },
  {
    path: "/product/dexterity",
    name: "dexterity",
    meta: {
      can_view: "product dexterity can view",
      can_filter: "product dexterity can fillter",
      can_delete: "product dexterity can delete",
      can_edit: "product dexterity can edit",
      can_create: "product dexterity can create",
      can_print: "product dexterity can print",
      can_import: "product dexterity can import",
    },
    component: () => import("../views/product/dexterity/Index.vue"),
  },
  {
    path: "/product/hearing-loss-data",
    name: "hearing loss",
    meta: {
      can_view: "product hearing loss can view",
      can_filter: "product hearing loss can fillter",
      can_delete: "product hearing loss can delete",
      can_edit: "product hearing loss can edit",
      can_create: "product hearing loss can create",
      can_print: "product hearing loss can print",
      can_import: "product hearing loss can import",
    },
    component: () => import("../views/product/hearingloss/Index.vue"),
  },
  {
    path: "/product/receiver-gaint",
    name: "receiver gaint",
    meta: {
      can_view: "product receiver gaint can view",
      can_filter: "product receiver gaint can fillter",
      can_delete: "product receiver gaint can delete",
      can_edit: "product receiver gaint can edit",
      can_create: "product receiver gaint can create",
      can_print: "product receiver gaint can print",
      can_import: "product receiver gaint can import",
    },
    component: () => import("../views/product/receivergaint/Index.vue"),
  },
  {
    path: "/product/style",
    name: "style",
    meta: {
      can_view: "product style can view",
      can_filter: "product style can fillter",
      can_delete: "product style can delete",
      can_edit: "product style can edit",
      can_create: "product style can create",
      can_print: "product style can print",
      can_import: "product style can import",
    },
    component: () => import("../views/product/style/Index.vue"),
  },
  {
    path: "/product/tube",
    name: "tube",
    meta: {
      can_view: "product tube can view",
      can_filter: "product tube can fillter",
      can_delete: "product tube can delete",
      can_edit: "product tube can edit",
      can_create: "product tube can create",
      can_print: "product tube can print",
      can_import: "product tube can import",
    },
    component: () => import("../views/product/tube/Index.vue"),
  },
  {
    path: "/product/tube",
    name: "venting",
    meta: {
      can_view: "product venting can view",
      can_filter: "product venting can fillter",
      can_delete: "product venting can delete",
      can_edit: "product venting can edit",
      can_create: "product venting can create",
      can_print: "product venting can print",
      can_import: "product venting can import",
    },
    component: () => import("../views/product/venting/Index.vue"),
  },
  {
    path: "/event/master/type",
    name: "type activities",
    meta: {
      can_view: "type activities can view",
      can_filter: "type activities can fillter",
      can_delete: "type activities can delete",
      can_edit: "type activities can edit",
      can_create: "type activities can create",
      can_print: "type activities can print",
      can_import: "type activities can import",
    },
    component: () => import("../views/eventmaster/type/Index.vue"),
  },
  {
    path: "/event/master/question",
    name: "question",
    meta: {
      can_view: "question can view",
      can_filter: "question can fillter",
      can_delete: "question can delete",
      can_edit: "question can edit",
      can_create: "question can create",
      can_print: "question can print",
      can_import: "question can import",
    },
    component: () => import("../views/eventmaster/question/Index.vue"),
  },
  {
    path: "/service/master/action-code",
    name: "service action code",
    meta: {
      can_view: "service action code can view",
      can_filter: "service action code can fillter",
      can_delete: "service action code can delete",
      can_edit: "service action code can edit",
      can_create: "service action code can create",
      can_print: "service action code can print",
      can_import: "service action code can import",
    },
    component: () => import("../views/service/master/actioncode/Index.vue"),
  },
  {
    path: "/service/master/complaint",
    name: "service complaint",
    meta: {
      can_view: "service complaint can view",
      can_filter: "service complaint can fillter",
      can_delete: "service complaint can delete",
      can_edit: "service complaint can edit",
      can_create: "service complaint can create",
      can_print: "service complaint can print",
      can_import: "service complaint can import",
    },
    component: () => import("../views/service/master/complaint/Index.vue"),
  },
  {
    path: "/service/master/complaint-code",
    name: "service complaint code",
    meta: {
      can_view: "service complaint code can view",
      can_filter: "service complaint code can fillter",
      can_delete: "service complaint code can delete",
      can_edit: "service complaint code can edit",
      can_create: "service complaint code can create",
      can_print: "service complaint code can print",
      can_import: "service complaint code can import",
    },
    component: () => import("../views/service/master/complaintcode/Index.vue"),
  },
  {
    path: "/service/master/demo-model",
    name: "service demo model",
    meta: {
      can_view: "service demo model can view",
      can_filter: "service demo model can fillter",
      can_delete: "service demo model can delete",
      can_edit: "service demo model can edit",
      can_create: "service demo model can create",
      can_print: "service demo model can print",
      can_import: "service demo model can import",
    },
    component: () => import("../views/service/master/demomodel/Index.vue"),
  },
  {
    path: "/service/master/help-topic",
    name: "service help topic",
    meta: {
      can_view: "service help topic can view",
      can_filter: "service help topic can fillter",
      can_delete: "service help topic can delete",
      can_edit: "service help topic can edit",
      can_create: "service help topic can create",
      can_print: "service help topic can print",
      can_import: "service help topic can import",
    },
    component: () => import("../views/service/master/helptopic/Index.vue"),
  },
  {
    path: "/service/master/prosentase",
    name: "service prosentase",
    meta: {
      can_view: "service prosentase can view",
      can_filter: "service prosentase can fillter",
      can_delete: "service prosentase can delete",
      can_edit: "service prosentase can edit",
      can_create: "service prosentase can create",
      can_print: "service prosentase can print",
      can_import: "service prosentase can import",
    },
    component: () => import("../views/service/master/prosentase/Index.vue"),
  },
  {
    path: "/service/master/sla-plant",
    name: "service sla plant",
    meta: {
      can_view: "service sla plant can view",
      can_filter: "service sla plant can fillter",
      can_delete: "service sla plant can delete",
      can_edit: "service sla plant can edit",
      can_create: "service sla plant can create",
      can_print: "service sla plant can print",
      can_import: "service sla plant can import",
    },
    component: () => import("../views/service/master/slaplant/Index.vue"),
  },
  {
    path: "/service/master/spartpart-replacemant",
    name: "service spartpart replacemant",
    meta: {
      can_view: "service spartpart replacemant can view",
      can_filter: "service spartpart replacemant can fillter",
      can_delete: "service spartpart replacemant can delete",
      can_edit: "service spartpart replacemant can edit",
      can_create: "service spartpart replacemant can create",
      can_print: "service spartpart replacemant can print",
      can_import: "service spartpart replacemant can import",
    },
    component: () => import("../views/service/master/sparepart/Index.vue"),
  },
  {
    path: "/service/master/source",
    name: "service source",
    meta: {
      can_view: "service source can view",
      can_filter: "service source can fillter",
      can_delete: "service source can delete",
      can_edit: "service source can edit",
      can_create: "service source can create",
      can_print: "service source can print",
      can_import: "service source can import",
    },
    component: () => import("../views/service/master/source/Index.vue"),
  },
  {
    path: "/service/master/status",
    name: "service status",
    meta: {
      can_view: "service status can view",
      can_filter: "service status can fillter",
      can_delete: "service status can delete",
      can_edit: "service status can edit",
      can_create: "service status can create",
      can_print: "service status can print",
      can_import: "service status can import",
    },
    component: () => import("../views/service/master/status/Index.vue"),
  },
  {
    path: "/service/master/canned-response",
    name: "service canned response",
    meta: {
      can_view: "service canned response can view",
      can_filter: "service canned response can fillter",
      can_delete: "service canned response can delete",
      can_edit: "service canned response can edit",
      can_create: "service canned response can create",
      can_print: "service canned response can print",
      can_import: "service canned response can import",
    },
    component: () => import("../views/service/master/cannedrespon/Index.vue"),
  },
  {
    path: "/ticket/master/category",
    name: "ticket category",
    meta: {
      can_view: "ticket category can view",
      can_filter: "ticket category can fillter",
      can_delete: "ticket category can delete",
      can_edit: "ticket category can edit",
      can_create: "ticket category can create",
      can_print: "ticket category can print",
      can_import: "ticket category can import",
    },
    component: () => import("../views/ticket/master/category/Index.vue"),
  },
  {
    path: "/ticket/master/status",
    name: "ticket status",
    meta: {
      can_view: "ticket status can view",
      can_filter: "ticket status can fillter",
      can_delete: "ticket status can delete",
      can_edit: "ticket status can edit",
      can_create: "ticket status can create",
      can_print: "ticket status can print",
      can_import: "ticket status can import",
    },
    component: () => import("../views/ticket/master/status/Index.vue"),
  },
  {
    path: "/ticket/master/severity",
    name: "ticket severity",
    meta: {
      can_view: "ticket severity can view",
      can_filter: "ticket severity can fillter",
      can_delete: "ticket severity can delete",
      can_edit: "ticket severity can edit",
      can_create: "ticket severity can create",
      can_print: "ticket severity can print",
      can_import: "ticket severity can import",
    },
    component: () => import("../views/ticket/master/severity/Index.vue"),
  },
  {
    path: "/ticket/master/source",
    name: "ticket source",
    meta: {
      can_view: "ticket source can view",
      can_filter: "ticket source can fillter",
      can_delete: "ticket source can delete",
      can_edit: "ticket source can edit",
      can_create: "ticket source can create",
      can_print: "ticket source can print",
      can_import: "ticket source can import",
    },
    component: () => import("../views/ticket/master/source/Index.vue"),
  },
  {
    path: "/ticket/master/subcategory",
    name: "ticket subcategory",
    meta: {
      can_view: "ticket subcategory can view",
      can_filter: "ticket subcategory can fillter",
      can_delete: "ticket subcategory can delete",
      can_edit: "ticket subcategory can edit",
      can_create: "ticket subcategory can create",
      can_print: "ticket subcategory can print",
      can_import: "ticket subcategory can import",
    },
    component: () => import("../views/ticket/master/abdtopic/Index.vue"),
  },
  {
    path: "/ticket/master/priority",
    name: "ticket priority",
    meta: {
      can_view: "ticket priority can view",
      can_filter: "ticket priority can fillter",
      can_delete: "ticket priority can delete",
      can_edit: "ticket priority can edit",
      can_create: "ticket priority can create",
      can_print: "ticket priority can print",
      can_import: "ticket priority can import",
    },
    component: () => import("../views/ticket/master/priority/Index.vue"),
  },
  
  {
    path: "/ticket/master/topic",
    name: "ticket topic",
    meta: {
      can_view: "ticket topic can view",
      can_filter: "ticket topic can fillter",
      can_delete: "ticket topic can delete",
      can_edit: "ticket topic can edit",
      can_create: "ticket topic can create",
      can_print: "ticket topic can print",
      can_import: "ticket topic can import",
    },
    component: () => import("../views/ticket/master/topic/Index.vue"),
  },
  {
    path: "/promotion",
    name: "promotion",
    meta: {
      can_view: "promotion can view",
      can_filter: "promotion can fillter",
      can_delete: "promotion can delete",
      can_edit: "promotion can edit",
      can_create: "promotion can create",
      can_print: "promotion can print",
      can_import: "promotion can import",
    },
    component: () => import("../views/promotion/Index.vue"),
  },
  {
    path: "/material",
    name: "documentation",
    meta: {
      can_view: "documentation can view",
      can_filter: "documentation can fillter",
      can_delete: "documentation can delete",
      can_edit: "documentation can edit",
      can_create: "documentation can create",
      can_print: "documentation can print",
      can_import: "documentation can import",
    },
    component: () => import("../views/material/master/Index.vue"),
  },
  {
    path: "/material/detail-create",
    name: "documentation can create",
    meta: {
      can_view: "documentation can view",
      can_filter: "documentation can fillter",
      can_delete: "documentation can delete",
      can_edit: "documentation can edit",
      can_create: "documentation can create",
      can_print: "documentation can print",
      can_import: "documentation can import",
    },
    component: () => import("../views/material/master/Createdocsdetail.vue"),
  },
  {
    path: "/user-guide/:id",
    name: "documentation can view",
    meta: {
      can_view: "documentation can view",
      can_filter: "documentation can fillter",
      can_delete: "documentation can delete",
      can_edit: "documentation can edit",
      can_create: "documentation can create",
      can_print: "documentation can print",
      can_import: "documentation can import",
    },
    component: () => import("../views/material/Index.vue"),
  },
  {
    path: "/report/leads",
    name: "report lead can view",
    meta: {
      can_view: "report lead can view",
      can_filter: "report lead can fillter",
      can_delete: "report lead can delete",
      can_edit: "report lead can edit",
      can_create: "report lead can create",
      can_print: "report lead can print",
      can_import: "report lead can import",
      can_export: "report lead can export",
    },
    component: () => import("../views/report/leads/Index.vue"),
  },
  {
    path: "/report/hearing-clinic",
    name: "report clinical info can view",
    meta: {
      can_view: "report clinical info can view",
      can_filter: "report clinical info can fillter",
      can_delete: "report clinical info can delete",
      can_edit: "report clinical info can edit",
      can_create: "report clinical info can create",
      can_print: "report clinical info can print",
      can_import: "report clinical info can import",
      can_export: "report clinical info can export",
    },
    component: () => import("../views/report/clinicalinfo/Index.vue"),
  },
  {
    path: "/master/finnal-interested",
    name: "finnal interested",
    meta: {
      can_view: "finnal interested can view",
      can_filter: "finnal interested can fillter",
      can_delete: "finnal interested can delete",
      can_edit: "finnal interested can edit",
      can_create: "finnal interested can create",
      can_print: "finnal interested can print",
      can_import: "finnal interested can import",
      can_export: "finnal interested can export",
    },
    component: () => import("../views/leads/master/Finnalinterested.vue"),
  },
  {
    path: "/master/client-condition",
    name: "client condition",
    meta: {
      can_view: "client condition can view",
      can_filter: "client condition can fillter",
      can_delete: "client condition can delete",
      can_edit: "client condition can edit",
      can_create: "client condition can create",
      can_print: "client condition can print",
      can_import: "client condition can import",
      can_export: "client condition can export",
    },
    component: () => import("../views/leads/master/Clientcondition.vue"),
  },
  {
    path: "/messanger",
    name: "messanger",
    component: () => import("../views/messanger/Index.vue"),
  },
  {
    path: "/inbox",
    name: "inbox",
    component: () => import("../views/inbox/Index.vue"),
  },
  {
    path: "/report/repeat-potential",
    name: "report potential",
    component: () => import("../views/report/repeatpotential/Index.vue"),
  },
  {
    path: "/report/appointment",
    name: "appointment count report",
    component: () => import("../views/report/appointment/Index.vue"),
    meta: {
      can_view: "appointment count report can view",
      can_filter: "appointment count report can fillter",
      can_delete: "appointment count report can delete",
      can_edit: "appointment count report can edit",
      can_create: "appointment count report can create",
      can_print: "appointment count report can print",
      can_import: "appointment count report can import",
      can_export: "appointment count report can export",
    },
  },
  {
    path: "/report/follow-up-after-sales",
    name: "report after sales",
    component: () => import("../views/report/aftersales/Index.vue"),
    meta: {
      can_view: "report after sales can view",
      can_filter: "report after sales can fillter",
      can_delete: "report after sales can delete",
      can_edit: "report after sales can edit",
      can_create: "report after sales can create",
      can_print: "report after sales can print",
      can_import: "report after sales can import",
      can_export: "report after sales can export",
    },
  },
  {
    path: "/password-reset",
    name: "reset password",
    component: () => import("../views/Resetpassword.vue"),
  },
  {
    path: "/chat-messanger",
    name: "messangers",
    component: () => import("../views/message/Index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
