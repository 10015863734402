<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary bg-white elevation-4">
    <!-- Brand Logo -->
    <router-link to="/" class="brand-link">
      <img
        src="/abdi.png"
        alt="AdminLTE Logo"
        class="brand-image elevation-3"
        style="opacity: 0.8"
      />
    </router-link>

    <!-- Sidebar -->
    <div class="sidebar bg-white">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img
            :src="imageUser == null ? '/customer.png' : imageUser"
            class="img-circle elevation-2"
            alt="User Image"
          />
        </div>
        <div class="info" v-if="currentUser && currentUser.users">
          <router-link
            :to="{ name: 'profile' }"
            class="d-block"
            v-if="currentUser.users"
            >{{
              currentUser.users.first_name + " " + currentUser.users.last_name
            }}</router-link
          >
        </div>
      </div>
      <nav class="mt-2" v-if="accessname !== null">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <li class="nav-item" @click="toggleSidebar()">
            <router-link class="nav-link" to="/">
              <img src="/assets/img/dashboard.png" class="nav-icon" />
              <p>Dashboard</p>
            </router-link>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <img src="/assets/img/doc.png" class="nav-icon" />
              <p>
                Docs
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview" v-if="Object.keys(menudocs).length > 0">
              <li
                class="nav-item"
                v-for="(d, index) in menudocs"
                :key="index"
                @click="toggleSidebar()"
              >
                <a href="javascript:void(0)" class="nav-link" @click="goTodoc(d.id)">
                  <i class="nav-icon fas fa-minus"></i>
                  <p>{{ d.title }}</p>

                  <p></p
                ></a>
              </li>
            </ul>
          </li>
          <li
            class="nav-item"
            v-if="
              accessname.includes('user can view') ||
              accessname.includes('roles can view') ||
              accessname.includes('branch can view') ||
              accessname.includes('ticket category can view') ||
              accessname.includes('ticket status can view') ||
              accessname.includes('ticket severity can view') ||
              accessname.includes('ticket sourcecan view') ||
              accessname.includes('ticket subcategory can view') ||
              accessname.includes('ticket priority can view') ||
              accessname.includes('type activities can view') ||
              accessname.includes('question can view') ||
              accessname.includes('service action code can view') ||
              accessname.includes('service complaint can view') ||
              accessname.includes('service complaint code can view') ||
              accessname.includes('service demo model can view') ||
              accessname.includes('service help topic can view') ||
              accessname.includes('service prosentase can view') ||
              accessname.includes('service sla plant can view') ||
              accessname.includes('service spartpart replacemant can view') ||
              accessname.includes('service source can view') ||
              accessname.includes('service status can view') ||
              accessname.includes('service sanned response can view')
            "
          >
            <a href="javascript:void(0)" class="nav-link">
              <img src="/assets/img/settings.png" class="nav-icon" />
              <p>
                Setting
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li
                class="nav-item"
                @click="toggleSidebar()"
                v-if="accessname.includes('roles can view')"
              >
                <router-link :to="{ name: 'roles' }" class="nav-link">
                  <img src="/assets/img/settings.png" class="nav-icon" />
                  <p>Roles</p>
                </router-link>
              </li>
              <li
                class="nav-item"
                @click="toggleSidebar()"
                v-if="accessname.includes('documentation can view')"
              >
                <router-link to="/material" class="nav-link">
                  <img src="/assets/img/doc.png" class="nav-icon" />
                  <p>Docs Guied</p>
                </router-link>
              </li>
              <li
                class="nav-item"
                @click="toggleSidebar()"
                v-if="accessname.includes('user can view')"
              >
                <router-link to="/users" class="nav-link">
                  <img src="/assets/img/man.png" class="nav-icon" />
                  <p>User</p>
                </router-link>
              </li>
              <li
                class="nav-item"
                @click="toggleSidebar()"
                v-if="accessname.includes('branch can view')"
              >
                <router-link to="/branch" class="nav-link">
                  <img src="/assets/img/office-building.png" class="nav-icon" />
                  <p>Branch</p>
                </router-link>
              </li>
              <li class="nav-item" v-if="accessname.includes('product can view')">
                <a href="#" class="nav-link">
                  <img
                    src="https://www.pusatalatbantudengar.com/images/Picture7.png"
                    class="nav-icon"
                  />
                  <p>
                    Product
                    <i class="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul class="nav nav-treeview sub-2">
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('product can view')"
                  >
                    <router-link :to="{ name: 'product' }" class="nav-link">
                      <i class="far fa-dot-circle nav-icon"></i>
                      <p>Catalog</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('product color can view')"
                  >
                    <router-link :to="{ name: 'product color' }" class="nav-link">
                      <i class="far fa-dot-circle nav-icon"></i>
                      <p>Color</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('product canal leght can view')"
                  >
                    <router-link :to="{ name: 'canal leght' }" class="nav-link">
                      <i class="far fa-dot-circle nav-icon"></i>
                      <p>Canal Leght</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('product dexterity can view')"
                  >
                    <router-link :to="{ name: 'dexterity' }" class="nav-link">
                      <i class="far fa-dot-circle nav-icon"></i>
                      <p>Dexterity</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('product hearing loss can view')"
                  >
                    <router-link :to="{ name: 'hearing loss' }" class="nav-link">
                      <i class="far fa-dot-circle nav-icon"></i>
                      <p>Hearing Loss Data</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('product receiver gaint can view')"
                  >
                    <router-link :to="{ name: 'receiver gaint' }" class="nav-link">
                      <i class="far fa-dot-circle nav-icon"></i>
                      <p>Receiver Gaint</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('product style can view')"
                  >
                    <router-link :to="{ name: 'style' }" class="nav-link">
                      <i class="far fa-dot-circle nav-icon"></i>
                      <p>Style</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('product tube can view')"
                  >
                    <router-link :to="{ name: 'tube' }" class="nav-link">
                      <i class="far fa-dot-circle nav-icon"></i>
                      <p>Tube</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('product venting can view')"
                  >
                    <router-link :to="{ name: 'venting' }" class="nav-link">
                      <i class="far fa-dot-circle nav-icon"></i>
                      <p>Venting</p></router-link
                    >
                  </li>
                </ul>
              </li>

              <li
                class="nav-item"
                v-if="
                  accessname.includes('type activities can view') ||
                  accessname.includes('question can view')
                "
              >
                <a href="#" class="nav-link">
                  <img src="/assets/img/schedule.png" class="nav-icon" />
                  <p>
                    Event
                    <i class="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul class="nav nav-treeview">
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('type activities can view')"
                  >
                    <router-link
                      :to="{ name: 'type activities' }"
                      v-on:click="toggle"
                      class="nav-link"
                      ><i class="far fa-dot-circle nav-icon"></i>
                      <p>Type</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('question can view')"
                  >
                    <router-link
                      :to="{ name: 'question' }"
                      v-on:click="toggle"
                      class="nav-link"
                      ><i class="far fa-dot-circle nav-icon"></i>
                      <p>Question</p></router-link
                    >
                  </li>
                </ul>
              </li>
              <li
                class="nav-item"
                v-if="
                  accessname.includes('service action code can view') ||
                  accessname.includes('service complaint can view') ||
                  accessname.includes('service complaint code can view') ||
                  accessname.includes('service demo model can view') ||
                  accessname.includes('service help topic can view') ||
                  accessname.includes('service prosentase can view') ||
                  accessname.includes('service sla plant can view') ||
                  accessname.includes('service spartpart replacemant can view') ||
                  accessname.includes('service source can view') ||
                  accessname.includes('service status can view') ||
                  accessname.includes('service sanned response can view')
                "
              >
                <a href="#" class="nav-link">
                  <img src="/assets/img/tool-box.png" class="nav-icon" />
                  <p>
                    Service
                    <i class="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul class="nav nav-treeview">
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('service action code can view')"
                  >
                    <router-link
                      :to="{ name: 'service action code' }"
                      v-on:click="toggle"
                      class="nav-link"
                      ><i class="far fa-dot-circle nav-icon"></i>
                      <p>Action Code</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('service complaint can view')"
                  >
                    <router-link
                      :to="{ name: 'service complaint' }"
                      v-on:click="toggle"
                      class="nav-link"
                      ><i class="far fa-dot-circle nav-icon"></i>
                      <p>Complaint</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('service complaint code can view')"
                  >
                    <router-link
                      :to="{ name: 'service complaint code' }"
                      v-on:click="toggle"
                      class="nav-link"
                      ><i class="far fa-dot-circle nav-icon"></i>
                      <p>Complaint Code</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('service demo model can view')"
                  >
                    <router-link
                      :to="{ name: 'service demo model' }"
                      v-on:click="toggle"
                      class="nav-link"
                      ><i class="far fa-dot-circle nav-icon"></i>
                      <p>Demo Model</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('service help topic can view')"
                  >
                    <router-link
                      :to="{ name: 'service help topic' }"
                      v-on:click="toggle"
                      class="nav-link"
                      ><i class="far fa-dot-circle nav-icon"></i>
                      <p>Help Topic</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('service prosentase can view')"
                  >
                    <router-link
                      :to="{ name: 'service prosentase' }"
                      v-on:click="toggle"
                      class="nav-link"
                      ><i class="far fa-dot-circle nav-icon"></i>
                      <p>Prosentase</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('service sla plant can view')"
                  >
                    <router-link
                      :to="{ name: 'service sla plant' }"
                      v-on:click="toggle"
                      class="nav-link"
                      ><i class="far fa-dot-circle nav-icon"></i>
                      <p>SLA Plant</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('service spartpart replacemant can view')"
                  >
                    <router-link
                      :to="{ name: 'service spartpart replacemant' }"
                      v-on:click="toggle"
                      class="nav-link"
                      ><i class="far fa-dot-circle nav-icon"></i>
                      <p>Spartpart Replacemant</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('service source can view')"
                  >
                    <router-link
                      :to="{ name: 'service source' }"
                      v-on:click="toggle"
                      class="nav-link"
                      ><i class="far fa-dot-circle nav-icon"></i>
                      <p>Source</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('service status can view')"
                  >
                    <router-link
                      :to="{ name: 'service status' }"
                      v-on:click="toggle"
                      class="nav-link"
                      ><i class="far fa-dot-circle nav-icon"></i>
                      <p>Status</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('service canned response can view')"
                  >
                    <router-link
                      :to="{ name: 'service canned response' }"
                      v-on:click="toggle"
                      class="nav-link"
                      ><i class="far fa-dot-circle nav-icon"></i>
                      <p>Canned Response</p></router-link
                    >
                  </li>
                </ul>
              </li>
              <li
                class="nav-item"
                v-if="
                  accessname.includes('client condition can view') ||
                  accessname.includes('finnal interested can view')
                "
              >
                <a href="#" class="nav-link">
                  <img src="/assets/img/sales-funnel.png" class="nav-icon" />
                  <p>
                    Leads
                    <i class="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul class="nav nav-treeview">
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('client condition can view')"
                  >
                    <router-link
                      :to="{ name: 'client condition' }"
                      v-on:click="toggle"
                      class="nav-link"
                      ><i class="far fa-dot-circle nav-icon"></i>
                      <p>Client Condition</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('finnal interested can view')"
                  >
                    <router-link
                      :to="{ name: 'finnal interested' }"
                      v-on:click="toggle"
                      class="nav-link"
                      ><i class="far fa-dot-circle nav-icon"></i>
                      <p>Finnal Interested</p></router-link
                    >
                  </li>
                </ul>
              </li>
              <li
                class="nav-item"
                v-if="
                  accessname.includes('ticket category can view') ||
                  accessname.includes('ticket status can view') ||
                  accessname.includes('ticket severity can view') ||
                  accessname.includes('ticket sourcecan view') ||
                  accessname.includes('ticket subcategory can view') ||
                  accessname.includes('ticket priority can view')
                "
              >
                <a href="#" class="nav-link">
                  <img src="/assets/img/ticket.png" class="nav-icon" />
                  <p>
                    Ticket
                    <i class="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul class="nav nav-treeview">
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('ticket category can view')"
                  >
                    <router-link
                      :to="{ name: 'ticket category' }"
                      v-on:click="toggle"
                      class="nav-link"
                      ><i class="far fa-dot-circle nav-icon"></i>
                      <p>Category</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('ticket status can view')"
                  >
                    <router-link
                      :to="{ name: 'ticket status' }"
                      v-on:click="toggle"
                      class="nav-link"
                      ><i class="far fa-dot-circle nav-icon"></i>
                      <p>Status</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('ticket severity can view')"
                  >
                    <router-link
                      :to="{ name: 'ticket severity' }"
                      v-on:click="toggle"
                      class="nav-link"
                      ><i class="far fa-dot-circle nav-icon"></i>
                      <p>Severity</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('ticket source can view')"
                  >
                    <router-link
                      :to="{ name: 'ticket source' }"
                      v-on:click="toggle"
                      class="nav-link"
                      ><i class="far fa-dot-circle nav-icon"></i>
                      <p>Source</p></router-link
                    >
                  </li>

                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('ticket priority can view')"
                  >
                    <router-link
                      :to="{ name: 'ticket priority' }"
                      v-on:click="toggle"
                      class="nav-link"
                      ><i class="far fa-dot-circle nav-icon"></i>
                      <p>Priority</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('ticket subcategory can view')"
                  >
                    <router-link
                      :to="{ name: 'ticket subcategory' }"
                      v-on:click="toggle"
                      class="nav-link"
                      ><i class="far fa-dot-circle nav-icon"></i>
                      <p>Subcategory</p></router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    @click="toggleSidebar()"
                    v-if="accessname.includes('ticket priority can view')"
                  >
                    <router-link
                      :to="{ name: 'ticket topic' }"
                      v-on:click="toggle"
                      class="nav-link"
                      ><i class="far fa-dot-circle nav-icon"></i>
                      <p>Topic</p></router-link
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li
            class="nav-item"
            @click="toggleSidebar()"
            v-if="accessname.includes('customer can view')"
          >
            <router-link to="/contact" class="nav-link">
              <img src="/assets/img/contact-book.png" class="nav-icon" />
              <p>Customer</p>
            </router-link>
          </li>
          <li
            class="nav-item"
            @click="toggleSidebar()"
            v-if="accessname.includes('campaign can view')"
          >
            <router-link to="/campaign" class="nav-link">
              <img src="/assets/img/megaphone.png" class="nav-icon" />
              <p>Campaign</p>
            </router-link>
          </li>
          <li
            class="nav-item"
            @click="toggleSidebar()"
            v-if="accessname.includes('leads can view')"
          >
            <router-link to="/leads" class="nav-link">
              <img src="/assets/img/sales-funnel.png" class="nav-icon" />
              <p>Leads</p>
            </router-link>
          </li>
          <li
            class="nav-item"
            v-if="
              accessname.includes('activities can view') ||
              accessname.includes('appointment can view') ||
              accessname.includes('appointment approval can view')
            "
          >
            <a href="#" class="nav-link">
              <img src="/assets/img/calendar.png" class="nav-icon" />
              <p>
                Event
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li
                class="nav-item"
                @click="toggleSidebar()"
                v-if="accessname.includes('activities can view')"
              >
                <router-link to="/activities" class="nav-link">
                  <i class="nav-icon fas fa-chart-line"></i>
                  <p>Activity</p>
                </router-link>
              </li>
              <li
                class="nav-item"
                @click="toggleSidebar()"
                v-if="accessname.includes('appointment can view')"
              >
                <router-link to="/appointment" class="nav-link">
                  <i class="nav-icon far fa-calendar-check"></i>
                  <p>Appointment</p>
                </router-link>
              </li>
              <li
                class="nav-item"
                @click="toggleSidebar()"
                v-if="accessname.includes('appointment approval can view')"
              >
                <router-link :to="{ name: 'appointment approval' }" class="nav-link">
                  <img src="/assets/img/hand.png" class="nav-icon" />
                  <p>Appointment Approval</p>
                </router-link>
              </li>
            </ul>
          </li>
          <li
            class="nav-item"
            @click="toggleSidebar()"
            v-if="accessname.includes('ticket can view')"
          >
            <router-link to="/ticket" class="nav-link">
              <img src="/assets/img/ticket.png" class="nav-icon" />
              <p>Ticket</p>
            </router-link>
          </li>
          <li
            class="nav-item"
            @click="toggleSidebar()"
            v-if="accessname.includes('service can view')"
          >
            <router-link to="/service" class="nav-link">
              <img src="/assets/img/technical-support.png" class="nav-icon" />
              <p>Service</p>
            </router-link>
          </li>
          <li
            class="nav-item"
            @click="toggleSidebar()"
            v-if="accessname.includes('calender can view')"
          >
            <router-link to="/event/calendar" class="nav-link">
              <img src="/assets/img/calendar.png" class="nav-icon" />
              <p>Calendar</p>
            </router-link>
          </li>
          <li
            class="nav-item"
            @click="toggleSidebar()"
            v-if="accessname.includes('history can view')"
          >
            <router-link to="/users-log" class="nav-link">
              <img src="/assets/img/histories.png" class="nav-icon" />
              <p>History</p>
            </router-link>
          </li>

          <li
            class="nav-item"
            v-if="
              accessname.includes('event report can view') ||
              accessname.includes('report activity can view') ||
              accessname.includes('report ticket can view') ||
              accessname.includes('report sales can view') ||
              accessname.includes('report event can view') ||
              accessname.includes('report lead can view') ||
              accessname.includes('appointment count report can view') ||
              accessname.includes('report clinical info can view') ||
              accessname.includes('report after sales can view')
            "
          >
            <a href="#" class="nav-link">
              <img src="/assets/img/statistics2.png" class="nav-icon" />
              <p>
                Report
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li
                class="nav-item"
                @click="toggleSidebar()"
                v-if="accessname.includes('report ticket can view')"
              >
                <router-link to="/report/ticket" class="nav-link">
                  <i class="nav-icon fas fa-minus"></i>
                  <p>Ticket</p>
                </router-link>
              </li>
              <li
                class="nav-item"
                @click="toggleSidebar()"
                v-if="accessname.includes('report event can view')"
              >
                <router-link to="/report/event" class="nav-link">
                  <i class="nav-icon fas fa-minus"></i>
                  <p>Event</p>
                </router-link>
              </li>
              <li class="nav-item" v-if="accessname.includes('report lead can view')">
                <router-link to="/report/leads" class="nav-link">
                  <i class="nav-icon fas fa-minus"></i>
                  <p>Leads</p>
                </router-link>
              </li>
              <li
                class="nav-item"
                @click="toggleSidebar()"
                v-if="accessname.includes('report activity can view')"
              >
                <router-link to="/report/activity" class="nav-link">
                  <i class="nav-icon fas fa-minus"></i>
                  <p>Activity</p>
                </router-link>
              </li>
              <li
                class="nav-item"
                @click="toggleSidebar()"
                v-if="accessname.includes('report clinical info can view')"
              >
                <router-link
                  :to="{ name: 'report clinical info can view' }"
                  class="nav-link"
                >
                  <i class="nav-icon fas fa-minus"></i>
                  <p>Hearing Clinic</p>
                </router-link>
              </li>
              <li
                class="nav-item"
                @click="toggleSidebar()"
                v-if="accessname.includes('report sales can view')"
              >
                <router-link to="/report/sale" class="nav-link">
                  <i class="nav-icon fas fa-minus"></i>
                  <p>Sales</p>
                </router-link>
              </li>
              <li
                class="nav-item"
                @click="toggleSidebar()"
                v-if="accessname.includes('appointment count report can view')"
              >
                <router-link to="/report/appointment" class="nav-link">
                  <i class="nav-icon fas fa-minus"></i>
                  <p>Appointment</p>
                </router-link>
              </li>
              <li
                class="nav-item"
                @click="toggleSidebar()"
                v-if="accessname.includes('report after sales can view')"
              >
                <router-link :to="{ name: 'report after sales' }" class="nav-link">
                  <i class="nav-icon fas fa-minus" />
                  <p>After Sales Follow up</p>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import $ from "jquery";
import authHeader from "../services/auth-header";
import axios from "axios";

import Sidebar from "primevue/sidebar";

export default {
  //props: ["accessSidebar"],
  props: {
    accessSidebar: {
      type: Boolean,
      default: () => false,
    },
    hover: {
      type: Boolean,
      default: () => false,
    },
    moduleAccess: {
      type: Array,
      default: () => [],
    },
    usernamestorage: {
      type: Object,
      default: () => ({}),
    },
    usersdata: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    Sidebar,
  },
  data() {
    return {
      currentDate: 0,
      datauser: [],
      active: true,
      devision_label: "",
      temp: "",
      visible: false,
      visible2: false,
      navactive: "",
      can_view: "",
      menusidebar: [],
      show: false,
      isVisible: true,
      menudocs: [],
      isvisibledocs: false,
      showSection: false,
      showparent: false,
      iseventmenu: false,
      isservicemaster: false,
      isticketmaster: false,
      visibleLeft: false,
      finnalinterest: false,
      usernamestorages: [],
      istoggle: "sidebar-closed",
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    accessname() {
      if (this.$store.state.access.moudles == null) {
        return JSON.parse(localStorage.getItem("permission")).map((b) => {
          return b.permission_name;
        });
      } else {
        return this.$store.state.access.modules;
      }
    },
    imageUser() {
      if (
        this.currentUser !== 401 &&
        this.currentUser !== null &&
        this.currentUser !== undefined
      ) {
        return this.currentUser.users.image;
      } else {
        return null;
      }
    },
    userscrm() {
      return this.$attrs.usersdata;
    },
    datausers() {
      return this.currentUser.users;
    },
    module_name() {
      if (this.$store.state.access.moudles == null) {
        return JSON.parse(localStorage.getItem("permission")).map((b) => {
          return b.permission_name;
        });
      } else {
        return this.$store.state.access.modules;
      }
    },
    routeName() {
      return this.$route.name;
    },
    idclass() {
      if (this.currentUser !== null) {
        return "sidebar";
      } else {
        return "sidelogin";
      }
    },
  },
  mounted: function () {
    let pervrouter = this.$router.options.history.state.back;
    if (pervrouter === "/login") {
      $('[data-widget="treeview"]').Treeview("init");
    }
    setTimeout(() => {
      this.checkrole();
      this.getMenudocs();
    }, 500);
  },
  methods: {
    getstoregusers(obj) {
      this.usernamestorages = obj;
    },
    callsidebarmobile() {
      if (this.$isMobile()) {
        this.visibleLeft = !this.visibleLeft;
      }
    },
    toggle() {
      this.showSection = !this.showSection;
      this.showparent = !this.showSection;
      this.iseventmenu = !this.showSection;
      this.isservicemaster = !this.showSection;
      this.isticketmaster = !this.showSection;
      this.finnalinterest = !this.finnalinterest;
      if (this.$isMobile()) {
        this.visibleLeft = !this.visibleLeft;
      }
    },
    toggleparent() {
      this.showparent = !this.showparent;
    },
    toggleparentevent() {
      this.iseventmenu = !this.iseventmenu;
    },
    toggleservicemaster() {
      this.isservicemaster = !this.isservicemaster;
    },
    toggleticketmaster() {
      this.isticketmaster = !this.isticketmaster;
    },
    togglefinnalinterest() {
      this.finnalinterest = !this.finnalinterest;
    },
    hideCollapse(val) {
      if (this.$isMobile()) {
        this.visibleLeft = !this.visibleLeft;
      }
      if (this.visible == true) {
        this.visible = val;
      }
      if (this.visible2 == true) {
        this.visible2 = val;
      }
      if (this.show == true) {
        this.show = val;
      }
      if (this.isvisibledocs == true) {
        this.isvisibledocs = val;
      }
      //this.show = 'collapse'
      //this.$emit("getAccessuser");
      this.getAccess();
    },
    hidenav() {
      if (this.$isMobile()) {
        this.visibleLeft = !this.visibleLeft;
      }
      $(".show").removeClass().addClass("collapse");
      $("a").attr("aria-expanded", "false");
    },
    activeClass(val) {
      this.navactive = val;
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push({
        name: "login",
        query: {
          redirect: this.$route.path,
        },
      });
    },
    removeDumplicateValue(myArray) {
      var newArray = [];

      $.each(myArray, function (key, value) {
        var exists = false;
        $.each(newArray, function (k, val2) {
          if (value == val2) {
            exists = true;
          }
        });
        if (exists == false && value != "") {
          newArray.push(value);
        }
      });

      return newArray;
    },
    currentRoute() {
      this.$nextTick(() => {
        this.routeName = this.$route.name;
      });
    },
    getAccess() {
      this.moduleAccess.forEach((b) => {
        this.menusidebar.push(b.module_name);
      });
      //this.$emit("getAccessuser");
      this.checkrole();
    },
    checkrole() {
      var menu = [];
      if (this.currentUser == null || this.currentUser.users == undefined) {
        return false;
      }
      this.moduleAccess.forEach((b) => {
        let checkroles = b.permission_name;
        let user_id = b.user_id;
        if (checkroles == "customer can view" && this.currentUser.users.id == user_id) {
          this.can_view = true;
        }
      });
      this.menusidebar = [...new Set(menu)];
      setTimeout(() => {}, 500);
    },
    goTodoc(id) {
      this.$router.push({
        name: "documentation can view",
        params: {
          id: id,
        },
      });
    },
    toggleSidebar() {
      if (this.$isMobile()) {
        //sidebar-closed
        let sitebody = document.body;

        setTimeout(() => {
          if (this.istoggle == "sidebar-closed") {
            sitebody.classList.remove("sidebar-closed");
            this.toggleBodyClass("addClass", "sidebar-open");
            this.istoggle = "sidebar-open";
          } else {
            sitebody.classList.remove("sidebar-open");
            this.toggleBodyClass("addClass", "sidebar-collapse");
            this.toggleBodyClass("addClass", "sidebar-closed");
            this.istoggle = "sidebar-closed";
          }
        }, 500);
      }
    },
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;
      if (addRemoveClass === "addClass") {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    getMenudocs() {
      var data = {};
      axios
        .post(this.$api_url + "index-docs", data, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.message == "Unauthenticated") {
            this.$store.dispatch("auth/logout");
            this.$router.push({
              name: "login",
              query: { redirect: this.$route.path },
            });
          }
          let getData = response.data;
          this.menudocs = [...new Set(getData)];
        })
        .catch((error) => {
          this.isBusy = false;
          console.log(error);
          if (error.response.status == 403 || error.response.status == 401) {
            this.$store.dispatch("auth/logout");
            this.$router.push({
              name: "login",
              query: { redirect: this.$route.path },
            });
          }
        });
    },
  },
};
</script>
